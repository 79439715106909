import Centre from 'components/shared/centre'
import { LogOutButton } from 'components/shared/auth-components'
import { ReactComponent as LogoSvg } from 'img/wsp.svg'

const Pending = (): JSX.Element => (
  <Centre>
    <div className="text-center">
      <span className="bg-blue-900 text-3xl inline-block px-4 py-8 rounded-full">
        <LogoSvg />
      </span>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Pending
      </h2>
      <p className="mt-2 text-center text-md text-gray-600">
        Thanks for signing in, we'll activate your account and let you know
        soon.
      </p>
      <p className="mt-2 text-center text-sm text-gray-600 leading-relaxed mt-20 px-4 py-2 border border-1 border-gray-300 rounded">
        If you think you logged in wrong, then you can{' '}
        <LogOutButton className="bg-indigo-500 ml-2" />
      </p>
    </div>
  </Centre>
)

export default Pending
