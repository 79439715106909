import type { AllCoursesQuery_allCourses as Course } from 'api/__generated__/AllCoursesQuery'

import { InlineButtonPrimary } from 'components/buttons'
import Pill, { PillVariant } from 'components/shared/pill'
import { useNavigate } from 'react-router-dom'

interface ItemProps {
  course: Course
  edit: () => void
  selected: boolean
}
const Item = ({ course, edit }: ItemProps): JSX.Element => {
  const { level, name, isAvailable, spaces, bookableCapacity } = course
  const navigate = useNavigate()
  const print = () => {
    navigate(`/course/print/${course.id}`)
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{name}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900 capitalize">
          {level.toLowerCase()}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          <Pill variant={isAvailable ? PillVariant.green : PillVariant.red}>
            {isAvailable ? 'Available' : 'Unavailable'}
          </Pill>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{spaces}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {bookableCapacity}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <InlineButtonPrimary onClick={edit} className="">
          View
        </InlineButtonPrimary>

        <InlineButtonPrimary onClick={print} className="">
          Print
        </InlineButtonPrimary>
      </td>
    </tr>
  )
}

interface ListProps {
  courses: Course[]
  selectedCourseId: Course['id'] | null
  openPanel: (courseId: Course['id']) => void
}

const CourseList = ({
  courses,
  openPanel,
  selectedCourseId,
}: ListProps): JSX.Element => (
  <div className="max-w-full xl:max-w-6xl mx-auto py-6 sm:px-6 flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden bcourse-b bcourse-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Level
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Available
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Spaces remaining
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Bookable capacity
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {courses.map(
                (course: Course): JSX.Element => (
                  <Item
                    course={course}
                    key={course.id}
                    edit={() => openPanel(course.id)}
                    selected={course.id === selectedCourseId}
                  />
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)

export default CourseList
