import { gql } from '@apollo/client'

export const People = {
  PersonFragment: gql`
    fragment PersonFragment on Person {
      id
      firstName
      lastName
      picture
      capabilities {
        id
        name
      }
    }
  `,
}

export const Assignment = {
  AssignmentFragment: gql`
    fragment AssignmentFragment on Assignment {
      person {
        ...PersonFragment
      }
      id
      role
      confirmedAt
      isConfirmed: confirmedAt
    }
    ${People.PersonFragment}
  `,
}

export const Session = {
  SessionFragment: gql`
    fragment SessionFragment on Session {
      id
      starts
      ends
      duration
      type
      spaces
      bookableCapacity
      autoGenerated
      notes
      flag
    }
  `,
}

export const Order = {
  OrderFragment: gql`
    fragment OrderFragment on Order {
      __typename
      id
      firstName
      lastName
      ref
      email
      phone
      notes
      orderSkus {
        id
        quantity
        sku {
          id
          name
          price
          id
        }
      }
    }
  `,
}
