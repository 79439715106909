import { Dispatch, SetStateAction } from 'react'
import Select, { OnChangeValue } from 'react-select'

import { Role, UserStatus } from 'globalTypes'
import { Input } from 'components/form'

import { roleOptions, userStatusOptions } from 'utils'

interface Option<T = string> {
  readonly value: T
  readonly label: string
}

interface Props {
  userStatus?: UserStatus
  role?: Role
  filterText: string
  setUserStatus: Dispatch<SetStateAction<UserStatus | undefined>>
  setRole: Dispatch<SetStateAction<Role | undefined>>
  setFilterText: Dispatch<SetStateAction<string>>
}
const PeopleFilter = ({
  userStatus,
  role,
  setRole,
  setUserStatus,
  filterText,
  setFilterText,
}: Props): JSX.Element => {
  const onChangeUserStatus = (
    newValue: OnChangeValue<Option<UserStatus>, false>,
  ) => setUserStatus(newValue?.value ?? undefined)

  const onChangeRole = (newValue: OnChangeValue<Option<Role>, false>) =>
    setRole(newValue?.value ?? undefined)

  const userStatusValue = userStatusOptions.find(
    ({ value }) => value === userStatus,
  )

  const roleValue = roleOptions.find(({ value }) => value === role)

  return (
    <div className="md:flex md:flex-row text-gray-600 text-sm ml-3 content-flex-end">
      <div className="flex mr-5 items-center pb-2 md:pb-0">
        <label className="inline-block mr-2">User status:</label>
        <Select
          options={userStatusOptions}
          onChange={onChangeUserStatus}
          value={userStatusValue}
          className="select inline-block flex-1 md:flex-0"
          isClearable
        />
      </div>
      <div className="flex mr-5 items-center pb-2 md:pb-0">
        <label className="inline-block mr-2">Role:</label>
        <Select
          options={roleOptions}
          onChange={onChangeRole}
          value={roleValue}
          className="select inline-block flex-1 md:flex-0"
          isClearable
        />
      </div>
      <div className="flex mr-5 items-center pb-2 md:pb-0">
        <label className="inline-block mr-2">Filter:</label>
        <Input
          type="text"
          placeholder="Name or phone"
          onChange={setFilterText}
          value={filterText}
          className="select inline-block flex-1 md:flex-0"
        />
      </div>
    </div>
  )
}

export default PeopleFilter
