import { DateTime } from 'luxon'
import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

import type { WeekSessionsQuery_sessions as Session } from 'api/__generated__/WeekSessionsQuery'
import { Flag, SessionType, Role, UserStatus } from 'globalTypes'

export interface Option<T = string> {
  value: T
  label: string
}

export const DOW = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

type SessionTypeMap = Record<SessionType, string>
type FlagMap = Record<Flag, string>

export const SESSION_TYPE_EMOJIS: SessionTypeMap = {
  [SessionType.PUBLIC]: '🏊‍♀️',
  [SessionType.BOOKING]: '🥳',
  [SessionType.LESSON]: '🎖',
  [SessionType.SCHOOL]: '🎓',
  [SessionType.LANES]: '🏁',
  [SessionType.FAMILY]: '👨‍👩‍👧‍👦',
  [SessionType.ADULT]: '👵',
  [SessionType.ADULT_INFANT]: '👶',
  [SessionType.THERAPEUTIC]: '🧘‍♀️',
  [SessionType.TRAINING]: '👩‍🏫',
  [SessionType.RESERVED]: '🤫',
}

export const SESSION_TYPE_LABELS: SessionTypeMap = {
  [SessionType.PUBLIC]: 'Public',
  [SessionType.BOOKING]: 'Booking',
  [SessionType.LESSON]: 'Lesson',
  [SessionType.SCHOOL]: 'School session',
  [SessionType.LANES]: 'Lane swimming',
  [SessionType.FAMILY]: 'Family session',
  [SessionType.ADULT]: 'Adult general',
  [SessionType.ADULT_INFANT]: 'Adult + baby',
  [SessionType.THERAPEUTIC]: "Ladies' therapeutic",
  [SessionType.TRAINING]: 'Training',
  [SessionType.RESERVED]: 'Reserved',
}

export const FLAG_LABELS: FlagMap = {
  [Flag.OK]: 'Ok',
  [Flag.WARNING]: 'Warning',
}

const sessionTypeKeys = Object.keys(SESSION_TYPE_LABELS) as SessionType[]
const flagKeys = Object.keys(FLAG_LABELS) as Flag[]

export const SESSION_TYPE_OPTIONS = sessionTypeKeys.map(
  (value: SessionType): Option<SessionType> => ({
    value: value,
    label: `${SESSION_TYPE_EMOJIS[value]} ${SESSION_TYPE_LABELS[value]}`,
  }),
)

export const FLAG_OPTIONS = flagKeys.map(
  (value: Flag): Option<Flag> => ({
    value: value,
    label: FLAG_LABELS[value],
  }),
)

export const ROWS_PER_HOUR = 4
export const STARTING_HOUR = 8
export const HOURS_PER_DAY = 13
export const ENDING_HOUR = STARTING_HOUR + HOURS_PER_DAY
export const MIN_TIME = Math.floor((1 / ROWS_PER_HOUR) * 60)
export const HOUR_RANGE = [...Array(HOURS_PER_DAY).keys()].map(
  (n) => n + STARTING_HOUR,
)

export const thisWeekLink = (): string =>
  `/wc/${DateTime.local().startOf('week').toISODate()}`

export const getOccupancy = ({
  bookableCapacity,
  spaces,
  type,
  courses,
}: Session): string => {
  const isLesson = type === SessionType.LESSON
  if (!isLesson) {
    return `${spaces}/${bookableCapacity}`
  }
  const totalCourseSpaces = courses.reduce(
    (acc, course) => acc + course.spaces,
    0,
  )
  const totalCourseCapacity = courses.reduce(
    (acc, course) => acc + course.bookableCapacity,
    0,
  )
  return `${totalCourseSpaces}/${totalCourseCapacity}`
}

// errors.graphQLErrors response from our resolvers
type APIGQLError<V> = GraphQLError & {
  field: keyof V
  message: string
}
export type FieldErrors<V> = Record<keyof V, string> | Record<string, never>

// Map the apolloerror.graphQLErrors errors list into
// an object with [fieldName]: message
// field name has been added in the resolveer.
// its not expected in the standard GraphQLError type
export const groupGQLErrors = <V>(
  error: ApolloError | undefined,
): FieldErrors<V> => {
  const errors = (error?.graphQLErrors || []) as APIGQLError<V>[]
  // @ts-ignore @typescript-eslint/no-explicit-any
  return errors.reduce((acc: any, item: APIGQLError<V>): FieldErrors<V> => {
    ;(acc[item.field] = acc[item.field] || []).push(item.message)
    return acc
  }, {})
}

export const ucfirst = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export const roleOptions: Option<Role>[] = [
  { value: Role.ADMIN, label: 'Admin' },
  { value: Role.MANAGER, label: 'Manager' },
  { value: Role.GATEKEEPER, label: 'Gatekeeper' },
  { value: Role.LIFEGUARD, label: 'Lifeguard' },
  { value: Role.TEACHER, label: 'Teacher' },
  { value: Role.READONLY, label: 'Read only' },
]

export const userStatusOptions: Option<UserStatus>[] = [
  { value: UserStatus.OK, label: 'Ok' },
  { value: UserStatus.PENDING, label: 'Pending' },
  { value: UserStatus.INACTIVE, label: 'Inactive' },
]
