import useBadge from 'hooks/useBadge'
import { useNavigate } from 'react-router-dom'

import { Role } from 'globalTypes'
import { Button } from './buttons'
import { RequireRole } from './shared/auth-components'

const PhoneNag = (): JSX.Element | null => {
  const { needsPhoneNumber } = useBadge()
  const navigate = useNavigate()
  if (!needsPhoneNumber) {
    return null
  }
  const onClick = () => {
    navigate('/settings')
  }
  return (
    <RequireRole roles={[Role.TEACHER, Role.LIFEGUARD, Role.GATEKEEPER]}>
      <div className="container">
        <div className="border-blue-600 border-2 bg-blue-50 py-3 px-5 rounded-md">
          <p className="text-xl text-blue-700 font-bold mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-1 -mt-0.5 inline align-middle"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
              />
            </svg>
            Please add your phone number
          </p>
          <p>
            In case we need to get in touch with you about a session. It won't
            be used for anything else.
          </p>
          <div className="flex items-baseline mt-3">
            <Button onClick={onClick}>Update settings</Button>
            <a
              href="https://wottonpool.co.uk/privacy/"
              title="View WSP Privacy policy"
              className="text-sm ml-5 hover:underline"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </RequireRole>
  )
}

export default PhoneNag
