import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { NavLink, NavLinkProps, RouteProps } from 'react-router-dom'

import { Role } from 'globalTypes'
import { InlineButton, Button } from 'components/buttons'
import { useUser } from 'components/auth-context'

export const useRoles = (roles: Role[]): boolean => {
  const { user } = useUser()
  const userRoles = new Set<Role>(user?.roles)
  const authorised = roles.some((r) => userRoles.has(r))
  return authorised
}

interface RequireRoleProps extends RouteProps {
  roles: Role[]
  children: React.ReactNode
}
export const RequireRole = ({
  children,
  roles,
}: RequireRoleProps): JSX.Element | null => {
  const authorised = useRoles(roles)
  return authorised ? <>{children}</> : null
}

export interface RoleNavLinkProps extends NavLinkProps {
  roles?: Role[]
  children: React.ReactNode
}
export const RoleNavLink = ({
  children,
  roles,
  ...navLinkProps
}: RoleNavLinkProps): JSX.Element | null => {
  const authorised = useRoles(roles ?? [])
  if (roles && !authorised) return null
  return <NavLink {...navLinkProps}>{children}</NavLink>
}

interface RoleSwitchProps {
  components: { roles: Role[]; component: React.ReactElement }[]
  fallback?: React.ReactElement
}
export const RoleSwitch = ({
  components,
  fallback,
}: RoleSwitchProps): JSX.Element | null => {
  const fallbackComponent = fallback || null
  const { user } = useUser()
  const userRoles = new Set<Role>(user?.roles)
  const anyRoles = (r: Role) => userRoles.has(r)

  const item = components.filter(({ roles }) => roles.some(anyRoles)).shift()
  return item ? item.component : fallbackComponent
}

export const LoginButton = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0()
  const onClick = () => {
    void loginWithRedirect({
      appState: {
        targetRoute: window.location.pathname,
      },
    })
  }
  return <Button onClick={onClick}>Log In</Button>
}

interface LogOutButtonProps {
  className?: string
}
export const LogOutButton = ({ className }: LogOutButtonProps): JSX.Element => {
  const { logout } = useAuth0()

  return (
    <InlineButton
      className={'text-white ' + className}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Log&nbsp;out
    </InlineButton>
  )
}

interface AuthNavProps {
  className?: string
}
export const AuthNav = ({ className }: AuthNavProps): JSX.Element => {
  const { user, isAuthenticated, isLoading } = useAuth0()

  return (
    <div className={className}>
      {isAuthenticated ? (
        <>
          {user && !isLoading && (
            <img
              className="h-8 inline-block w-8 rounded-full"
              src={user.picture}
              alt={user.name}
            />
          )}
          <LogOutButton />
        </>
      ) : (
        <LoginButton />
      )}
    </div>
  )
}
