import { useState, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import Select, { OnChangeValue, Options } from 'react-select'
import { ApolloError, useMutation, useQuery } from '@apollo/client'

import type {
  SessionPanelQuery_session as Session,
  SessionPanelQuery_courses as Course,
} from 'api/__generated__/SessionPanelQuery'
import type { AllCoursesQuery } from 'api/__generated__/AllCoursesQuery'
import type {
  DeleteSessionMutation,
  DeleteSessionMutationVariables,
} from 'api/__generated__/DeleteSessionMutation'
import type {
  UpdateSessionMutation,
  UpdateSessionMutationVariables,
} from 'api/__generated__/UpdateSessionMutation'
import { SessionType, Flag } from 'globalTypes'
import * as mutations from 'api/mutations'
import * as queries from 'api/queries'
import {
  MIN_TIME,
  SESSION_TYPE_OPTIONS,
  STARTING_HOUR,
  ENDING_HOUR,
  FLAG_OPTIONS,
} from 'utils'
import { Label, TextArea, Input } from 'components/form'
import { Button, ButtonSec, ButtonDanger } from 'components/buttons'
import Error from 'components/shared/error'
import type { Placeholder } from 'api/sessions'
import {
  CreateSessionMutation,
  CreateSessionMutationVariables,
} from 'api/__generated__/CreateSessionMutation'

const durations = [
  { value: 20, label: '20min' },
  { value: 30, label: '30min' },
  { value: 50, label: '50min' },
  { value: 60, label: '1hr' },
  { value: 80, label: '1hr20' },
  { value: 90, label: '1hr30' },
  { value: 110, label: '1hr10' },
  { value: 120, label: '2hrs' },
]

interface Option<T = string> {
  value: T
  label: string
}

const formatTimeOptions = (value: DateTime): Option<DateTime> => ({
  value,
  label: value.toLocaleString(DateTime.TIME_24_SIMPLE),
})

/**
 * Calculate a list of start times, begining from the start of this gap
 * and incrementing by 15 mins intil the next session starts this day
 * @param DateTime starts
 * @param DateTime ends
 * @return [{value: DateTime, label: String}]
 */
const getStartTimes = (
  starts: DateTime,
  ends: DateTime,
): Options<Option<DateTime>> => {
  const startTimes = [starts]

  while (startTimes[startTimes.length - 1].plus({ minutes: MIN_TIME }) < ends) {
    startTimes.push(
      startTimes[startTimes.length - 1].plus({ minutes: MIN_TIME }),
    )
  }
  return startTimes.map(formatTimeOptions)
}

/**
 * For editing, allow all times
 * API will validate if it's savable
 * @param DateTime starts
 * @return [{value: DateTime, label: String}]
 */
const getAllStartTimes = (starts: DateTime) => {
  const firstTime = starts.startOf('day').set({ hour: STARTING_HOUR })
  const eod = firstTime.set({ hour: ENDING_HOUR })

  const startTimes = [firstTime]

  while (startTimes[startTimes.length - 1].plus({ minutes: MIN_TIME }) < eod) {
    startTimes.push(
      startTimes[startTimes.length - 1].plus({ minutes: MIN_TIME }),
    )
  }
  return startTimes.map(formatTimeOptions)
}

interface UseSessionState {
  newDuration?: OnChangeValue<Option<number>, false>
  newNotes?: string
  newStarts?: OnChangeValue<Option<DateTime>, false>
  newType?: OnChangeValue<Option<SessionType>, false>
  newFlag?: OnChangeValue<Option<Flag>, false>
  newCourseIds?: OnChangeValue<Option<Course['id']>, true>
  newBookableCapacity: number
  onChangeDuration: (option: OnChangeValue<Option<number>, false>) => void
  onChangeStarts: (option: OnChangeValue<Option<DateTime>, false>) => void
  onChangeType: (option: OnChangeValue<Option<SessionType>, false>) => void
  onChangeFlag: (option: OnChangeValue<Option<Flag>, false>) => void
  onChangeNotes: (value: string) => void
  onChangeBookableCapacity: (value: string) => void
  onChangeCourseIds: (option: OnChangeValue<Option<Course['id']>, true>) => void
  isValid: boolean
  startTimes: Options<Option<DateTime>>
}

const useSessionState = (
  session: Session | Placeholder,
  isNewSession = false,
): UseSessionState => {
  // Session represents the empty slot of time to create this new session withing
  const { starts, ends } = session
  const [newStarts, setStarts] =
    useState<OnChangeValue<Option<DateTime>, false>>()
  const [newDuration, setDuration] =
    useState<OnChangeValue<Option<number>, false>>()
  const [newType, setType] =
    useState<OnChangeValue<Option<SessionType>, false>>()
  const [newFlag, setFlag] = useState<OnChangeValue<Option<Flag>, false>>()
  const [newCourseIds, setCourseIds] =
    useState<OnChangeValue<Option<Course['id']>, true>>()
  const [newNotes, setNotes] = useState<string>('')
  const [newBookableCapacity, setBookableCapacity] = useState<number>(0)
  const [isValid, setValid] = useState(false)
  const limitedStartTimes = useMemo(
    () => getStartTimes(starts, ends),
    [starts, ends],
  )
  const allStartTimes = useMemo(() => getAllStartTimes(starts), [starts])
  const startTimes = isNewSession ? limitedStartTimes : allStartTimes

  // Starts select is a contolled component, so saving the whole {value, label} object for this one
  const onChangeStarts = (option: OnChangeValue<Option<DateTime>, false>) =>
    setStarts(option)
  const onChangeDuration = (option: OnChangeValue<Option<number>, false>) =>
    setDuration(option)
  const onChangeType = (option: OnChangeValue<Option<SessionType>, false>) =>
    setType(option)
  const onChangeFlag = (option: OnChangeValue<Option<Flag>, false>) =>
    setFlag(option)
  const onChangeNotes = (value: string) => setNotes(value)
  // String type from form input
  const onChangeBookableCapacity = (value: string) =>
    setBookableCapacity(parseInt(value, 10))

  const onChangeCourseIds = (
    values: OnChangeValue<Option<Course['id']>, true>,
  ) => setCourseIds(values)

  useEffect(() => {
    // set validity
    if (newStarts && newDuration && newType) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [newStarts, newDuration, newType])

  // Default duration for new sessions
  const defaultDurationOption = durations.find(({ value }) => 60 === value)

  // Clear fields between blank session slots for create
  // Prepopulate initial values if its the edit form
  useEffect(() => {
    if (isNewSession) {
      setStarts(null)
      setDuration(defaultDurationOption)
      setType(null)
      setNotes('')
      setBookableCapacity(0)
      setCourseIds([])
      setFlag(null)
      return
    }

    // Get values from the stored session
    const { starts, duration, type, flag, notes, bookableCapacity, courses } =
      session as Session
    // Find the select option for the raw values
    const newDurationOption = durations.find(({ value }) => duration === value)
    const newStartsOption = startTimes.find(
      (option: Option<DateTime>) => starts.toISO() === option?.value.toISO(),
    )
    const newTypeOption = SESSION_TYPE_OPTIONS.find(
      (option) => type === option?.value,
    )
    const newFlagOption = FLAG_OPTIONS.find((option) => flag === option?.value)
    const courseIds = courses.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    setDuration(newDurationOption)
    setStarts(newStartsOption)
    setType(newTypeOption)
    setFlag(newFlagOption)
    setNotes(notes ?? '')
    setBookableCapacity(bookableCapacity)
    setCourseIds(courseIds)
  }, [isNewSession, session, startTimes])

  return {
    newDuration,
    newNotes,
    newStarts,
    newType,
    newBookableCapacity,
    newCourseIds,
    newFlag,
    onChangeDuration,
    onChangeNotes,
    onChangeStarts,
    onChangeType,
    onChangeFlag,
    onChangeBookableCapacity,
    onChangeCourseIds,
    isValid,
    startTimes,
  }
}

interface SessionFormProps extends UseSessionState {
  save: () => void
  cancel: () => void
  saving: boolean
  error?: ApolloError
  allCourses: Course[]
}

const SessionForm = ({
  newDuration,
  newNotes,
  newType,
  newStarts,
  newBookableCapacity,
  newCourseIds,
  newFlag,
  onChangeDuration,
  onChangeNotes,
  onChangeStarts,
  onChangeType,
  onChangeFlag,
  onChangeBookableCapacity,
  onChangeCourseIds,
  isValid,
  save,
  cancel,
  saving,
  error,
  startTimes,
  allCourses,
}: SessionFormProps): JSX.Element => {
  const courseOptions = allCourses.map(({ id, name }) => ({
    value: id,
    label: name,
  }))
  const isLesson = newType?.value === SessionType.LESSON

  return (
    <>
      <div className="grid grid-cols-2 col-gap-4 mb-4">
        <Label>Type</Label>
        <Label disabled={isLesson}>Bookable capacity</Label>
        <Select
          options={SESSION_TYPE_OPTIONS}
          onChange={onChangeType}
          value={newType}
          isSearchable={false}
          className="select mr-2"
        />
        <Input
          onChange={onChangeBookableCapacity}
          value={isLesson ? undefined : newBookableCapacity}
          type="number"
          disabled={isLesson}
          placeholder={isLesson ? 'Set on the course' : undefined}
        />
      </div>

      <div className="grid grid-cols-2 col-gap-4 mb-4">
        <Label>Hour</Label>
        <Label>Duration</Label>
        <Select
          options={startTimes}
          onChange={onChangeStarts}
          value={newStarts}
          isSearchable={false}
          className="select mr-2"
        />
        <Select
          options={durations}
          onChange={onChangeDuration}
          value={newDuration}
          isSearchable={false}
          className="select"
        />
      </div>

      <div className="grid grid-cols-1 mb-4">
        <Label>Notes</Label>
        <TextArea
          placeholder="Notes"
          onChange={onChangeNotes}
          value={newNotes ?? ''}
        />
      </div>

      <div className="grid grid-cols-1 mb-4">
        <Label>State</Label>
        <Select
          options={FLAG_OPTIONS}
          onChange={onChangeFlag}
          value={newFlag}
          isSearchable={false}
          className="select mr-2"
          isClearable
        />
      </div>

      {isLesson && (
        <div className="grid grid-cols-1 mb-4">
          <Label>Lesson courses</Label>
          <Select
            options={courseOptions}
            onChange={onChangeCourseIds}
            value={newCourseIds}
            isSearchable={false}
            className="select mr-2"
            isMulti
          />
        </div>
      )}

      <div>
        <Button type="publish" disabled={!isValid} onClick={save}>
          {saving ? 'Saving...' : 'Save'}
        </Button>
        <ButtonSec type="cancel" onClick={cancel}>
          Cancel
        </ButtonSec>
      </div>
      {error && <Error className="block mt-4">{error.message}</Error>}
    </>
  )
}

export interface NewSessionFormProps {
  placeholder: Placeholder
  close: () => void
}

export const NewSessionForm = ({
  placeholder,
  close,
}: NewSessionFormProps): JSX.Element | null => {
  const sessionState = useSessionState(placeholder, true)

  const { data, loading } = useQuery<AllCoursesQuery>(queries.ALL_COURSES, {
    fetchPolicy: 'cache-first',
  })

  const [createSession, { loading: saving, error }] = useMutation<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >(mutations.CREATE_SESSION, {
    refetchQueries: [queries.WEEK_SESSIONS],
  })

  const save = async () => {
    const {
      newStarts,
      newDuration,
      newType,
      newNotes,
      newBookableCapacity,
      newCourseIds,
      newFlag,
    } = sessionState
    if (!newStarts || !newDuration || !newType || newBookableCapacity === null)
      return

    const isLesson = newType.value === SessionType.LESSON
    await createSession({
      variables: {
        starts: newStarts.value,
        duration: newDuration.value,
        type: newType.value,
        bookableCapacity: newBookableCapacity,
        notes: newNotes,
        flag: newFlag?.value ?? null,
        courseIds: isLesson ? newCourseIds?.map(({ value }) => value) : [],
      },
    })
    close()
  }

  const cancel = () => {
    close()
  }

  if (loading) return null

  return (
    <>
      <h3 className="font-bold text-blue-800 text-md mb-3">Create session</h3>
      <SessionForm
        {...sessionState}
        save={save}
        cancel={cancel}
        saving={saving}
        error={error}
        allCourses={data?.allCourses ?? []}
      />
    </>
  )
}

export interface EditSessionFormProps {
  session: Session
  allCourses: Course[]
  close: () => void
}

export const EditSessionForm = ({
  session,
  allCourses,
  close,
}: EditSessionFormProps): JSX.Element | null => {
  const [isEditing, setEditing] = useState<boolean>(false)

  const sessionState = useSessionState(session, false)
  const [deleteSession] = useMutation<
    DeleteSessionMutation,
    DeleteSessionMutationVariables
  >(mutations.DELETE_SESSION, {
    variables: { sessionId: session.id },
    refetchQueries: [queries.WEEK_SESSIONS],
  })

  const [updateSession, { loading: saving, error }] = useMutation<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >(mutations.UPDATE_SESSION, {
    refetchQueries: [queries.WEEK_SESSIONS],
  })

  useEffect(() => {
    setEditing(false)
  }, [session.id, setEditing])

  if (!session) return null

  const save = () => {
    if (!session?.id) return
    const {
      newStarts,
      newDuration,
      newType,
      newNotes,
      newBookableCapacity,
      newCourseIds,
      newFlag,
    } = sessionState
    if (!newStarts || !newDuration || !newType || newBookableCapacity === null)
      return
    const isLesson = newType.value === SessionType.LESSON
    updateSession({
      variables: {
        sessionId: session.id,
        starts: newStarts.value,
        duration: newDuration.value,
        type: newType.value,
        bookableCapacity: newBookableCapacity,
        notes: newNotes,
        flag: newFlag?.value ?? null,
        courseIds: isLesson ? newCourseIds?.map(({ value }) => value) : [],
      },
    })
  }

  const cancel = () => {
    setEditing(false)
  }

  const removeSession = () => {
    deleteSession()
    close()
  }

  return (
    <div className="pb-4">
      {isEditing ? (
        <>
          <h3 className="font-bold text-blue-800 text-md mb-3">Edit session</h3>
          <SessionForm
            {...sessionState}
            allCourses={allCourses}
            save={save}
            cancel={cancel}
            saving={saving}
            error={error}
          />
        </>
      ) : (
        <div className="flex">
          <Button type="edit" onClick={() => setEditing(true)}>
            Edit session
          </Button>
          <ButtonDanger onClick={removeSession}>Delete session</ButtonDanger>
        </div>
      )}
    </div>
  )
}
