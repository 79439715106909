import React from 'react'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

export default function SessionLink({
  children,
  session: { id, starts },
  className,
}: {
  children: React.ReactNode
  session: { id: string; starts: string | DateTime }
  className?: string
}): JSX.Element {
  const dt = starts instanceof DateTime ? starts : DateTime.fromISO(starts)
  return (
    <Link
      className={className}
      to={`/wc/${dt.toISODate()}/${id}`}
      title="View session"
    >
      {children}
    </Link>
  )
}
