import {
  useState,
  useEffect,
  useMemo,
  createContext,
  Dispatch,
  SetStateAction,
  FormEventHandler,
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Select, { OnChangeValue } from 'react-select'

import { Header } from 'components/layout'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'

import type {
  UserSettingsQuery,
  UserSettingsQuery_user_person as Person,
} from 'api/__generated__/UserSettingsQuery'
import type {
  SetContactPreferenceMutation,
  SetContactPreferenceMutationVariables,
} from 'api/__generated__/SetContactPreferenceMutation'
import type {
  UpdatePersonMutation,
  UpdatePersonMutationVariables,
} from 'api/__generated__/UpdatePersonMutation'
import { ContactPreference, ReminderPreference } from 'globalTypes'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import { groupGQLErrors, FieldErrors } from 'utils'
import { Button, ButtonSec } from 'components/buttons'
import Pill from 'components/shared/pill'
import useBadge from 'hooks/useBadge'
import Field from './field'

interface Option<T = string> {
  value: T
  label: string
}

export const FormStateContext = createContext<
  | {
      person: Person
      editing: boolean
      fieldErrors: FieldErrors<UpdatePersonMutationVariables>
      formState: UpdatePersonMutationVariables
      setFormState: Dispatch<SetStateAction<UpdatePersonMutationVariables>>
    }
  | undefined
>(undefined)

const contactPreferenceOptions = [
  { value: ContactPreference.SMS, label: 'SMS' },
  { value: ContactPreference.EMAIL, label: 'Email' },
]

const reminderPreferenceOptions = [
  { value: ReminderPreference.DAILY, label: 'Daily reminders' },
  { value: ReminderPreference.WEEKLY, label: 'Weekly assignment notification' },
]

const Settings = (): JSX.Element => {
  // State for updatable fields
  const [formState, setFormState] = useState<UpdatePersonMutationVariables>({})
  // Hold state for select box
  // This will be populated when the query loads by looking for a match in the options list
  const [newContactPreference, setContactPreference] = useState<
    OnChangeValue<Option<ContactPreference>, true>
  >([])

  const [newReminderPreference, setReminderPreference] = useState<
    OnChangeValue<Option<ReminderPreference>, true>
  >([])

  const [editing, setEditing] = useState<boolean>(false)
  const { data, loading } = useQuery<UserSettingsQuery>(queries.USER_SETTINGS)
  const [
    saveContactPreference,
    { loading: savingContactPrefs, error: errorContactPrefs },
  ] = useMutation<
    SetContactPreferenceMutation,
    SetContactPreferenceMutationVariables
  >(mutations.SET_CONTACT_PREFS, { refetchQueries: [queries.USER_SETTINGS] })

  const [updatePerson, { loading: savingPerson, error: errorSaving }] =
    useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(
      mutations.UPDATE_PERSON,
      { refetchQueries: [queries.USER_SETTINGS] },
    )

  const {
    needsContactPrefs,
    needsPhoneNumber,
    needsPhoneNumberForSms,
    firstNameIsEmail,
    noLastName,
  } = useBadge()

  const saving = savingContactPrefs ?? savingPerson

  // Set initial state from query
  // and reset when toggling edit
  useEffect(() => {
    if (data) {
      setContactPreference(
        contactPreferenceOptions.filter(({ value }) =>
          data.user.person.contactPreference.includes(value),
        ),
      )

      setReminderPreference(
        reminderPreferenceOptions.filter(({ value }) =>
          data.user.person.reminderPreference.includes(value),
        ),
      )

      setFormState(data.user.person)
    }
  }, [data?.user.person, editing])

  const savePrefs = async () => {
    // Map select box state to list of items for mutation
    const contactPreference = newContactPreference.map(({ value }) => value)
    const reminderPreference = newReminderPreference.map(({ value }) => value)
    return saveContactPreference({
      variables: { contactPreference, reminderPreference },
    })
  }

  const save = async () => {
    try {
      await updatePerson({
        variables: formState,
      })
    } catch (error) {
      // Error comes from useMutation
      return
    }
    try {
      await savePrefs()
    } catch (error) {
      // Error comes from useMutation
      return
    }
    setEditing(false)
  }
  const onSubmit: FormEventHandler = (evt) => {
    evt.preventDefault()
    save()
  }

  // Parse ApolloError and turn it into an object with
  // an error message for each field key {[field]: message}
  const fieldErrors = useMemo(
    () => groupGQLErrors<UpdatePersonMutationVariables>(errorSaving),
    [errorSaving],
  )

  const contactPrefFieldErrors = useMemo(
    () =>
      groupGQLErrors<SetContactPreferenceMutationVariables>(errorContactPrefs),
    [errorContactPrefs],
  )

  if (loading) {
    return <BigLoading loading />
  }

  if (!data) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  const {
    user: { email, person },
  } = data

  return (
    <FormStateContext.Provider
      value={{
        editing,
        person,
        formState,
        setFormState,
        fieldErrors,
      }}
    >
      <Header>Settings</Header>
      <div className="container">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 flex items-center">
            <img
              className="h-14 w-14 rounded-full mr-4 border-2 border-white shadow-md"
              src={person.picture}
              alt={person.firstName ?? 'Profile'}
            />

            <div className="flex-1">
              <h3 className="text-lg leading-5 font-medium text-gray-900">
                Your information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Personal details and settings.
              </p>
            </div>
            {editing ? (
              <>
                <Button onClick={save} type="publish" disabled={saving}>
                  Save
                </Button>
                <ButtonSec onClick={() => setEditing(false)}>Cancel</ButtonSec>
              </>
            ) : (
              <Button onClick={() => setEditing(true)}>Edit</Button>
            )}
          </div>
          <div className="border-t border-gray-200">
            <form onSubmit={onSubmit} action="" method="get">
              <input type="submit" className="hidden" />

              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 relative">
                    {firstNameIsEmail && (
                      <div className="badge -top-1 -left-2" />
                    )}
                    First name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Field fieldName="firstName" />
                    {firstNameIsEmail && !editing && (
                      <p className="text-red-600 ml-3 mt-2">
                        Please can you update your name, so we know who you are
                      </p>
                    )}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 relative">
                    {noLastName && <div className="badge -top-1 -left-2" />}
                    Last name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {noLastName && !firstNameIsEmail && !editing ? (
                      <p className="text-red-600 ml-3">
                        Please can you update your name, so we know who you are
                      </p>
                    ) : (
                      <Field fieldName="lastName" />
                    )}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="ml-3">{email}</span>
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 relative">
                    {needsPhoneNumber && (
                      <div className="badge -top-1 -left-2" />
                    )}
                    Mobile number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {needsPhoneNumber && !editing ? (
                      <>
                        <p className="text-red-600 ml-3">
                          Please enter your phone number in case we need to get
                          in touch with you.
                        </p>
                        {needsPhoneNumberForSms && (
                          <p className="text-red-600 ml-3">
                            If you would like to recieve SMS notifications about
                            your sessions, they will go to this number.
                          </p>
                        )}
                      </>
                    ) : (
                      <Field fieldName="phone" />
                    )}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 relative">
                    {needsContactPrefs && (
                      <div className="badge -top-1 -left-2" />
                    )}
                    Contact preference
                    <p className="text-sm text-gray-500 mt-1 font-normal">
                      How do you wish to be reminded about your sessions. You
                      will need to enter your phone number to receive SMS
                      reminders.
                    </p>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                      {editing ? (
                        <>
                          <Select
                            options={contactPreferenceOptions}
                            onChange={setContactPreference}
                            value={newContactPreference}
                            className="select flex-1"
                            isMulti
                          />

                          {contactPrefFieldErrors?.contactPreference && (
                            <p
                              className="text-xs mt-4 text-red-600"
                              role="alert"
                            >
                              {contactPrefFieldErrors?.contactPreference}
                            </p>
                          )}
                        </>
                      ) : (
                        <div className="ml-3">
                          {data.user.person.contactPreference.length ? (
                            <p className="pb-2">
                              {newContactPreference.map((cp) => (
                                <Pill key={cp.value}>{cp.label}</Pill>
                              ))}
                            </p>
                          ) : (
                            <p className="text-red-600 max-w-prose">
                              Please set your contact preferences if you would
                              like to recieve notifications about your sessions.
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 relative">
                    Reminder preference
                    <p className="text-sm text-gray-500 mt-1 font-normal">
                      We send reminders weekly when the assignments are
                      confirmed. And also you can get daily reminders on the
                      morning of your sessions.
                    </p>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                      {editing ? (
                        <>
                          <Select
                            options={reminderPreferenceOptions}
                            onChange={setReminderPreference}
                            value={newReminderPreference}
                            className="select flex-1"
                            isMulti
                          />

                          {contactPrefFieldErrors?.contactPreference && (
                            <p
                              className="text-xs mt-4 text-red-600"
                              role="alert"
                            >
                              {contactPrefFieldErrors?.contactPreference}
                            </p>
                          )}
                        </>
                      ) : (
                        <div className="ml-3">
                          {data.user.person.reminderPreference.length ? (
                            <p className="pb-2">
                              {newReminderPreference.map((cp) => (
                                <Pill key={cp.value}>{cp.label}</Pill>
                              ))}
                            </p>
                          ) : (
                            <p className="text-red-600 max-w-prose">
                              Please set your reminder preferences if you would
                              like to recieve notifications about your sessions.
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </dd>
                </div>
              </dl>
            </form>
          </div>
        </div>
      </div>
    </FormStateContext.Provider>
  )
}

export default Settings
