import React from 'react'

interface InputProps {
  value?: string | number
  placeholder?: string
  className?: string
  onChange: (value: any) => void
  type: 'number' | 'text'
  disabled?: boolean
  list?: string
}

export const Input: React.FC<InputProps> = ({
  value,
  placeholder,
  className,
  onChange,
  type,
  disabled,
  list,
}) => {
  const id = ''

  const onInputchange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value)
  }
  const opacity = disabled ? 'opacity-50 ' : ''
  return (
    <input
      onChange={onInputchange}
      value={value}
      className={'form-field ' + opacity + className}
      placeholder={placeholder}
      id={id}
      type={type}
      disabled={disabled}
      list={list}
    />
  )
}

interface TextAreaProps {
  value?: string
  placeholder?: string
  className?: string
  onChange: (value: string) => void
}

export const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder,
  className,
}) => {
  const onTextAreaChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(evt.target.value)
  }
  return (
    <textarea
      onChange={onTextAreaChange}
      placeholder={placeholder}
      className={'form-field ' + className}
      value={value}
    >
      {value}
    </textarea>
  )
}

interface LabelProps {
  labelFor?: string
  className?: string
  disabled?: boolean
}

export const Label: React.FC<LabelProps> = ({
  children,
  labelFor,
  className,
  disabled,
}) => {
  const opacity = disabled ? 'opacity-50 ' : ''

  return (
    <label
      className={
        'block text-gray-700 text-sm font-bold mb-3 ' + opacity + className
      }
      htmlFor={labelFor}
    >
      {children}
    </label>
  )
}
