import { useState } from 'react'
import Select from 'react-select'
import { useMutation, useQuery } from '@apollo/client'

import type {
  OrderPanelQuery_order as Order,
  OrderPanelQuery_order_course as Course,
} from 'api/__generated__/OrderPanelQuery'

import type {
  AllCoursesQuery,
  AllCoursesQuery_allCourses as CourseOption,
} from 'api/__generated__/AllCoursesQuery'

import type {
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
} from 'api/__generated__/UpdateOrderMutation'

import * as mutations from 'api/mutations'
import * as queries from 'api/queries'
import { InlineButtonDanger } from 'components/buttons'
import Error from 'components/shared/error'

type Option = {
  value: CourseOption['id']
  label: React.ReactNode
  item: CourseOption
}

type Props = {
  orderId: Order['id']
}
const ChangeCourse = ({ orderId }: Props): JSX.Element => {
  const [courseId, setCourseId] = useState<Course['id'] | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const { data, loading } = useQuery<AllCoursesQuery>(queries.ALL_COURSES)

  const [updateOrder, { error }] = useMutation<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >(mutations.UPDATE_ORDER, { refetchQueries: [queries.ORDER_PANEL] })

  const onChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      setCourseId(selectedOption.value)
    }
  }

  const save = async () => {
    if (!courseId) return
    setIsLoading(true)
    await updateOrder({ variables: { orderId, courseId } })
    setIsLoading(false)
  }

  const options = data?.allCourses.map((item) => ({
    value: item.id,
    label: item.name,
    item,
  }))

  if (loading) return <>Loading</>

  return (
    <div className="mb-6">
      <h3 className="panel-heading">Change course</h3>
      <p className="text-gray-600 text-sm">
        If you wish to change an order to another course of lessions, select it
        below. Remember to re-send the order confirmation email.
      </p>
      <div className="flex mt-4 items-center">
        <Select
          className="flex-1 mr-3"
          onChange={onChange}
          options={options}
          isOptionDisabled={({ item: { spaces } }) => spaces < 1}
        />
        <InlineButtonDanger disabled={!courseId || isLoading} onClick={save}>
          Save
        </InlineButtonDanger>
      </div>

      {error && <Error className="block mt-4">{error}</Error>}
    </div>
  )
}

export default ChangeCourse
