import { useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useParams, useNavigate } from 'react-router-dom'

type ParamTypes = {
  weekStart: string
}

type UseWeekStart = {
  weekStart: DateTime
  weekEnd: DateTime
  thisRoute: string
}

const parseWeekStart = (weekStartStr?: string): DateTime =>
  weekStartStr
    ? DateTime.fromISO(weekStartStr).startOf('week')
    : DateTime.local().startOf('week')

const useWeekStart = (): UseWeekStart => {
  const { weekStart: weekStartStr } = useParams<ParamTypes>()
  const navigate = useNavigate()

  const weekStart = useMemo(() => parseWeekStart(weekStartStr), [weekStartStr])

  useEffect(() => {
    if (!weekStart.isValid) {
      const thisWeekStart = parseWeekStart().toISODate()
      navigate(`/wc/${thisWeekStart}`, { replace: true })
    }
  }, [weekStart])

  return {
    weekStart,
    weekEnd: weekStart.endOf('week'),
    thisRoute: `/wc/${weekStart.toISODate()}`,
  }
}

export default useWeekStart
