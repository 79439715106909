import type {
  SessionPanelReadOnlyQuery_orders as Order,
  SessionPanelReadOnlyQuery_session as Session,
} from 'api/__generated__/SessionPanelReadOnlyQuery'
import { SessionType } from 'globalTypes'

const ReadOnlyOrderInfo = ({
  orders,
  session,
}: {
  orders: Order[]
  session: Session
}): JSX.Element | null => {
  if (!orders) return null
  return (
    <>
      {orders.map(({ firstName, lastName, phone, notes, orderSkus, ref }) => (
        <div key={ref}>
          <h3 className="panel-heading">Booking details</h3>

          <table className="min-w-full divide-y divide-gray-200 mb-6 table-fixed">
            <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
              <tr>
                <th scope="row" className="th--col">
                  Booker name
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm capitalize w-1/2">
                  {firstName} {lastName}
                </td>
              </tr>
              <tr>
                <th scope="row" className="th--col">
                  Phone
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm">
                  {phone ? phone : 'Not provided'}
                </td>
              </tr>
              <tr>
                <th scope="col" className="th--col">
                  Places booked
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm w-1/2">
                  {orderSkus.map(({ id, quantity, sku: { name } }) => (
                    <p key={id}>
                      {name} &times; {quantity}
                    </p>
                  ))}
                </td>
              </tr>
              <tr>
                <th scope="col" className="th--col">
                  Booking Ref
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm font-bold">
                  {ref}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}

export default ReadOnlyOrderInfo
