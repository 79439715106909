import { DateTime } from 'luxon'

import { SESSION_TYPE_EMOJIS } from 'utils'
import { Reload } from 'components/buttons'
import { SessionType } from 'globalTypes'

interface Props {
  type?: SessionType
  starts?: DateTime
  ends?: DateTime
  reload?: () => void
  loading: boolean
}
const SessionPanelHeader = ({
  starts,
  ends,
  type,
  reload,
  loading,
}: Props): JSX.Element => {
  const panelTitle =
    starts &&
    ends &&
    `${starts.toLocaleString(DateTime.TIME_24_SIMPLE)} - ${ends.toLocaleString(
      DateTime.TIME_24_SIMPLE,
    )} on ${starts.toFormat('EEEE d MMMM')}`

  return (
    <header className="px-4 sm:px-6 bg-indigo-700 py-6 flex text-white">
      <h2 className="text-lg leading-7 font-medium flex-1">
        {type && (
          <span className="text-2xl mr-3">{SESSION_TYPE_EMOJIS[type]}</span>
        )}
        {panelTitle}
      </h2>
      {reload && (
        <Reload
          onClick={reload}
          className={`flex-end ${loading && 'animate-spin'}`}
        />
      )}
    </header>
  )
}

export default SessionPanelHeader
