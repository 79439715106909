import { useMemo, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import type {
  SessionPanelReadOnlyQuery,
  SessionPanelReadOnlyQueryVariables,
  SessionPanelReadOnlyQuery_session as Session,
  SessionPanelReadOnlyQuery_user_person as Person,
  SessionPanelReadOnlyQuery_session_myAssignment as Assignment,
} from 'api/__generated__/SessionPanelReadOnlyQuery'
import type {
  AddAssignmentMutation,
  AddAssignmentMutationVariables,
} from 'api/__generated__/AddAssignmentMutation'
import type {
  DeleteAssignmentMutation,
  DeleteAssignmentMutationVariables,
} from 'api/__generated__/DeleteAssignmentMutation'
import { Role } from 'globalTypes'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import { parseDateTimes } from 'api/sessions'
import { ButtonConfirm } from 'components/buttons'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'
import useAssignmentFilters from 'hooks/useAssignmentFilter'
import { ReadOnlyAssignment } from '../session-panel/assignments'
import { NoteCard } from '../session-panel/components'
import ReadOnlyOrderInfo from './order-info'

interface MyAssignmentProps {
  session: Session
  roles: Role[]
  personId: Person['id']
}
const MyAssignment = ({
  session,
  roles,
  personId,
}: MyAssignmentProps): JSX.Element => {
  const { myAssignment } = session
  const { confirmed, assignedGatekeepers } = useAssignmentFilters(session)
  const canGatekeep =
    roles.includes(Role.GATEKEEPER) && assignedGatekeepers.length == 0
  const canLifeguard = roles.includes(Role.LIFEGUARD)

  const [addAssignment, { loading: saving, error }] = useMutation<
    AddAssignmentMutation,
    AddAssignmentMutationVariables
  >(mutations.ADD_ASSIGNMENT, {
    refetchQueries: [queries.SESSION_PANEL_READ_ONLY],
  })

  const [deleteAssignment, { loading: deleting, error: errorDeleting }] =
    useMutation<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>(
      mutations.DELETE_ASSIGNMENT,
      {
        refetchQueries: [queries.SESSION_PANEL_READ_ONLY],
      },
    )

  const applyForSession = (role: Role): void => {
    addAssignment({ variables: { personId, role, sessionId: session.id } })
  }

  const withdrawAssignment = (assignmentId: Assignment['id']): void => {
    deleteAssignment({ variables: { assignmentId } })
  }

  const currentlyAttending = (
    <>
      <h3 className="panel-heading">Currently attending</h3>
      {confirmed.length === 0 && <p className="text-gray-600 text-sm">None</p>}
      {confirmed.map((assignment) => (
        <ReadOnlyAssignment
          person={assignment.person}
          isConfirmed={assignment.isConfirmed}
          role={assignment.role}
        />
      ))}
    </>
  )

  if (myAssignment?.isConfirmed) {
    return (
      <>
        <h3 className="font-bold text-blue-800 text-md mb-1">
          Status:{' '}
          <span className="bg-green-600 rounded-sm text-white px-1">
            Confirmed
          </span>
        </h3>
        <p className="mt-2 text-gray-600">
          {myAssignment.person.firstName}, You are confirmed as the{' '}
          <strong>{myAssignment.role.toLowerCase()}</strong> for this session
        </p>
        <h3 className="font-bold text-blue-800 text-md mb-1 mt-4">
          What next?
        </h3>
        <p className="mt-2 text-gray-600 mb-4">
          Please attend the session as normal
        </p>
        {currentlyAttending}
        <h3 className="font-bold text-blue-800 text-md mb-1 mt-4">
          Cancellation
        </h3>
        <p className="mt-2 text-gray-600">
          If you will have any problems attending this session, please contact
          the manager as soon as possible so we can re-arrange.
        </p>
      </>
    )
  } else if (myAssignment) {
    return (
      <>
        <h3 className="font-bold text-blue-800 text-md mb-1">
          Status:{' '}
          <span className="bg-red-600 rounded-sm text-white px-1">
            Pending confirmation
          </span>
        </h3>
        <p className="mt-2 text-gray-600">
          You have registered interest to cover this session as{' '}
          {myAssignment.role.toLowerCase()}. Thankyou.
        </p>
        <h3 className="font-bold text-blue-800 text-md mb-1 mt-4">
          What next?
        </h3>
        <p className="mt-2 text-gray-600">
          You will be notified if you are assigned to this session
        </p>
        <h3 className="font-bold text-blue-800 text-md mb-1 mt-4">
          Cancellation
        </h3>
        <p className="mt-2 text-gray-600">
          If you can no longer make it, you can withdraw
        </p>
        <ButtonConfirm
          className="mt-4 mb-4"
          onClick={() => withdrawAssignment(myAssignment.id)}
          confirm="Press again to confirm"
        >
          {deleting ? 'Saving...' : 'Withdraw offer for this session'}
        </ButtonConfirm>
        {errorDeleting && (
          <Error className="block mt-4">{errorDeleting.message}</Error>
        )}
        {currentlyAttending}
      </>
    )
  }
  return (
    <>
      {canGatekeep && (
        <div className="mb-4">
          <h3 className="font-bold text-blue-800 text-md mb-1">Gatekeeping</h3>
          <p className="mt-2 text-gray-600">
            Gatekeeping slots are automatically assigned to the first volunteer.
            Press the button to assign yourself to cover this session starting
            at {session.starts.toFormat('HH:mm EEEE d MMMM')}
          </p>
          <ButtonConfirm
            className="mt-4"
            onClick={() => applyForSession(Role.GATEKEEPER)}
            confirm="Press again to confirm"
          >
            {saving ? 'Saving...' : 'Cover this session (gatekeeper)'}
          </ButtonConfirm>
          <p className="mt-2 text-gray-600 text-sm">
            You will be auto approved
          </p>
        </div>
      )}
      {canLifeguard && (
        <div className="mb-4">
          <h3 className="font-bold text-blue-800 text-md mb-1">Lifeguarding</h3>
          <p className="mt-2">
            If you'd like to lifegurard for a session, let us know by pressing
            apply to lifeguard. You will be notified when your offers are
            confirmed.
          </p>
          <ButtonConfirm
            className="mt-4"
            onClick={() => applyForSession(Role.LIFEGUARD)}
            confirm="Press again to confirm"
          >
            {saving
              ? 'Saving...'
              : 'Register availability for this session (lifeguard)'}
          </ButtonConfirm>
          <p className="mt-2 text-gray-600 text-sm">
            You will be notified if you are assigned
          </p>
        </div>
      )}
      {error && <Error className="block mt-4">{error.message}</Error>}

      {currentlyAttending}
    </>
  )
}

// TODO abstract this to a shared place
interface Props {
  sessionId: Session['id']
  close: () => void

  // This is a bodge to give these things to SessionPanel, so it can mount for animating and not have to flash
  setLoading: (loading: boolean) => void
  setRefetch: (refetch: (options?: any) => Promise<any>) => void
  setSession: (session?: Session) => void
}
const ReadonlySessionPanel = ({
  sessionId,
  setLoading,
  setRefetch,
  setSession,
}: Props): JSX.Element => {
  const { data, loading, refetch, error } = useQuery<
    SessionPanelReadOnlyQuery,
    SessionPanelReadOnlyQueryVariables
  >(queries.SESSION_PANEL_READ_ONLY, {
    variables: {
      sessionId,
    },
  })

  // Parse Luxon objects for the session that's being loaded
  const session = useMemo(
    (): Session | undefined =>
      data?.session ? parseDateTimes<Session>(data?.session) : undefined,
    [data?.session],
  )
  // Pass these things up to SessionPanel
  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    setRefetch(refetch)
  }, [refetch])

  useEffect(() => {
    setSession(session)
  }, [session])

  if (loading) {
    return <BigLoading loading />
  }

  if (error) {
    return <Error className="mt-6">{error.message}</Error>
  }

  if (!data || !session) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  return (
    <>
      <MyAssignment
        session={session}
        roles={data?.user.roles ?? []}
        personId={data?.user.person.id}
      />

      <div className="mt-6">
        {session.notes && <NoteCard notes={session.notes} />}

        <ReadOnlyOrderInfo orders={data.orders} session={session} />
      </div>
    </>
  )
}

export default ReadonlySessionPanel
