import React from 'react'

import { LoginButton } from '../shared/auth-components'
import Centre from '../shared/centre'
import Error from '../shared/error'
import { ReactComponent as LogoSvg } from '../../img/wsp.svg'

interface Props {
  error?: any
}

const SplashComponent: React.FC<Props> = ({ error }) => (
  <Centre>
    <div className="text-center">
      <span className="bg-blue-900 text-3xl inline-block px-4 py-8 rounded-full">
        <LogoSvg />
      </span>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
      <p className="mt-2 text-center text-sm text-gray-600">
        Or{' '}
        <span className="font-medium text-indigo-600 hover:text-indigo-500">
          register for an account by logging in
        </span>
      </p>
    </div>
    <div className="mt-1 text-center">
      <LoginButton />
    </div>
    {error && (
      <div className="mx-auto py-6">
        <Error>{error}</Error>
      </div>
    )}
  </Centre>
)

export default SplashComponent
