import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Role } from 'globalTypes'
import { ReactComponent as LogoSvg } from 'img/wsp.svg'
import {
  AuthNav,
  RoleNavLink,
  RoleNavLinkProps,
} from 'components/shared/auth-components'
import { thisWeekLink } from 'utils'
import useBadge from 'hooks/useBadge'

const StyledNavLink = (props: RoleNavLinkProps): JSX.Element => (
  <RoleNavLink
    {...props}
    className={({ isActive }) =>
      `px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus-visible:text-white focus-visible:ring-2 focus-visible:ring-gray-700 relative ${
        isActive ? 'text-white bg-gray-900' : ''
      }`
    }
  />
)

const NavLinks = (): JSX.Element => {
  const { needsAnySettings } = useBadge()
  return (
    <>
      <StyledNavLink to={thisWeekLink()}>This week</StyledNavLink>
      <StyledNavLink roles={[Role.MANAGER, Role.ADMIN]} to="/people">
        People
      </StyledNavLink>
      <StyledNavLink
        roles={[Role.MANAGER, Role.ADMIN, Role.READONLY]}
        to="/orders"
      >
        Orders
      </StyledNavLink>
      <StyledNavLink
        roles={[Role.MANAGER, Role.ADMIN, Role.READONLY]}
        to="/courses"
      >
        Lessons
      </StyledNavLink>
      <StyledNavLink
        roles={[
          Role.MANAGER,
          Role.ADMIN,
          Role.TEACHER,
          Role.LIFEGUARD,
          Role.GATEKEEPER,
          Role.READONLY,
        ]}
        to="/documents"
      >
        Documents
      </StyledNavLink>
      <StyledNavLink
        to="/settings"
        roles={[
          Role.MANAGER,
          Role.ADMIN,
          Role.TEACHER,
          Role.LIFEGUARD,
          Role.GATEKEEPER,
        ]}
      >
        {needsAnySettings && <div className="badge" />}
        Settings
      </StyledNavLink>
    </>
  )
}

const WideNav = (): JSX.Element => {
  return (
    <div className="hidden sm:flex ml-10 flex-1 flex text-gray-200">
      <NavLinks />
    </div>
  )
}
const NarrowNav = (): JSX.Element => {
  return (
    <div className="sm:hidden">
      <div className="mx-4 flex flex-row text-gray-200 pb-3">
        <div className="flex-1 mx-2">
          <AuthNav />
        </div>

        <div className="flex flex-col flex-1">
          <NavLinks />
        </div>
      </div>
    </div>
  )
}
interface BurgerProps {
  isOpen: boolean
}
const VeggieBurger = ({ isOpen }: BurgerProps): JSX.Element =>
  isOpen ? (
    <svg
      className="block h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  ) : (
    <svg
      className="block h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  )

export const Nav = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!isOpen)
  }

  return (
    <nav className="bg-gray-800">
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <NavLink to="/" className="flex-shrink-0 text-xl ml-2">
            <LogoSvg />
          </NavLink>

          <WideNav />

          <AuthNav className="hidden sm:block" />

          <div className="inset-y-0 left-4 sm:hidden">
            <button
              onClick={toggle}
              className="focus:outline-none inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-white"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <VeggieBurger isOpen={isOpen} />
            </button>
          </div>
        </div>
      </div>

      {isOpen && <NarrowNav />}
    </nav>
  )
}
