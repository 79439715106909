import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

import type { SessionPanelQuery_orders as Order } from 'api/__generated__/SessionPanelQuery'

interface NewSessionPanelHeaderProps {
  starts: DateTime
}
export const NewSessionPanelHeader = ({
  starts,
}: NewSessionPanelHeaderProps): JSX.Element => (
  <header className="px-4 sm:px-6 bg-indigo-700 py-6">
    <h2 className="text-lg leading-7 font-medium text-white">
      Create new session on {starts.toFormat('EEEE d MMMM')}
    </h2>
  </header>
)

interface NoteCardProps {
  notes?: string
}
export const NoteCard = ({ notes }: NoteCardProps): JSX.Element => (
  <div className="py-2 px-3 mt-2 mb-6 border-gray-200 border-solid border-2 shadow-sm text-gray-800">
    {notes &&
      notes.split('\n').map((line) => (
        <p key={line} className="my-3">
          {line}
        </p>
      ))}
  </div>
)

interface OrderTableProps {
  orders: Order[]
}
export const OrderTable = ({ orders }: OrderTableProps): JSX.Element => (
  <>
    <h3 className="panel-heading mt-8 mb-2">Online bookings</h3>
    <table className="divide-y divide-gray-200 mb-2 table-fixed w-full">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="th px-2 w-1/2">
            Booker
          </th>
          <th scope="col" className="th px-2">
            Places
          </th>
          <th scope="col" className="th px-2 w-1/5">
            Ref
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
        {orders.length === 0 && (
          <tr>
            <td className="px-2 py-3 text-gray-400" colSpan={3}>
              No orders yet
            </td>
          </tr>
        )}
        {orders.map((order: Order) => (
          <tr key={order.id}>
            <td className="px-2 py-4 text-sm capitalize">
              <Link
                to={`/orders/${order.id}`}
                title="View order"
                className="link"
              >
                {order.firstName} {order.lastName}
              </Link>
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-sm">
              {order.orderSkus.map(({ id, quantity, sku: { name } }) => (
                <p key={id}>
                  {name} &times; {quantity}
                </p>
              ))}
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-sm font-bold">
              {order.ref}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
)
