import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import type {
  AllCoursesQuery,
  AllCoursesQuery_allCourses as Course,
} from 'api/__generated__/AllCoursesQuery'

import * as queries from 'api/queries'
import { Header } from 'components/layout'
import CoursePanel from 'components/courses/course-panel'
import CourseList from 'components/courses/course-table'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'

const CourseComponent = (): JSX.Element => {
  const { courseId } = useParams<{ courseId?: Course['id'] }>()
  const navigate = useNavigate()

  const { data, loading } = useQuery<AllCoursesQuery>(queries.ALL_COURSES)

  if (loading) {
    return <BigLoading loading />
  }

  if (!data) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  const close = () => {
    navigate('/courses', { replace: true })
  }

  const openPanel = (courseId: Course['id']): void => {
    navigate(`/courses/${courseId}`)
  }

  return (
    <>
      <Header>Lesson courses</Header>
      <CourseList
        courses={data.allCourses}
        openPanel={openPanel}
        selectedCourseId={courseId ?? null}
      />

      <CoursePanel courseId={courseId} close={close} />
    </>
  )
}

export default CourseComponent
