import React, { useState, useEffect } from 'react'
import Select, { OnChangeValue } from 'react-select'
import { useMutation } from '@apollo/client'

import type {
  PeoplePanelQuery_person as Person,
  PeoplePanelQuery_allCapabilities as Capability,
} from 'api/__generated__/PeoplePanelQuery'
import type {
  SetCapabilitiesMutation,
  SetCapabilitiesMutationVariables,
} from 'api/__generated__/SetCapabilitiesMutation'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import { Label } from 'components/form'
import { Button, ButtonSec } from 'components/buttons'
import Error from 'components/shared/error'

interface Option<T = string> {
  value: T
  label: string
}

const capToSelectValue = ({
  id,
  name,
}: Capability): Option<Capability['id']> => ({
  value: id,
  label: name,
})
const capabilitiesOptions = (capabilities: Capability[]) =>
  capabilities.map(capToSelectValue)

interface Props {
  person: Person
  close: () => void
  allCapabilities: Capability[]
}

const PersonForm: React.FC<Props> = ({ person, close, allCapabilities }) => {
  const options = capabilitiesOptions(allCapabilities)
  const [caps, setCaps] = useState<
    OnChangeValue<Option<Capability['id']>, true>
  >([])

  const [setCapabilities, { loading: saving, error }] = useMutation<
    SetCapabilitiesMutation,
    SetCapabilitiesMutationVariables
  >(mutations.SET_CAPABILITIES, {
    refetchQueries: [queries.PEOPLE_PANEL],
  })

  useEffect(() => {
    // Only reset if panel is reopened
    const currentCaps = person.capabilities.map(capToSelectValue)
    setCaps(currentCaps)
  }, [person.capabilities])

  const onChangeCaps = (
    values: OnChangeValue<Option<Capability['id']>, true>,
  ): void => {
    setCaps(values)
  }
  const save = () => {
    const capabilityIds = (caps || []).map(({ value }) => value)
    setCapabilities({ variables: { personId: person.id, capabilityIds } })
  }

  return (
    <>
      <div className="mb-4">
        <Label>Capabilities</Label>
        <p className="text-sm text-gray-600 -mt-1 mb-2">
          Add more capabilities below to enable roles
        </p>
        <Select
          options={options}
          onChange={onChangeCaps}
          value={caps}
          className="select"
          isMulti
        />
      </div>

      <div>
        <Button type="publish" onClick={save}>
          {saving ? 'Saving...' : 'Save'}
        </Button>

        <ButtonSec type="cancel" onClick={close}>
          Cancel
        </ButtonSec>
      </div>
      {error && <Error className="block mt-4">{error.message}</Error>}
    </>
  )
}

export default PersonForm
