import { useState, useRef } from 'react'

import type {
  DocumentPanelQuery,
  DocumentPanelQuery_document as Document,
} from 'api/__generated__/DocumentPanelQuery'

import Panel from 'components/panel'
import EditDocumentPanel from 'components/documents/edit-document-panel'
import GenericPanelHeader from 'components/panel/generic-panel-header'
import NewDocumentPanel from './new-document-panel'

interface Props {
  documentId?: Document['id'] | 'new'
  close: () => void
}

const DocumentPanel = ({ documentId, close }: Props): JSX.Element => {
  // Passing data, loading and refetch back out here to make the header work
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<DocumentPanelQuery | undefined>(undefined)
  const refetchRef = useRef<() => void>(() => null)
  const setRefetch = (refetch: () => Promise<void>): void => {
    refetchRef.current = refetch
  }
  const newDocument = documentId === 'new'
  const panelOpen = Boolean(documentId || newDocument)

  let header
  if (data?.document) {
    header = (
      <GenericPanelHeader
        title={data.document.title ?? 'Temp document'}
        reload={refetchRef.current}
        loading={loading}
        icon="📂"
      />
    )
  }
  if (newDocument) {
    header = (
      <GenericPanelHeader loading={false} title={'New document'} icon="📂" />
    )
  }

  return (
    <Panel isOpen={panelOpen} close={close} header={header}>
      {documentId && !newDocument && (
        <EditDocumentPanel
          documentId={documentId}
          setData={setData}
          setLoading={setLoading}
          setRefetch={setRefetch}
          close={close}
        />
      )}
      {newDocument && <NewDocumentPanel close={close} />}
    </Panel>
  )
}

export default DocumentPanel
