import { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

import type {
  OrderPanelQuery,
  OrderPanelQueryVariables,
  OrderPanelQuery_order as Order,
} from 'api/__generated__/OrderPanelQuery'
import type {
  ResendOrderConfirmationMutation,
  ResendOrderConfirmationMutationVariables,
} from 'api/__generated__/ResendOrderConfirmationMutation'
import type {
  MarkOrderPaidMutation,
  MarkOrderPaidMutationVariables,
} from 'api/__generated__/MarkOrderPaidMutation'

import type {
  CancelOrderMutation,
  CancelOrderMutationVariables,
} from 'api/__generated__/CancelOrderMutation'
import { OrderStatus, PaymentType, Role } from 'globalTypes'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import Pill, { PillVariant } from 'components/shared/pill'
import { BigLoading } from 'components/shared/loading'
import Error from 'components/shared/error'
import SessionLink from 'components/SessionLink'
import { ButtonConfirm, ButtonDanger } from 'components/buttons'
import { orderStatusPillVariants } from './order-table'
import ChangeSession from './change-session'
import ChangeCourse from './change-course'
import { RequireRole } from 'components/shared/auth-components'

interface OrderTableProps {
  order: Order
}
export const OrderTable = ({ order }: OrderTableProps): JSX.Element => {
  const {
    firstName,
    lastName,
    email,
    phone,
    orderStatus,
    orderSkus,
    ref,
    paymentType,
    session,
    course,
    notes,
    paidAt,
    insertedAt,
  } = order
  let startsString
  if (session) {
    const starts = DateTime.fromISO(session.starts)
    startsString = starts.toLocaleString(DateTime.DATETIME_MED)
  }

  const created = DateTime.fromISO(insertedAt).toLocaleString(
    DateTime.DATETIME_MED,
  )

  const paidAtStr = DateTime.fromISO(paidAt).toLocaleString(
    DateTime.DATETIME_MED,
  )
  return (
    <>
      <h3 className="panel-heading">Contact details</h3>

      <table className="min-w-full divide-y divide-gray-200 mb-6 table-fixed">
        <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
          <tr>
            <th scope="row" className="th--col">
              Name
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm capitalize w-1/2">
              {firstName} {lastName}
            </td>
          </tr>
          <tr>
            <th scope="row" className="th--col">
              Email
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm">{email}</td>
          </tr>
          <tr>
            <th scope="row" className="th--col">
              Phone
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm">{phone}</td>
          </tr>
        </tbody>
      </table>

      <h3 className="panel-heading">Order info</h3>
      <table className="min-w-full divide-y divide-gray-200 mb-6 table-fixed">
        <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
          <tr>
            <th scope="col" className="th--col">
              Places booked
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm w-1/2">
              {orderSkus.map(({ id, quantity, sku: { name } }) => (
                <p key={id}>
                  {name} &times; {quantity}
                </p>
              ))}
            </td>
          </tr>
          <tr>
            <th scope="col" className="th--col">
              Booking Ref
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm font-bold">
              {ref}
            </td>
          </tr>
          {notes && (
            <tr>
              <th scope="col" className="th--col">
                {course ? "Kids' name(s)" : 'notes'}
              </th>
              <td className="px-2 py-4 whitespace-nowrap text-sm">{notes}</td>
            </tr>
          )}
          <tr>
            <th scope="col" className="th--col">
              Order status
            </th>
            <td className="px-2 py-2 whitespace-nowrap text-sm font-bold">
              <Pill
                variant={orderStatusPillVariants[orderStatus]}
                title={orderStatus.toLowerCase()}
              >
                {orderStatus.toLowerCase()}
              </Pill>
            </td>
          </tr>
          <tr>
            <th scope="col" className="th--col">
              Payment type
            </th>
            <td className="px-2 py-2 whitespace-nowrap text-sm font-bold">
              <Pill
                variant={
                  paymentType == PaymentType.CARD
                    ? PillVariant.blue
                    : PillVariant.green
                }
                title={paymentType.toLowerCase()}
              >
                {paymentType.toLowerCase()}
              </Pill>
            </td>
          </tr>
          <tr>
            <th scope="col" className="th--col">
              Paid at
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm">
              {paidAt ? paidAtStr : 'Un paid'}
            </td>
          </tr>
          <tr>
            <th scope="col" className="th--col">
              Order created
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm">{created}</td>
          </tr>
        </tbody>
      </table>
      {session && (
        <>
          <h3 className="panel-heading">Session info</h3>
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
              <tr>
                <th scope="col" className="th--col w-1/2">
                  Starts
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm">
                  {startsString}
                </td>
              </tr>
              <tr>
                <th scope="col" className="th--col w-1/2"></th>
                <td className="px-2 py-4 whitespace-nowrap text-sm flex flex-row space-between">
                  <div className="flex-1">
                    <SessionLink className="link" session={session}>
                      View Session
                    </SessionLink>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
            <ChangeSession orderId={order.id} />
          </RequireRole>
        </>
      )}

      {course && (
        <>
          <h3 className="panel-heading">Lesson info</h3>
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
              <tr>
                <th scope="col" className="th--col w-1/2">
                  Course
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm capitalize">
                  {course.name}
                </td>
              </tr>
              <tr>
                <th scope="col" className="th--col w-1/2">
                  Level
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm capitalize">
                  {course.level.toLowerCase()}
                </td>
              </tr>
              <tr>
                <th scope="col" className="th--col">
                  Capacity
                </th>
                <td className="px-2 py-4 whitespace-nowrap text-sm">
                  {course.bookableCapacity}
                </td>
              </tr>
              <tr>
                <th scope="col" className="th--col w-1/2"></th>
                <td className="px-2 py-4 whitespace-nowrap text-sm">
                  <Link className="link" to={`/courses/${course.id}`}>
                    View Course
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>

          <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
            <ChangeCourse orderId={order.id} />
          </RequireRole>
        </>
      )}
    </>
  )
}

interface Props {
  orderId: Order['id']
  close: () => void
  // This is a bodge to give these things to SessionPanel, so it can mount for animating and not have to flash
  setLoading: (loading: boolean) => void
  setRefetch: (refetch: (options?: any) => Promise<any>) => void
  setData: (data?: OrderPanelQuery) => void
}

const EditOrderPanel = ({
  orderId,
  setLoading,
  setRefetch,
  setData,
}: Props): JSX.Element | null => {
  const { data, loading, refetch, error } = useQuery<
    OrderPanelQuery,
    OrderPanelQueryVariables
  >(queries.ORDER_PANEL, {
    variables: { orderId },
  })

  const [resendConfirmation, { loading: sending }] = useMutation<
    ResendOrderConfirmationMutation,
    ResendOrderConfirmationMutationVariables
  >(mutations.RESEND_ORDER_CONFIRMATION, { variables: { orderId } })
  const [markOrderPaid, { loading: saving }] = useMutation<
    MarkOrderPaidMutation,
    MarkOrderPaidMutationVariables
  >(mutations.MARK_ORDER_PAID, {
    variables: { orderId },
    refetchQueries: [queries.ORDER_PANEL],
  })

  const [cancelOrder, { loading: canceling }] = useMutation<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >(mutations.CANCEL_ORDER_MUTATION, {
    variables: { orderId },
    refetchQueries: [queries.ORDER_PANEL],
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    setRefetch(refetch)
  }, [refetch])

  useEffect(() => {
    setData(data)
  }, [data])

  if (loading) {
    return <BigLoading loading />
  }
  if (error) {
    return <Error className="mt-6">{error.message}</Error>
  }

  if (!data) {
    return <Error className="mt-6">Oh dear</Error>
  }

  const isCardPayment = data.order.paymentType === PaymentType.CARD
  const isSuccesfulOrder = data.order.orderStatus === OrderStatus.SUCCEEDED

  const isCancelable = [
    OrderStatus.CREATED,
    OrderStatus.NEEDS_REFUND,
    OrderStatus.SUCCEEDED,
  ].includes(data.order.orderStatus)
  const isRefundable = [
    OrderStatus.NEEDS_REFUND,
    OrderStatus.SUCCEEDED,
  ].includes(data.order.orderStatus)

  const doCancelOrder = () => {
    if (isSuccesfulOrder && isCardPayment) {
      window.open(
        `https://dashboard.stripe.com/payments/${data?.order.paymentIntentId}`,
      )
    }
    void cancelOrder()
  }

  return (
    <>
      <div className="flex-1 pb-8">
        <OrderTable order={data.order} />

        <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
          <h3 className="panel-heading mt-2">Order operations</h3>

          <p className="text-sm text-gray-600 mb-4">
            If you need to re-send the order confirmation to the customer.
          </p>
          <ButtonConfirm
            onClick={resendConfirmation}
            className="align-self-start"
            disabled={
              sending || data.order.orderStatus !== OrderStatus.SUCCEEDED
            }
            loading={sending}
            confirm={`Send again to ${data.order.email}`}
          >
            Re-send confirmation
          </ButtonConfirm>

          <p className="text-sm text-gray-600 mb-2 mt-4">
            If a customer has paid manually, by means other than the website,
            then you can set their order as paid here.
          </p>
          <p className="text-sm text-gray-600 mb-4">
            The customer will get a confirmation email when you do this.
          </p>
          <ButtonConfirm
            onClick={markOrderPaid}
            className="align-self-start"
            disabled={
              saving ||
              !!data.order.paidAt ||
              data.order.orderStatus !== OrderStatus.CREATED
            }
            loading={saving}
          >
            Mark paid with manual payment
          </ButtonConfirm>

          {isCardPayment && (
            <>
              <p className="text-sm text-gray-600 mb-4 mt-4">
                To cancel an order that's been paid, please issue a refund on
                Stripe
              </p>
              <ButtonDanger
                onClick={doCancelOrder}
                className="align-self-start"
                disabled={canceling || !isCancelable}
                loading={canceling}
              >
                Cancel order{isRefundable && ' and refund'}
              </ButtonDanger>
            </>
          )}
        </RequireRole>
      </div>
    </>
  )
}

export default EditOrderPanel
