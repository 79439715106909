import type {
  PeopleQuery_people as Person,
  PeopleQuery_people_capabilities as Capability,
} from 'api/__generated__/PeopleQuery'

import { InlineButtonPrimary } from 'components/buttons'
import Pill, { PillVariant } from 'components/shared/pill'
import { userStatusPillVariants } from 'components/people/pill-variants'

interface ItemProps {
  person: Person
  edit: () => void
  selected: boolean
}
const Item = ({ person, edit }: ItemProps): JSX.Element => {
  const { firstName, lastName, phone, picture, capabilities, roles } = person
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img
              className="h-10 w-10 rounded-full"
              src={picture}
              alt={firstName ?? undefined}
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {firstName} {lastName}
            </div>
            <div className="text-sm font-medium text-gray-400 mt-1">
              {phone}
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 text-sm">
        {roles?.map((role: string) => (
          <Pill key={role} variant={PillVariant.blue} className="capitalize">
            {role.toLowerCase()}
          </Pill>
        ))}
      </td>
      <td className="px-6 py-4 text-sm hidden md:table-cell">
        {capabilities?.map((capability: Capability) => (
          <Pill
            key={capability.id}
            variant={PillVariant.green}
            title={capability.description}
          >
            {capability.name}
          </Pill>
        ))}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm">
        {person.users.map((user) => (
          <Pill
            key={user.id}
            variant={userStatusPillVariants[user.status]}
            className="capitalize"
          >
            {user.status.toLowerCase()}
          </Pill>
        ))}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <InlineButtonPrimary onClick={edit} className="">
          Edit
        </InlineButtonPrimary>
      </td>
    </tr>
  )
}

interface PeopleListProps {
  people: Person[]
  selectedPersonId: Person['id'] | null
  openPanel: (personId: Person['id']) => void
}

const PeopleList = ({
  people,
  openPanel,
  selectedPersonId,
}: PeopleListProps): JSX.Element => (
  <div className="max-w-full xl:max-w-6xl mx-auto py-6 sm:px-6 flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Roles
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
                >
                  Capabilities
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {people.map((person: Person) => (
                <Item
                  person={person}
                  key={person.id}
                  edit={() => openPanel(person.id)}
                  selected={person.id === selectedPersonId}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)

export default PeopleList
