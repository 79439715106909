import React from 'react'
import { DateTime } from 'luxon'

import { HOUR_RANGE } from 'utils'
import { SessionOrPlaceholder } from 'api/sessions'

interface WeekColProps {
  date: DateTime
  children: React.ReactNode
}
export const WeekCol = ({ children, date }: WeekColProps): JSX.Element => {
  const now = DateTime.local()
  const dayClass = now.hasSame(date, 'day') ? 'bg-gray-200' : 'bg-gray-100'

  return (
    <div
      className={
        'relative grid grid-rows-52 grid-week-col min-h-60v md:min-h-50v ' +
        dayClass
      }
    >
      <div className="absolute grid grid-rows-26 h-full w-full">
        {HOUR_RANGE.map((hour) => {
          const dt = date.set({ hour })
          const hourClass = now.hasSame(dt, 'hour')
            ? 'border-blue-800'
            : 'border-white'
          return (
            <span
              className={'border-t span-hour ' + hourClass}
              key={dt.toISO()}
            ></span>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export const Hours = (): JSX.Element => {
  return (
    <div className="relative grid grid-rows-26 text-xs text-gray-600">
      {HOUR_RANGE.map((h) => (
        <span
          className="span-hour z-1 relative text-right"
          style={{ marginTop: '-0.6em' }}
          key={h}
        >
          {h}:00
        </span>
      ))}
    </div>
  )
}

const WeekDay = ({ date }: { date: DateTime }): JSX.Element => (
  <div
    className="grid-week-col bg-gray-100 px-2 py-1 text-sm text-gray-800"
    title={date.toISODate()}
  >
    <span className="hidden sm:inline lg:hidden">{date.toFormat('EEE')}</span>
    <span className="inline sm:hidden lg:inline">{date.toFormat('EEEE')}</span>
    <span className="text-gray-500 ml-2">{date.toFormat('d MMM')}</span>
  </div>
)

export const WeekDays = ({
  weekStart,
}: {
  weekStart: DateTime
}): JSX.Element => (
  <div className="grid grid-week grid-week-col gap-1 px-1 pb-2 bg-white">
    <div></div>
    {[...Array(7).keys()]
      .map((day) => weekStart.plus({ day }))
      .map((date) => (
        <WeekDay key={date.toISODate()} date={date} />
      ))}
  </div>
)

export interface Props {
  sessions: SessionOrPlaceholder[][]
  weekStart: DateTime
  renderSession: (session: SessionOrPlaceholder) => React.ReactElement | null
}
const WeekComponent = ({
  sessions,
  weekStart,
  renderSession,
}: Props): JSX.Element | null => {
  if (!weekStart.isValid) {
    return null
  }
  return (
    <div className="container week-scroll">
      <WeekDays weekStart={weekStart} />
      <div className="grid grid-week gap-1 px-1">
        <Hours />
        {sessions.length &&
          sessions.map((day: SessionOrPlaceholder[], index: number) => {
            const date = weekStart.plus({ day: index })
            return (
              <WeekCol date={date} key={date.toISODate() + 'weekcol'}>
                {day.map(renderSession)}
              </WeekCol>
            )
          })}
      </div>
    </div>
  )
}

export default WeekComponent
