import React from 'react'

interface Props {
  className?: string
}
const Centre: React.FC<Props> = ({ children, className }) => (
  <div
    className={
      'min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 ' +
      className
    }
  >
    <div className="max-w-md w-full space-y-8">{children}</div>
  </div>
)

export default Centre
