import { useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import type {
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
} from 'api/__generated__/CreateDocumentMutation'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import { groupGQLErrors } from 'utils'
import { Button, ButtonSec } from 'components/buttons'
import DocumentForm, { FormState } from './document-form'

interface Props {
  close: () => void
}

const NewDocumentPanel = ({ close }: Props): JSX.Element | null => {
  const navigate = useNavigate()
  const [createDocument, { loading: saving, error: errorSaving }] = useMutation<
    CreateDocumentMutation,
    CreateDocumentMutationVariables
  >(mutations.CREATE_DOCUMENT_MUTATION, {
    refetchQueries: [queries.DOCUMENTS],
  })

  const [formState, setFormState] = useState<FormState>({
    title: '',
    category: '',
    description: '',
  })

  const save = async () => {
    const res = await createDocument({ variables: formState })
    if (res.data) {
      navigate(`/document/${res.data?.createDocument.id}`, { replace: true })
    }
  }

  const fieldErrors = useMemo(
    () => groupGQLErrors<CreateDocumentMutationVariables>(errorSaving),
    [errorSaving],
  )

  return (
    <div className="flex-1">
      <div className="mb-4">
        <DocumentForm
          formState={formState}
          setFormState={setFormState}
          save={save}
          fieldErrors={fieldErrors}
        />

        <div className="flex mb-4">
          <ButtonSec onClick={close}>Cancel</ButtonSec>
          <Button onClick={save} loading={saving}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewDocumentPanel
