import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import type {
  CoursePanelQuery,
  CoursePanelQueryVariables,
  CoursePanelQuery_course as Course,
  CoursePanelQuery_orders as Order,
} from 'api/__generated__/CoursePanelQuery'

import * as queries from 'api/queries'
import { Header } from 'components/layout'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'
import { useEffect } from 'react'

const Item = ({ order }: { order: Order }): JSX.Element => {
  const { firstName, lastName, ref, email, phone, notes } = order
  const names = notes?.split(', ')
  return (
    <>
      {names?.map((name) => {
        return (
          <tr key={name}>
            <td className="px-6 py-4">
              <div className="flex items-center">
                <div className="text-sm font-medium text-gray-900 capitalize">
                  {firstName} {lastName}
                </div>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{ref}</td>
            <td className="px-6 py-4 whitespace-nowrap">{email}</td>
            <td className="px-6 py-4 whitespace-nowrap">{phone}</td>
            <td className="px-6 py-4 whitespace-nowrap">{name}</td>
          </tr>
        )
      })}
    </>
  )
}

const PrintCourseComponent = (): JSX.Element => {
  const { courseId } = useParams<{ courseId: Course['id'] }>()
  const navigate = useNavigate()

  const { data, loading } = useQuery<
    CoursePanelQuery,
    CoursePanelQueryVariables
  >(queries.COURSE_PANEL, {
    variables: { courseId },
  })

  useEffect(() => {
    if (!loading) {
      window.print()
    }
  }, [loading])

  if (loading) {
    return <BigLoading loading />
  }

  if (!data) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  const close = () => {
    navigate('/courses', { replace: true })
  }

  return (
    <>
      <Header>{data.course.name}</Header>
      <div className="max-w-full xl:max-w-6xl mx-auto pt-6 sm:px-6 flex flex-col">
        <div className="border-gray-200 border-2 rounded-md py-4 px-4 text-center">
          <p>
            Please be careful with this printout and dispose of it securely as
            soon as you're finished with it.
          </p>
        </div>
      </div>
      <div className="max-w-full xl:max-w-6xl mx-auto py-6 sm:px-6 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Booker
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ref
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Child
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.orders.map((order) => (
                    <Item order={order} key={order.id} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrintCourseComponent
