import { useQuery } from '@apollo/client'

import type { UserBadgeQuery } from 'api/__generated__/UserBadgeQuery'

import * as queries from 'api/queries'
import { ContactPreference } from 'globalTypes'

interface IUseBadge {
  loading: boolean
  needsAnySettings: boolean
  needsContactPrefs: boolean
  needsPhoneNumber: boolean
  needsPhoneNumberForSms: boolean
  needsName: boolean
  firstNameIsEmail: boolean
  noLastName: boolean
}
const useBadge = (): IUseBadge => {
  const { data, loading } = useQuery<UserBadgeQuery>(queries.USER_BADGE, {
    fetchPolicy: 'cache-first',
  })

  const hasContactPrefs = Boolean(
    data && data.user.person.contactPreference.length > 0,
  )

  const needsPhoneNumber = Boolean(!loading && data && !data.user.person.phone)

  const needsPhoneNumberForSms = Boolean(
    !loading &&
      data &&
      data.user.person.contactPreference.includes(ContactPreference.SMS) &&
      !data.user.person.phone,
  )

  const noLastName = Boolean(!loading && data && !data.user.person.lastName)
  const firstNameIsEmail = Boolean(
    !loading && data && data.user.person.firstName === data.user.email,
  )
  const needsName = noLastName || firstNameIsEmail

  const needsAnySettings =
    !loading && (!hasContactPrefs || needsPhoneNumber || needsName)

  const needsContactPrefs = !loading && !hasContactPrefs

  return {
    loading,
    needsAnySettings,
    needsContactPrefs,
    needsPhoneNumber,
    needsPhoneNumberForSms,
    needsName,
    firstNameIsEmail,
    noLastName,
  }
}

export default useBadge
