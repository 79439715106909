import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import type {
  OrderQuery,
  OrderQueryVariables,
  OrderQuery_orders as Order,
} from 'api/__generated__/OrderQuery'

import { OrderStatus, OrderType } from 'globalTypes'
import * as queries from 'api/queries'
import { Header } from 'components/layout'
import OrderPanel from 'components/orders/order-panel'
import OrderList from 'components/orders/order-table'
import Error from 'components/shared/error'
import OrderFilter from 'components/orders/order-filter'
import { Reload } from 'components/buttons'

const OrderComponent = (): JSX.Element => {
  const { orderId } = useParams<{ orderId?: Order['id'] }>()
  const navigate = useNavigate()
  const now = DateTime.now()
  const [orderType, setOrderType] = useState<OrderType | undefined>(undefined)
  const [orderStatus, setOrderStatus] = useState<OrderStatus | undefined>(
    undefined,
  )
  const [since, setSince] = useState<DateTime>(now.startOf('month'))
  const to = since.endOf('month').toISODate()

  const { data, loading, refetch, stopPolling, startPolling } = useQuery<
    OrderQuery,
    OrderQueryVariables
  >(queries.ORDERS, {
    variables: { since: since.toISODate(), to, orderType, orderStatus },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    startPolling(10_000)
    // Stop polling on unmount
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  if (!data && !loading) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  const close = () => {
    navigate('/orders', { replace: true })
  }

  const openPanel = (orderId: Order['id']): void => {
    navigate(`/orders/${orderId}`)
  }

  return (
    <>
      <Header
        before={
          <>
            <Reload
              onClick={refetch}
              className={`text-gray-400 ${
                loading && 'text-gray-300 animate-spin'
              }`}
            />
            <OrderFilter
              orderStatus={orderStatus}
              orderType={orderType}
              since={since}
              setOrderType={setOrderType}
              setOrderStatus={setOrderStatus}
              setSince={setSince}
            />
          </>
        }
      >
        Orders{' '}
        {data && (
          <span className="text-gray-500 text-xl font-normal">
            ({data.orders.length})
          </span>
        )}
      </Header>
      <OrderList
        orders={data?.orders ?? []}
        openPanel={openPanel}
        selectedOrderId={orderId ?? null}
      />

      {data && data.orders.length == 0 && (
        <div className="max-w-full xl:max-w-6xl mx-auto py-6 sm:px-6 flex flex-col">
          <p className="text-sm text-gray-400 text-center">
            No orders for this criteria, make sure you have the right filters on
            👆
          </p>
        </div>
      )}

      <OrderPanel orderId={orderId} close={close} />
    </>
  )
}

export default OrderComponent
