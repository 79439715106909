import { Link } from 'react-router-dom'
import type { SessionPanelQuery_session as Session } from 'api/__generated__/SessionPanelQuery'
import { SessionType } from 'globalTypes'

const SessionCourseTable = ({
  session: { courses, type },
}: {
  session: Session
}): JSX.Element | null => {
  if (type !== SessionType.LESSON) {
    return null
  }

  if (courses.length < 1) {
    return null
  }

  return (
    <>
      <h3 className="panel-heading mt-2 mb-2">Lesson courses</h3>

      <table className="min-w-full divide-y divide-gray-200 mb-8 table-fixed">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th px-2 w-1/2">
              Course name
            </th>
            <th scope="col" className="th px-2">
              Capacity
            </th>
            <th scope="col" className="th px-2">
              Level
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
          {courses.map((course) => (
            <tr key={course.id}>
              <td className="px-2 py-4 whitespace-nowrap text-sm capitalize">
                <Link
                  to={`/courses/${course.id}`}
                  title="View course"
                  className="link"
                >
                  {course.name}
                </Link>
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm">
                {course.bookableCapacity}
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm capitalize">
                {course.level.toLowerCase()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default SessionCourseTable
