/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthType {
  GOOGLE = 'GOOGLE',
  PASSWORD = 'PASSWORD',
}

export enum ContactPreference {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum CopyAssignmentPeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export enum DocumentEventType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
}

export enum Flag {
  OK = 'OK',
  WARNING = 'WARNING',
}

export enum LessonLevel {
  ADVANCED = 'ADVANCED',
  BEGINNER = 'BEGINNER',
  IMPROVER = 'IMPROVER',
}

export enum OrderStatus {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  NEEDS_REFUND = 'NEEDS_REFUND',
  REFUNDED = 'REFUNDED',
  SUCCEEDED = 'SUCCEEDED',
}

export enum OrderType {
  LESSON = 'LESSON',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum PaymentType {
  CARD = 'CARD',
  MANUAL = 'MANUAL',
}

export enum ReminderPreference {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export enum Role {
  ADMIN = 'ADMIN',
  GATEKEEPER = 'GATEKEEPER',
  LIFEGUARD = 'LIFEGUARD',
  MANAGER = 'MANAGER',
  READONLY = 'READONLY',
  TEACHER = 'TEACHER',
  TECH_MANAGER = 'TECH_MANAGER',
}

export enum SessionType {
  ADULT = 'ADULT',
  ADULT_INFANT = 'ADULT_INFANT',
  BOOKING = 'BOOKING',
  FAMILY = 'FAMILY',
  LANES = 'LANES',
  LESSON = 'LESSON',
  PUBLIC = 'PUBLIC',
  RESERVED = 'RESERVED',
  SCHOOL = 'SCHOOL',
  THERAPEUTIC = 'THERAPEUTIC',
  TRAINING = 'TRAINING',
}

export enum UserStatus {
  INACTIVE = 'INACTIVE',
  OK = 'OK',
  PENDING = 'PENDING',
}

export interface OrderSkuInput {
  quantity: number
  skuId: string
}

//==============================================================
// END Enums and Input Objects
//==============================================================
