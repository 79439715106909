import { useRef, useState } from 'react'

import type { SessionPanelReadOnlyQuery_session as Session } from 'api/__generated__/SessionPanelReadOnlyQuery'

import Panel from 'components/panel'
import SessionPanelHeader from 'components/panel/session-panel-header'
import ReadonlySessionPanelPanel from 'components/panel/readonly-session-panel/readonly-session-panel'

interface Props {
  sessionId?: Session['id']
  close: () => void
}
const ReadonlySessionPanel = ({ sessionId, close }: Props): JSX.Element => {
  // Passing session, loading and refetch back out here to make the header work
  const [loading, setLoading] = useState<boolean>(false)
  const [session, setSession] = useState<Session | undefined>(undefined)
  const refetchRef = useRef<() => void>(() => null)
  const setRefetch = (refetch: () => Promise<void>): void => {
    refetchRef.current = refetch
  }
  let header = undefined
  if (sessionId) {
    header = (
      <SessionPanelHeader
        starts={session?.starts}
        ends={session?.ends}
        type={session?.type}
        loading={loading}
        reload={refetchRef.current}
      />
    )
  }

  return (
    <Panel isOpen={Boolean(sessionId)} close={close} header={header}>
      {sessionId && (
        <ReadonlySessionPanelPanel
          sessionId={sessionId}
          close={close}
          setLoading={setLoading}
          setRefetch={setRefetch}
          setSession={setSession}
        />
      )}
    </Panel>
  )
}

export default ReadonlySessionPanel
