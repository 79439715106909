import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import type {
  PeoplePanelQuery,
  PeoplePanelQueryVariables,
  PeoplePanelQuery_person as Person,
  PeoplePanelQuery_person_users as User,
} from 'api/__generated__/PeoplePanelQuery'
import type {
  SetActivationMutation,
  SetActivationMutationVariables,
} from 'api/__generated__/SetActivationMutation'
import { UserStatus } from 'globalTypes'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import Pill, { PillVariant } from 'components/shared/pill'
import { userStatusPillVariants } from 'components/people/pill-variants'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'
import {
  InlineButtonDanger,
  InlineButtonPrimary,
  Plus,
} from 'components/buttons'
import { Label } from 'components/form'
import CapabilityHelp from 'components/panel/person-panel/capability-help'
import PersonForm from 'components/panel/person-panel/person-forms'
import { useUser } from 'components/auth-context'
import { ucfirst } from 'utils'

interface Props {
  personId: Person['id']
  close: () => void
  // This is a bodge to give these things to SessionPanel, so it can mount for animating and not have to flash
  setLoading: (loading: boolean) => void
  setRefetch: (refetch: (options?: any) => Promise<any>) => void
  setData: (data?: PeoplePanelQuery) => void
}

const EditPersonPanel = ({
  personId,
  setLoading,
  setRefetch,
  setData,
}: Props): JSX.Element | null => {
  const { data, loading, refetch, error } = useQuery<
    PeoplePanelQuery,
    PeoplePanelQueryVariables
  >(queries.PEOPLE_PANEL, {
    variables: { personId },
    fetchPolicy: 'cache-and-network',
  })

  const [setActivation, { loading: saving }] = useMutation<
    SetActivationMutation,
    SetActivationMutationVariables
  >(mutations.SET_USER_ACTIVATION, { refetchQueries: [queries.PEOPLE_PANEL] })

  const [showInfo, setShowInfo] = useState<boolean>(false)
  const currentUser = useUser()

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    setRefetch(refetch)
  }, [refetch])

  useEffect(() => {
    setData(data)
  }, [data])

  const activate = async (userId: User['id']): Promise<void> => {
    await setActivation({
      variables: { userId, status: UserStatus.OK },
    })
  }

  const deactivate = async (userId: User['id']): Promise<void> => {
    await setActivation({
      variables: { userId, status: UserStatus.INACTIVE },
    })
  }

  if (loading) {
    return <BigLoading loading />
  }
  if (error) {
    return <Error className="mt-6">{error.message}</Error>
  }

  if (!data) {
    return <Error className="mt-6">Oh dear</Error>
  }

  const hasContactPreference = data.person.contactPreference.length > 0

  return (
    <div className="pb-8">
      <h3
        className="panel-heading"
        title="This is the method by which we remind staff of their upcoming sessions"
      >
        Contact preference
      </h3>
      {data.person.contactPreference.length ? (
        <p className="pb-2">
          {data.person.contactPreference.map((cp) => (
            <Pill key={cp}>{cp.toUpperCase()}</Pill>
          ))}
        </p>
      ) : (
        <p className="pb-2 text-sm text-gray-400">None</p>
      )}

      <h3
        className="panel-heading"
        title="Which reminders do they choose to receive"
      >
        Reminder preference
      </h3>
      {data.person.reminderPreference.length ? (
        <p className="pb-2">
          {data.person.reminderPreference.map((cp) => (
            <Pill
              key={cp}
              variant={
                hasContactPreference ? PillVariant.green : PillVariant.gray
              }
            >
              {ucfirst(cp)}
            </Pill>
          ))}
        </p>
      ) : (
        <p className="pb-2 text-sm text-gray-400">None</p>
      )}

      <h3 className="panel-heading">Account activation</h3>

      <div className="pb-4">
        {data.person.users.map((user) => {
          const isSelf = user.id === currentUser.user?.id
          return (
            <div className="flex items-baseline" key={user.id}>
              <Pill variant={PillVariant.gray}>
                {user.authType.toLowerCase()}
              </Pill>

              <Pill variant={userStatusPillVariants[user.status]}>
                {user.status.toLowerCase()}
              </Pill>
              <p className="text-sm text-gray-400 flex-1">{user.email}</p>
              <div>
                {user.status === UserStatus.OK ? (
                  <InlineButtonDanger
                    disabled={saving || isSelf}
                    onClick={async () => await deactivate(user.id)}
                  >
                    Deactivate
                  </InlineButtonDanger>
                ) : (
                  <InlineButtonPrimary
                    disabled={saving || isSelf}
                    onClick={() => activate(user.id)}
                    title={isSelf ? 'This is you' : 'Deactivate this login'}
                  >
                    Activate
                  </InlineButtonPrimary>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <h3 className="panel-heading">Roles & Capabilities</h3>
      <Label>Active roles</Label>
      <p className="text-sm text-gray-600 -mt-1 mb-1">
        Roles are applied based on valid capabilities
      </p>
      <p className="whitespace-nowrap text-sm pb-4">
        {data.person.roles.map((role) => (
          <Pill key={role} variant={PillVariant.blue} className="capitalize">
            {role.toLowerCase()}
          </Pill>
        ))}
      </p>
      <PersonForm
        person={data.person}
        allCapabilities={data.allCapabilities}
        close={close}
      />

      <div className="bg-blue-100 border-blue-200 flex border-t-2 border-b-2 p-2 mt-4">
        <Plus
          onClick={() => setShowInfo(!showInfo)}
          isOpen={showInfo}
          className="self-center mt-0"
        />
        <p className="text-sm text-gray-600 flex-1 pl-4">
          Each role has a set of required capabilities that must be valid before
          the person can perform the role.
        </p>
      </div>

      {showInfo && <CapabilityHelp allCapabilities={data.allCapabilities} />}
    </div>
  )
}

export default EditPersonPanel
