import React from 'react'
import { DateTime } from 'luxon'

import { ROWS_PER_HOUR, STARTING_HOUR } from 'utils'
import type { WeekSessionsQuery_sessions_assignments as Assignment } from 'api/__generated__/WeekSessionsQuery'

type Calc = (
  starts: DateTime,
  duration: number,
) => { span: number; row: number }
export const calc: Calc = (starts, duration) => {
  const span = Math.floor((duration / 60) * ROWS_PER_HOUR)
  const hourRows = (starts.hour - STARTING_HOUR) * ROWS_PER_HOUR
  const minuteRows = (starts.minute / 60) * ROWS_PER_HOUR
  const row = Math.floor(hourRows + minuteRows) + 1
  return { span, row }
}

export const renderAvatar = (
  assignment: Assignment,
  index: number,
): React.ReactElement => {
  const { person, role } = assignment
  const alt = `${person.firstName} ${person.lastName} / ${role}`
  const src = person.picture
  return index === 0 ? (
    <img key={src} className="avatar" src={src} alt={alt} />
  ) : (
    <img key={src} className="-ml-1 avatar" src={src} alt={alt} />
  )
}
