import { useRef, useState } from 'react'

import EditSessionPanel from 'components/panel/session-panel/edit-session-panel'
import NewSessionPanel from 'components/panel/session-panel/new-session-panel'

import type { WeekSessionsQuery_sessions as Session } from 'api/__generated__/WeekSessionsQuery'
import type { Placeholder } from 'api/sessions'

import Panel from 'components/panel'
import SessionPanelHeader from 'components/panel/session-panel-header'
import { NewSessionPanelHeader } from 'components/panel/session-panel/components'

interface Props {
  sessionId?: Session['id']
  placeholderSession?: Placeholder
  close: () => void
}
const SessionPanel = ({
  sessionId,
  placeholderSession,
  close,
}: Props): JSX.Element => {
  // Passing session, loading and refetch back out here to make the header work
  const [loading, setLoading] = useState<boolean>(false)
  const [session, setSession] = useState<
    Pick<Session, 'starts' | 'type' | 'ends'> | undefined
  >(undefined)
  const refetchRef = useRef<() => void>(() => null)
  const setRefetch = (refetch: () => Promise<void>): void => {
    refetchRef.current = refetch
  }
  const panelOpen = Boolean(sessionId || placeholderSession)

  let header = undefined
  if (placeholderSession || sessionId) {
    header = placeholderSession ? (
      <NewSessionPanelHeader starts={placeholderSession?.starts} />
    ) : (
      <SessionPanelHeader
        starts={session?.starts}
        ends={session?.ends}
        type={session?.type}
        loading={loading}
        reload={refetchRef.current}
      />
    )
  }

  return (
    <Panel isOpen={panelOpen} close={close} header={header}>
      {placeholderSession && (
        <NewSessionPanel placeholder={placeholderSession} close={close} />
      )}
      {sessionId && (
        <EditSessionPanel
          sessionId={sessionId}
          close={close}
          setLoading={setLoading}
          setRefetch={setRefetch}
          setSession={setSession}
        />
      )}
    </Panel>
  )
}

export default SessionPanel
