import { useParams, useNavigate } from 'react-router-dom'

import type { WeekSessionsQuery_sessions as Session } from 'api/__generated__/WeekSessionsQuery'

import useWeekStart from 'hooks/useWeekStart'
import useSessions from 'hooks/useSessions'

import Week from 'components/week'
import ReadonlySessionPanel from 'components/panel/readonly-session-panel'
import { WeekHeader } from 'components/layout'
import { ReadOnlySession } from 'components/week/week-session'
import PhoneNag from 'components/phone-nag'

const Apply = (): JSX.Element => {
  const { weekStart, thisRoute } = useWeekStart()
  const { sessionId } = useParams<{ sessionId?: Session['id'] }>()
  const { sessions, loading } = useSessions(weekStart)
  const navigate = useNavigate()

  const close = () => {
    navigate(thisRoute, { replace: true })
  }

  const openPanel = (sessionId: Session['id']): void => {
    navigate(`${thisRoute}/${sessionId}`)
  }

  return (
    <>
      <WeekHeader weekStart={weekStart} loading={loading} />
      <PhoneNag />
      <>
        <Week
          sessions={sessions}
          weekStart={weekStart}
          renderSession={(session) => (
            <ReadOnlySession
              key={session.starts.toISO()}
              session={session}
              openPanelSession={openPanel}
              selectedSessionId={sessionId}
            />
          )}
        />
        <ReadonlySessionPanel sessionId={sessionId} close={close} />
      </>
    </>
  )
}

export default Apply
