import React from 'react'

export enum PillVariant {
  green = 'bg-green-100 text-green-800 ',
  blue = 'bg-blue-100 text-blue-800 ',
  red = 'bg-red-100 text-red-800 ',
  yellow = 'bg-yellow-100 text-yellow-800 ',
  gray = 'bg-gray-200 text-gray-500 ',
}

const Pill: React.FC<{
  className?: string
  title?: string | null
  variant?: PillVariant
}> = ({ children, className = '', title, variant = PillVariant.green }) => (
  <span
    title={title ?? undefined}
    className={
      'px-2 mr-2 my-2 inline-flex text-xs leading-5 font-semibold rounded-full whitespace-nowrap ' +
      variant +
      className
    }
  >
    {children}
  </span>
)

export default Pill
