import { gql } from '@apollo/client'

import * as fragments from 'api/fragments'

export const ADD_ASSIGNMENT = gql`
  mutation AddAssignmentMutation(
    $personId: ID!
    $sessionId: ID!
    $role: Role!
  ) {
    addAssignment(personId: $personId, sessionId: $sessionId, role: $role) {
      ...AssignmentFragment
    }
  }
  ${fragments.Assignment.AssignmentFragment}
`

export const CONFIRM_ASSIGNMENT = gql`
  mutation ConfirmAssignmentMutation($assignmentId: ID!) {
    confirmAssignment(assignmentId: $assignmentId) {
      ...AssignmentFragment
    }
  }
  ${fragments.Assignment.AssignmentFragment}
`

export const SET_USER_ACTIVATION = gql`
  mutation SetActivationMutation($userId: ID!, $status: UserStatus!) {
    setActivation(userId: $userId, status: $status)
  }
`

export const DELETE_ASSIGNMENT = gql`
  mutation DeleteAssignmentMutation($assignmentId: ID!) {
    deleteAssignment(assignmentId: $assignmentId)
  }
`

export const COPY_ASSIGNMENT = gql`
  mutation CopyAssignmentMutation(
    $assignmentId: ID!
    $period: CopyAssignmentPeriod!
  ) {
    copyAssignment(assignmentId: $assignmentId, period: $period)
  }
`

export const DELETE_SESSION = gql`
  mutation DeleteSessionMutation($sessionId: ID!) {
    deleteSession(sessionId: $sessionId)
  }
`

export const UPDATE_SESSION = gql`
  mutation UpdateSessionMutation(
    $sessionId: ID!
    $starts: NaiveDateTime
    $duration: Int
    $type: SessionType
    $bookableCapacity: Int
    $notes: String
    $courseIds: [ID!]
    $flag: Flag
  ) {
    updateSession(
      sessionId: $sessionId
      starts: $starts
      duration: $duration
      type: $type
      bookableCapacity: $bookableCapacity
      notes: $notes
      courseIds: $courseIds
      flag: $flag
    ) {
      id
      duration
      starts
      ends
      type
      bookableCapacity
      notes
      flag
      courses {
        id
      }
    }
  }
`

export const CREATE_SESSION = gql`
  mutation CreateSessionMutation(
    $starts: NaiveDateTime!
    $duration: Int!
    $type: SessionType!
    $bookableCapacity: Int!
    $notes: String
    $courseIds: [ID!]
    $flag: Flag
  ) {
    createSession(
      starts: $starts
      duration: $duration
      type: $type
      bookableCapacity: $bookableCapacity
      notes: $notes
      courseIds: $courseIds
      flag: $flag
    ) {
      id
      duration
      starts
      ends
      type
      bookableCapacity
      notes
      flag
      courses {
        id
      }
    }
  }
`

export const SET_CAPABILITIES = gql`
  mutation SetCapabilitiesMutation($personId: ID!, $capabilityIds: [ID!]!) {
    setCapabilities(personId: $personId, capabilityIds: $capabilityIds)
  }
`

export const SET_CONTACT_PREFS = gql`
  mutation SetContactPreferenceMutation(
    $contactPreference: [ContactPreference!]!
    $reminderPreference: [ReminderPreference!]!
  ) {
    setContactPreference(
      contactPreference: $contactPreference
      reminderPreference: $reminderPreference
    )
  }
`

export const UPDATE_PERSON = gql`
  mutation UpdatePersonMutation(
    $phone: String
    $firstName: String
    $lastName: String
  ) {
    updatePerson(phone: $phone, firstName: $firstName, lastName: $lastName)
  }
`

export const UPDATE_ORDER = gql`
  mutation UpdateOrderMutation(
    $orderId: ID!
    $sessionId: ID
    $courseId: ID
    $firstName: String
    $lastName: String
    $email: String
    $postCode: String
    $orderSkus: [OrderSkuInput!]
  ) {
    updateOrder(
      orderId: $orderId
      sessionId: $sessionId
      courseId: $courseId
      firstName: $firstName
      lastName: $lastName
      email: $email
      postCode: $postCode
      orderSkus: $orderSkus
    ) {
      id
    }
  }
`

export const MARK_ORDER_PAID = gql`
  mutation MarkOrderPaidMutation($orderId: ID!) {
    markOrderPaid(orderId: $orderId) {
      id
    }
  }
`

export const RESEND_ORDER_CONFIRMATION = gql`
  mutation ResendOrderConfirmationMutation($orderId: ID!) {
    resendOrderConfirmation(orderId: $orderId)
  }
`

export const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrderMutation($orderId: ID!) {
    cancelOrder(orderId: $orderId) {
      __typename
      id
      orderStatus
    }
  }
`

export const SEND_ASSIGNMENT_NOTIFICATIONS_MUTATION = gql`
  mutation SendAssignmentNotifications($weekCommencing: Date!) {
    sendAssignmentNotifications(weekCommencing: $weekCommencing)
  }
`

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocumentMutation(
    $title: String!
    $description: String
    $category: String!
    $roles: [Role!]
  ) {
    createDocument(
      title: $title
      description: $description
      category: $category
      roles: $roles
    ) {
      __typename
      id
      title
      description
      category
      exists
      roles
    }
  }
`

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation UpdateDocumentMutation(
    $documentId: ID!
    $title: String!
    $description: String
    $category: String!
    $roles: [Role!]
  ) {
    updateDocument(
      documentId: $documentId
      title: $title
      description: $description
      category: $category
      roles: $roles
    ) {
      __typename
      id
      title
      description
      category
      exists
      roles
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocumentMutation($documentId: ID!) {
    deleteDocument(documentId: $documentId)
  }
`

export const UPDATE_COURSE = gql`
  mutation UpdateCourseMutation($courseId: ID!, $isAvailable: Boolean!) {
    updateCourse(courseId: $courseId, isAvailable: $isAvailable) {
      id
      isAvailable
    }
  }
`
