import { useState, useRef } from 'react'

import type {
  PeoplePanelQuery,
  PeoplePanelQuery_person as Person,
} from 'api/__generated__/PeoplePanelQuery'

import Panel from 'components/panel'
import { Reload } from 'components/buttons'
import EditPersonPanel from 'components/panel/person-panel/edit-person-panel'

const PanelHeader = ({
  person,
  loading,
  reload,
}: {
  person: Person
  loading: boolean
  reload?: () => void
}): JSX.Element => {
  const { picture, firstName, lastName } = person
  return (
    <header className="px-4 sm:px-6 bg-indigo-700 py-6 flex text-white">
      <h2 className="text-lg leading-7 font-medium text-white flex items-center flex-1">
        <div className="flex-shrink-0 h-10 w-10 mr-3">
          <img
            className="h-10 w-10 rounded-full"
            src={picture}
            alt={firstName ?? 'Profile'}
          />
        </div>
        {firstName} {lastName}
      </h2>
      {reload && (
        <Reload
          onClick={reload}
          className={`flex-end ${loading && 'animate-spin'}`}
        />
      )}
    </header>
  )
}

interface Props {
  personId?: Person['id']
  close: () => void
}

const PersonPanel = ({ personId, close }: Props): JSX.Element => {
  // Passing data, loading and refetch back out here to make the header work
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<PeoplePanelQuery | undefined>(undefined)
  const refetchRef = useRef<() => void>(() => null)
  const setRefetch = (refetch: () => Promise<void>): void => {
    refetchRef.current = refetch
  }

  const header = data?.person ? (
    <PanelHeader
      person={data.person}
      reload={refetchRef.current}
      loading={loading}
    />
  ) : (
    <></>
  )

  return (
    <Panel isOpen={!!personId} close={close} header={header}>
      {personId && (
        <EditPersonPanel
          personId={personId}
          close={close}
          setLoading={setLoading}
          setData={setData}
          setRefetch={setRefetch}
        />
      )}
    </Panel>
  )
}

export default PersonPanel
