import { Dispatch, SetStateAction } from 'react'
import { DateTime } from 'luxon'
import Select, { OnChangeValue } from 'react-select'

import { OrderStatus, OrderType } from 'globalTypes'

interface Option<T = string> {
  readonly value: T
  readonly label: string
}

const orderStatusOptions: Option<OrderStatus>[] = [
  { value: OrderStatus.SUCCEEDED, label: 'Succeeded' },
  { value: OrderStatus.CREATED, label: 'Created' },
  { value: OrderStatus.CANCELED, label: 'Canceled' },
  { value: OrderStatus.REFUNDED, label: 'Refunded' },
  { value: OrderStatus.NEEDS_REFUND, label: 'Needs refund' },
]

const orderTypeOptions: Option<OrderType>[] = [
  { value: OrderType.PUBLIC, label: 'Public' },
  { value: OrderType.PRIVATE, label: 'Private' },
  { value: OrderType.LESSON, label: 'Lesson' },
]

const now = DateTime.now()
const sinceOptions: Option<DateTime>[] = [0, 1, 2, 3, 4, 5, 6].map((month) => {
  const value = now.plus({ month: -1 * month }).startOf('month')
  return { value, label: value.toFormat('LLLL') }
})

interface Props {
  orderStatus?: OrderStatus
  orderType?: OrderType
  since?: DateTime
  setOrderStatus: Dispatch<SetStateAction<OrderStatus | undefined>>
  setOrderType: Dispatch<SetStateAction<OrderType | undefined>>
  setSince: Dispatch<SetStateAction<DateTime>>
}
const OrderFilter = ({
  orderStatus,
  orderType,
  since,
  setOrderStatus,
  setOrderType,
  setSince,
}: Props): JSX.Element => {
  const onChangeOrderStatus = (
    newValue: OnChangeValue<Option<OrderStatus>, false>,
  ) => setOrderStatus(newValue?.value ?? undefined)

  const onChangeOrderType = (
    newValue: OnChangeValue<Option<OrderType>, false>,
  ) => setOrderType(newValue?.value ?? undefined)

  const onChangeSince = (newValue: OnChangeValue<Option<DateTime>, false>) =>
    setSince(newValue?.value ?? sinceOptions[0].value)

  const orderTypeValue = orderTypeOptions.find(
    ({ value }) => value === orderType,
  )

  const orderStatusValue = orderStatusOptions.find(
    ({ value }) => value === orderStatus,
  )

  const sinceValue = sinceOptions.find(({ value }) => value === since)

  return (
    <div className="md:flex md:flex-row text-gray-600 text-sm ml-3 content-flex-end">
      <div className="flex mr-5 items-center pb-2 md:pb-0">
        <label className="inline-block mr-2">Order status:</label>
        <Select
          options={orderStatusOptions}
          onChange={onChangeOrderStatus}
          value={orderStatusValue}
          className="select inline-block flex-1 md:flex-0"
          isClearable
        />
      </div>
      <div className="flex mr-5 items-center pb-2 md:pb-0">
        <label className="inline-block mr-2">Order type:</label>
        <Select
          options={orderTypeOptions}
          onChange={onChangeOrderType}
          value={orderTypeValue}
          className="select inline-block flex-1 md:flex-0"
          isClearable
        />
      </div>
      <div className="flex mr-5 items-center pb-2 md:pb-0">
        <label className="inline-block mr-2">Created month:</label>
        <Select
          options={sinceOptions}
          onChange={onChangeSince}
          value={sinceValue}
          defaultValue={sinceOptions[0]}
          className="select inline-block flex-1 md:flex-0"
        />
      </div>
    </div>
  )
}

export default OrderFilter
