import { Dispatch, SetStateAction, FormEventHandler } from 'react'
import { useQuery } from '@apollo/client'
import Select, { OnChangeValue } from 'react-select'

import type { DocumentCategoriesQuery } from 'api/__generated__/DocumentCategoriesQuery'

import * as queries from 'api/queries'
import { Label, TextArea, Input } from 'components/form'
import { FieldErrors } from 'utils'
import { Role } from 'globalTypes'
import { roleOptions, Option } from 'utils'

export type FormState = {
  title: string
  description: string | null
  category: string
  roles?: Role[]
}
type Props = {
  formState: FormState
  setFormState: Dispatch<SetStateAction<FormState>>
  fieldErrors: FieldErrors<FormState>
  save: () => void
}
function DocumentForm({
  formState,
  setFormState,
  save,
  fieldErrors,
}: Props): JSX.Element {
  function getSetValueFor<T>(fieldName: keyof FormState) {
    return (value: Readonly<T>) =>
      setFormState(
        (state: FormState) =>
          state && {
            ...state,
            [fieldName]: value,
          },
      )
  }

  const onSubmit: FormEventHandler = (evt) => {
    evt.preventDefault()
    save()
  }

  // Save select option as a list of Role[] per formstate type
  const onChangeRoles = (option: OnChangeValue<Option<Role>, true>) => {
    const setValue = getSetValueFor<FormState['roles']>('roles')
    setValue(option.map((item) => item.value))
  }

  // Formstate is a list of Role[] so find the roleOptions that are present
  const roleValue = roleOptions.filter(({ value }) =>
    formState?.roles?.includes(value),
  )

  // Get this here, cos for new document form there wont already be a panel query
  const { data } = useQuery<DocumentCategoriesQuery>(
    queries.DOCUMENT_CATEGORIES,
    { fetchPolicy: 'cache-first' },
  )

  return (
    <form onSubmit={onSubmit} action="" method="get">
      <input type="submit" className="hidden" />
      <div className="mb-3">
        <Label>Title</Label>
        <Input
          onChange={getSetValueFor('title')}
          value={formState?.title ?? ''}
          type="text"
        />
        {fieldErrors.title && (
          <p className="text-xs mt-4 text-red-600 capitalize" role="alert">
            {fieldErrors.title}
          </p>
        )}
      </div>
      <div className="mb-3">
        <Label>Description</Label>
        <TextArea
          onChange={getSetValueFor('description')}
          value={formState?.description ?? ''}
        />
        {fieldErrors.description && (
          <p className="text-xs mt-4 text-red-600 capitalize" role="alert">
            {fieldErrors.description}
          </p>
        )}
      </div>
      <div className="mb-3">
        <Label>Category</Label>
        <Input
          onChange={getSetValueFor('category')}
          value={formState?.category ?? ''}
          type="text"
          list="categories"
        />
        <datalist id="categories">
          {data?.documentCategories.map(({ category }) => (
            <option key={category}>{category}</option>
          ))}
        </datalist>
        {fieldErrors.category && (
          <p className="text-xs mt-4 text-red-600 capitalize" role="alert">
            {fieldErrors.category}
          </p>
        )}
      </div>
      <div className="mb-3">
        <Label>Roles</Label>
        <Select
          options={roleOptions}
          onChange={onChangeRoles}
          value={roleValue}
          className="select"
          isMulti
        />
        <p className="text-xs text-gray-600 py-3">
          Set roles who can see the document. If you select none, the document
          is publicly viewable. Make sure to include admin or you'll loose it!
        </p>
        {fieldErrors.roles && (
          <p className="text-xs mt-4 text-red-600 capitalize" role="alert">
            {fieldErrors.roles}
          </p>
        )}
      </div>
    </form>
  )
}

export default DocumentForm
