import { useState } from 'react'
import type { Placeholder } from 'api/sessions'
import { useParams, useNavigate, useResolvedPath } from 'react-router-dom'

import type { WeekSessionsQuery_sessions as Session } from 'api/__generated__/WeekSessionsQuery'
import useWeekStart from 'hooks/useWeekStart'
import useSessions from 'hooks/useSessions'

import Week from 'components/week'
import SessionPanel from 'components/panel/session-panel'
import { WeekHeader } from 'components/layout'
import { EditableSession } from 'components/week/week-session'
import WeekPanel from 'components/panel/week-panel'
import PhoneNag from 'components/phone-nag'

const Main = (): JSX.Element => {
  const { weekStart, thisRoute } = useWeekStart()
  const { sessionId } = useParams<{ sessionId?: Session['id'] }>()
  const { sessions, loading } = useSessions(weekStart)

  const [placeholderSession, setPlaceholderSession] = useState<
    Placeholder | undefined
  >()

  const navigate = useNavigate()
  const weekPanelOpen = sessionId === 'messages'

  const close = () => {
    navigate(thisRoute, { replace: true })
    setPlaceholderSession(undefined)
  }

  const openPanelSession = (sessionId: Session['id']): void => {
    navigate(`${thisRoute}/${sessionId}`)
  }

  return (
    <>
      <WeekHeader weekStart={weekStart} loading={loading} />
      <PhoneNag />
      <>
        <Week
          sessions={sessions}
          weekStart={weekStart}
          renderSession={(session) => (
            <EditableSession
              key={session.starts.toISO()}
              session={session}
              selectedSessionId={sessionId}
              openPanelPlaceholder={setPlaceholderSession}
              openPanelSession={openPanelSession}
            />
          )}
        />
        <WeekPanel isOpen={!!weekPanelOpen} />
        <SessionPanel
          sessionId={weekPanelOpen ? undefined : sessionId}
          placeholderSession={placeholderSession}
          close={close}
        />
      </>
    </>
  )
}

export default Main
