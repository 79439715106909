import React from 'react'

import type { WeekSessionsQuery_sessions_assignments as Assignment } from 'api/__generated__/WeekSessionsQuery'
import { ucfirst } from 'utils'

import OrangeShirt from '../img/orange-tshirt.png'
import GreenShirt from '../img/green-tshirt.png'
import PurpleShirt from '../img/purple-tshirt.png'
import GreyShirt from '../img/grey-tshirt.png'
import { Role } from 'globalTypes'

type ShirtIcons = Record<Role | 'unconfirmed', string>
export const SHIRT_ICONS: ShirtIcons = {
  [Role.TEACHER]: PurpleShirt,
  [Role.GATEKEEPER]: GreenShirt,
  [Role.LIFEGUARD]: OrangeShirt,
  [Role.ADMIN]: GreyShirt, // Don't need
  [Role.MANAGER]: GreyShirt, // Don't need
  [Role.TECH_MANAGER]: GreyShirt,
  [Role.READONLY]: GreyShirt, // Don't need
  unconfirmed: GreyShirt,
}

type Props = {
  assignments: Assignment[]
}
const makeShirtComponent = (image: string): React.FC<Props> => {
  return ({ assignments }: Props): JSX.Element => (
    <>
      {assignments.map(
        ({ id, role, person: { firstName, lastName } }, index): JSX.Element => (
          <img
            src={image}
            key={id}
            className={`w-5 h-5 block ${index > 0 ? '-ml-3' : ''}`}
            title={`${firstName} ${lastName} – ${ucfirst(role)}`}
          />
        ),
      )}
    </>
  )
}

export const Gatekeepers = makeShirtComponent(GreenShirt)
export const Lifeguards = makeShirtComponent(OrangeShirt)
export const Teachers = makeShirtComponent(PurpleShirt)
export const Unconfirmed = makeShirtComponent(GreyShirt)

export const RoleIcon = ({
  className,
  role,
  isConfirmed = true,
  flag,
}: {
  className?: string
  role: Role
  isConfirmed?: boolean
  flag?: string
}): JSX.Element => (
  <div className={`w-5 h-5 inline-block relative ${className}`}>
    <img
      src={isConfirmed ? SHIRT_ICONS[role] : GreyShirt}
      title={
        isConfirmed
          ? `${ucfirst(role)} – ${flag}`
          : `Unconfirmed ${ucfirst(role)} – ${flag}`
      }
    />
    {flag && (
      <span
        className="bg-green-700 h-2 w-2 absolute top-2 rounded-full"
        style={{ transform: 'translateX(-10px)' }}
      />
    )}
  </div>
)
