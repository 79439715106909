import { Placeholder } from 'api/sessions'
import { NewSessionForm } from 'components/panel/session-panel/session-forms'

interface Props {
  placeholder: Placeholder
  close: () => void
}
const NewSessionPanel = ({ placeholder, close }: Props): JSX.Element => {
  return <NewSessionForm placeholder={placeholder} close={close} />
}

export default NewSessionPanel
