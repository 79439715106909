import { useState, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { Role, SessionType } from 'globalTypes'
import type {
  SessionPanelQuery,
  SessionPanelQueryVariables,
  SessionPanelQuery_session as Session,
} from 'api/__generated__/SessionPanelQuery'

import * as queries from 'api/queries'
import { parseDateTimes } from 'api/sessions'
import { Assignments } from './assignments'
import { NoteCard, OrderTable } from './components'
import { EditSessionForm } from './session-forms'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'
import SessionCourseTable from './sesion-course-table'
import useAssignmentFilters from 'hooks/useAssignmentFilter'
import { RequireRole } from 'components/shared/auth-components'

interface Props {
  sessionId: Session['id']
  close: () => void
  // This is a bodge to give these things to SessionPanel, so it can mount for animating and not have to flash
  setLoading: (loading: boolean) => void
  setRefetch: (refetch: (options?: any) => Promise<any>) => void
  setSession: (session?: Session) => void
}
const EditSessionPanel = ({
  sessionId,
  close,
  setLoading,
  setRefetch,
  setSession,
}: Props): JSX.Element | null => {
  const [roleToAdd, setRoleToAdd] = useState<Role | null>(null)
  const { data, loading, refetch, error } = useQuery<
    SessionPanelQuery,
    SessionPanelQueryVariables
  >(queries.SESSION_PANEL, {
    variables: {
      sessionId,
    },
    fetchPolicy: 'cache-and-network',
  })

  // Close addRole UI if changing between sessions
  useEffect(() => {
    setRoleToAdd(null)
  }, [sessionId])

  // Filter assignments by each role
  const {
    assignedLifeguards,
    assignedGatekeepers,
    assignedTeachers,
    assignedTechManagers,
  } = useAssignmentFilters(data?.session)

  // Parse Luxon objects for the session that's being loaded
  const session = useMemo(
    (): Session | undefined =>
      data?.session ? parseDateTimes<Session>(data?.session) : undefined,
    [data?.session],
  )

  // Pass these things up to SessionPanel
  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    setRefetch(refetch)
  }, [refetch])

  useEffect(() => {
    setSession(session)
  }, [session])

  // Filter lists if people who can gatekeep or lifeguard
  // remove people who are already assigned
  const assignedPeopleIds =
    data?.session.assignments.map(({ person: { id } }) => id) ?? []
  const lifeguards = (data?.lifeguards ?? []).filter(
    ({ id }) => !assignedPeopleIds.includes(id),
  )
  const gatekeepers = (data?.gatekeepers ?? []).filter(
    ({ id }) => !assignedPeopleIds.includes(id),
  )
  const teachers = (data?.teachers ?? []).filter(
    ({ id }) => !assignedPeopleIds.includes(id),
  )
  const techManagers = (data?.tech_managers ?? []).filter(
    ({ id }) => !assignedPeopleIds.includes(id),
  )

  if (loading) {
    return <BigLoading loading />
  }

  if (error) {
    return <Error className="mt-6">{error.message}</Error>
  }

  if (!data || !session) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  return (
    <>
      <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
        <EditSessionForm
          session={session}
          allCourses={data.courses}
          close={close}
        />
      </RequireRole>
      {session.notes && <NoteCard notes={session.notes} />}
      <Assignments
        lifeguards={lifeguards}
        gatekeepers={gatekeepers}
        teachers={teachers}
        techManagers={techManagers}
        assignedLifeguards={assignedLifeguards}
        assignedGatekeepers={assignedGatekeepers}
        assignedTeachers={assignedTeachers}
        assignedTechManagers={assignedTechManagers}
        roleToAdd={roleToAdd}
        setRoleToAdd={setRoleToAdd}
        sessionId={session.id}
        sessionType={session.type}
      />
      {data.session.type !== SessionType.LESSON && (
        <OrderTable orders={data?.orders ?? []} />
      )}
      <SessionCourseTable session={data.session} />
    </>
  )
}

export default EditSessionPanel
