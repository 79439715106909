import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { Role } from 'globalTypes'
import AuthContainer from 'components/auth-container'
import GQLProvider from 'api/GQLProvider'
import { RequireRole, RoleSwitch } from 'components/shared/auth-components'
import { Nav } from 'components/layout/nav'
import { NotFound } from 'components/shared/error'
import Main from 'components/main'
import Apply from 'components/apply'
import People from 'components/people'
import Orders from 'components/orders'
import Courses from 'components/courses'
import PrintCourse from 'components/courses/print'
import Settings from 'components/settings'
import Documents from 'components/documents'

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || ''
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
const GQL_ENDPOINT = process.env.REACT_APP_GQL_ENDPOINT

function WeekView(): JSX.Element {
  return (
    <RoleSwitch
      components={[
        {
          roles: [Role.ADMIN, Role.MANAGER],
          component: <Main />,
        },
        {
          roles: [Role.READONLY],
          component: <Main />,
        },
      ]}
      fallback={<Apply />}
    />
  )
}

function App(): JSX.Element {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={(appState) => {
        window.history.replaceState(
          {},
          document.title,
          appState?.targetRoute || window.location.pathname,
        )
      }}
      audience={GQL_ENDPOINT}
      useRefreshTokens={true}
    >
      <GQLProvider>
        <AuthContainer>
          <BrowserRouter>
            <Nav />
            <Routes>
              <Route
                path="/people"
                element={
                  <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
                    <People />
                  </RequireRole>
                }
              >
                <Route path=":personId" element={<People />} />
              </Route>
              <Route
                path="/orders"
                element={
                  <RequireRole
                    roles={[Role.ADMIN, Role.MANAGER, Role.READONLY]}
                  >
                    <Orders />
                  </RequireRole>
                }
              >
                <Route path=":orderId" element={<Orders />} />
              </Route>
              <Route
                path="/courses"
                element={
                  <RequireRole
                    roles={[Role.ADMIN, Role.MANAGER, Role.READONLY]}
                  >
                    <Courses />
                  </RequireRole>
                }
              >
                <Route path=":courseId" element={<Courses />} />
              </Route>
              <Route path="course/print/:courseId" element={<PrintCourse />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/documents" element={<Documents />}>
                <Route path=":category" element={<Documents />} />
                <Route path=":category/:documentId" element={<Documents />} />
              </Route>
              <Route path="/document/:documentId" element={<Documents />} />
              <Route path="/" element={<Navigate to="/wc/redirect" />} />
              <Route path="/wc/:weekStart" element={<WeekView />}>
                <Route path=":sessionId" element={<WeekView />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </AuthContainer>
      </GQLProvider>
    </Auth0Provider>
  )
}

export default App
