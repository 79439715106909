import React from 'react'

interface WithClassName {
  className?: string
}

export const ErrorIcon: React.FC<WithClassName> = ({ className }) => (
  <svg
    className={'h-6 w-6 text-red-600 ' + className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    />
  </svg>
)

const Error: React.FC<WithClassName> = ({ children, className }) => (
  <div
    className={'bg-red-600 px-3 py-3 flex items-center rounded ' + className}
  >
    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
      <ErrorIcon />
    </div>
    <div className="font-medium text-white flex-1 ml-5">{children}</div>
  </div>
)

export const NotPermitted = () => (
  <div className="page-width">
    <Error>
      Insufficient permissions
      <p className="text-xs">
        Your account might need to be updated, please contact a manager if you
        expect to see something different
      </p>
    </Error>
  </div>
)

export const NotFound = () => (
  <div className="page-width">
    <Error>Not Found</Error>
  </div>
)

export default Error
