import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate, Link, NavLink } from 'react-router-dom'

import type {
  DocumentsQuery,
  DocumentsQuery_documents as Document,
  DocumentsQuery_documentCategories as Category,
} from 'api/__generated__/DocumentsQuery'

import * as queries from 'api/queries'
import { Role } from 'globalTypes'
import { Header } from 'components/layout'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'
import { RequireRole } from 'components/shared/auth-components'
import { ucfirst } from 'utils'

import DocumentPanel from 'components/documents/document-panel'
import DocumentsList from 'components/documents/documents-table'

const CategoryFilters = ({
  categories,
}: {
  categories?: Category[]
}): JSX.Element => {
  return (
    <div className="mr-2 flex-1 flex justify-end mt-3 sm:mt-0 flex-wrap w-full">
      {categories?.map(({ count, category }) => (
        <NavLink
          className={({ isActive }) => `
                    text-xs bg-blue-50
                    mr-2 px-2 py-1 rounded-md
                    whitespace-nowrap
                    mb-2 sm:mb-0
                    ${
                      isActive
                        ? 'bg-blue-600 text-white'
                        : 'text-blue-700 hover:bg-blue-100'
                    }
                  `}
          key={category}
          to={`/documents/${category.replace(' ', '-')}`}
        >
          {ucfirst(category)} | <span className="italic">{count}</span>
        </NavLink>
      ))}
      <Link
        className="text-xs text-blue-600 self-center mr-2 hover:underline"
        to={'/documents/'}
      >
        Clear filter
      </Link>
    </div>
  )
}

const DocumentsComponent = (): JSX.Element => {
  const { documentId, category: urlFilterCategory } = useParams<{
    documentId?: Document['id']
    category?: Document['category']
  }>()
  const navigate = useNavigate()

  const filterCategory = urlFilterCategory?.replace('-', ' ')

  const { data, loading } = useQuery<DocumentsQuery>(queries.DOCUMENTS)

  const filteredDocuments = useMemo(() => {
    if (!data?.documents) {
      return []
    }
    if (!filterCategory) {
      return data.documents
    }
    return data.documents.filter(({ category }) => filterCategory === category)
  }, [data?.documents, filterCategory])

  if (loading) {
    return <BigLoading loading />
  }

  if (!data) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  const close = () => {
    if (filterCategory) {
      navigate(`/documents/${filterCategory}`, { replace: true })
    } else {
      navigate('/documents', { replace: true })
    }
  }

  const openPanel = (documentId: Document['id']): void => {
    if (filterCategory) {
      navigate(`/documents/${filterCategory}/${documentId}`)
    } else {
      navigate(`/document/${documentId}`)
    }
  }

  return (
    <>
      <Header
        before={
          <div className="flex flex-col sm:flex-row-reverse items-end sm:items-center">
            <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
              <Link
                className="md:mr-6 btn rounded-md px-3 border border-gray-300 bg-white text-gray-500 hover:text-gray-600 hover:border-gray-400 "
                to={`/document/new`}
              >
                New document
              </Link>
            </RequireRole>
            <CategoryFilters categories={data?.documentCategories} />
          </div>
        }
      >
        Documents
      </Header>
      <DocumentsList
        documents={filteredDocuments}
        openPanel={openPanel}
        selectedDocumentId={documentId ?? null}
      />

      <DocumentPanel documentId={documentId} close={close} />
    </>
  )
}

export default DocumentsComponent
