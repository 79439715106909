import React from 'react'
import { useQuery } from '@apollo/client'

import type { UserCheckQuery } from 'api/__generated__/UserCheckQuery'
import { UserStatus } from 'globalTypes'

import * as queries from 'api/queries'

import Error from 'components/shared/error'
import Pending from 'components/pending'
import { BigLoading } from 'components/shared/loading'
import AuthContext from 'components/auth-context'
import { LogOutButton } from 'components/shared/auth-components'

const AuthContainer = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { data, loading, error } = useQuery<UserCheckQuery>(queries.USER_CHECK)

  if (loading) {
    return <BigLoading loading />
  }

  if (data?.user.status === UserStatus.PENDING) {
    return <Pending />
  }

  if (data?.user.status === UserStatus.OK) {
    return (
      <AuthContext.Provider value={data.user}>{children}</AuthContext.Provider>
    )
  }

  if (error) {
    return (
      <div className="page-width mt-6">
        <Error>{error}</Error>
        <LogOutButton className="text-red-600 mt-6" />
      </div>
    )
  }

  return (
    <div className="page-width mt-6">
      <Error>This account is not active</Error>
      <LogOutButton className="text-red-600 mt-6" />
    </div>
  )
}

export default AuthContainer
