import { useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import type {
  PeopleQuery,
  PeopleQueryVariables,
  PeopleQuery_people as Person,
} from 'api/__generated__/PeopleQuery'

import * as queries from 'api/queries'
import { Header } from 'components/layout'
import PersonPanel from 'components/panel/person-panel'
import PeopleList from 'components/people/people-table'
import Error from 'components/shared/error'
import { BigLoading } from 'components/shared/loading'
import PeopleFilter from './people-filter'
import { Role, UserStatus } from 'globalTypes'

const PeopleComponent = (): JSX.Element => {
  const { personId } = useParams<{ personId?: Person['id'] }>()
  const navigate = useNavigate()

  // Filter states
  const [userStatus, setUserStatus] = useState<UserStatus | undefined>(
    undefined,
  )
  const [role, setRole] = useState<Role | undefined>(undefined)
  const [filterText, setFilterText] = useState<string>('')

  const { data, loading } = useQuery<PeopleQuery, PeopleQueryVariables>(
    queries.PEOPLE,
    { variables: { role, userStatus } },
  )

  const filteredPeople = useMemo(
    () =>
      data?.people.filter(
        ({ firstName, lastName, phone }) =>
          `${firstName} ${lastName} ${phone}`
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1,
      ),
    [filterText, data?.people],
  )

  if (loading) {
    return <BigLoading loading />
  }

  if (!data) {
    return <Error className="page-width mt-6">Oh dear</Error>
  }

  const close = () => {
    navigate('/people', { replace: true })
  }

  const openPanel = (personId: Person['id']): void => {
    navigate(`/people/${personId}`)
  }

  return (
    <>
      <Header
        before={
          <PeopleFilter
            userStatus={userStatus}
            setUserStatus={setUserStatus}
            role={role}
            setRole={setRole}
            filterText={filterText}
            setFilterText={setFilterText}
          />
        }
      >
        People
      </Header>
      <PeopleList
        people={filteredPeople}
        openPanel={openPanel}
        selectedPersonId={personId ?? null}
      />

      <PersonPanel personId={personId} close={close} />
    </>
  )
}

export default PeopleComponent
