import { useEffect, useState } from 'react'
import { setContext } from '@apollo/client/link/context'
import { OAuthError, useAuth0 } from '@auth0/auth0-react'
import apolloLogger from 'apollo-link-logger'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
} from '@apollo/client'

import fragmentTypes from 'api/fragmentTypes.json'
import { BigLoading } from 'components/shared/loading'
import SplashComponent from 'components/splash'

const GQL_ENDPOINT = process.env.REACT_APP_GQL_ENDPOINT
const audience = GQL_ENDPOINT

const { possibleTypes } = fragmentTypes
const cache = new InMemoryCache({ possibleTypes })

const httpLink = new HttpLink({
  uri: GQL_ENDPOINT,
})

type PrevContext = {
  headers: Record<string, unknown>
}

export default function GQLProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
  } = useAuth0()
  const [token, setToken] = useState<string>()

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently({ audience })
        setToken(token)
      } catch (err) {
        if (err instanceof OAuthError) {
          if (err.error === 'consent_required') {
            const token = await getAccessTokenWithPopup({ audience })
            setToken(token)
          }
        }
      }
    }
    getToken()
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated || isLoading || window.location.pathname === '/') {
      return
    }
    void loginWithRedirect({
      appState: {
        targetRoute: window.location.pathname,
      },
    })
  }, [isAuthenticated, isLoading])

  if (isLoading) {
    return <BigLoading loading />
  }

  if (!isAuthenticated) {
    return <SplashComponent />
  }

  const authLink = setContext(
    async (_, prevContext: PrevContext): Promise<PrevContext> => {
      return {
        ...prevContext,
        headers: {
          ...prevContext.headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    },
  )

  const client = new ApolloClient({
    name: 'Dashboard',
    version: process.env.REACT_APP_RELEASE || 'local',
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    link: from([authLink, apolloLogger, httpLink]),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
