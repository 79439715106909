import { createContext, useContext } from 'react'

import type { UserCheckQuery_user as User } from 'api/__generated__/UserCheckQuery'

const AuthContext = createContext<User | null>(null)

export const useUser = () => {
  const user = useContext(AuthContext)
  return { user }
}
export default AuthContext
