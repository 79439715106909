import { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'

import type {
  WeekSessionsQuery,
  WeekSessionsQueryVariables,
  WeekSessionsQuery_sessions as Session,
} from 'api/__generated__/WeekSessionsQuery'
import * as queries from 'api/queries'
import { sortSessions, SessionOrPlaceholder } from 'api/sessions'

interface IUseSessions {
  loading: boolean
  sessions: SessionOrPlaceholder[][]
  refetch: () => void
  selectedSession: SessionOrPlaceholder | null
  pickSelectedSession: (selectedSession: SessionOrPlaceholder | null) => void
}

export const parseDateTimes = (session: Session): Session => {
  const starts = DateTime.fromISO(session.starts)
  const ends = DateTime.fromISO(session.ends)
  return { ...session, starts, ends }
}

const useSessions = (weekStart: DateTime): IUseSessions => {
  const { data, loading, refetch } = useQuery<
    WeekSessionsQuery,
    WeekSessionsQueryVariables
  >(queries.WEEK_SESSIONS, {
    variables: {
      since: weekStart.toISODate(),
      to: weekStart.plus({ days: 6 }).toISODate(),
    },
  })

  const [selectedSession, pickSelectedSession] =
    useState<SessionOrPlaceholder | null>(null)

  // Sessions from API with DateTimes
  const unSortedSessions = useMemo(
    (): Session[] => data?.sessions.map(parseDateTimes) ?? [],
    [data?.sessions],
  )

  // Group into DOW by start datetime
  // Intersperse blank intervals with Placeholder
  const sessions = useMemo(
    (): SessionOrPlaceholder[][] => sortSessions(unSortedSessions, weekStart),
    [unSortedSessions, weekStart],
  )

  return {
    sessions,
    loading,
    refetch,
    selectedSession,
    pickSelectedSession,
  }
}

export default useSessions
