import { DateTime } from 'luxon'

import type { OrderQuery_orders as Order } from 'api/__generated__/OrderQuery'
import { OrderStatus, OrderType } from 'globalTypes'

import { InlineButtonPrimary } from 'components/buttons'
import Pill, { PillVariant } from 'components/shared/pill'

const orderTypePillVariants: Record<OrderType, PillVariant> = {
  [OrderType.LESSON]: PillVariant.yellow,
  [OrderType.PRIVATE]: PillVariant.red,
  [OrderType.PUBLIC]: PillVariant.green,
}

export const orderStatusPillVariants: Record<OrderStatus, PillVariant> = {
  [OrderStatus.SUCCEEDED]: PillVariant.green,
  [OrderStatus.CANCELED]: PillVariant.blue,
  [OrderStatus.REFUNDED]: PillVariant.blue,
  [OrderStatus.CREATED]: PillVariant.yellow,
  [OrderStatus.NEEDS_REFUND]: PillVariant.red,
}

interface ItemProps {
  order: Order
  edit: () => void
  selected: boolean
}
const Item = ({ order, edit }: ItemProps): JSX.Element => {
  const {
    firstName,
    lastName,
    orderStatus,
    orderType,
    session,
    course,
    insertedAt,
  } = order

  let startsString
  if (session) {
    const starts = DateTime.fromISO(session.starts)
    startsString = starts.toLocaleString(DateTime.DATETIME_MED)
  }

  const created = DateTime.fromISO(insertedAt).toLocaleString(
    DateTime.DATETIME_MED,
  )

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="text-sm font-medium text-gray-900 capitalize">
            {firstName} {lastName}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <Pill
            variant={orderTypePillVariants[orderType]}
            title={orderType.toLowerCase()}
          >
            {orderType.toLowerCase()}
          </Pill>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {session && (
          <div className="text-sm font-medium text-gray-900">
            Session: {startsString}
          </div>
        )}
        {course && (
          <div className="text-sm font-medium text-gray-900">
            Lesson: {course.name}{' '}
            <span className="capitalize">({course.level.toLowerCase()})</span>
          </div>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900 capitalize">
          <Pill
            variant={orderStatusPillVariants[orderStatus]}
            title={orderStatus.toLowerCase()}
          >
            {orderStatus.toLowerCase()}
          </Pill>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm">{created}</td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <InlineButtonPrimary onClick={edit} className="">
          View
        </InlineButtonPrimary>
      </td>
    </tr>
  )
}

interface ListProps {
  orders: Order[]
  selectedOrderId: Order['id'] | null
  openPanel: (orderId: Order['id']) => void
}

const OrderList = ({
  orders,
  openPanel,
  selectedOrderId,
}: ListProps): JSX.Element => (
  <div className="max-w-full xl:max-w-6xl mx-auto py-6 sm:px-6 flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Description
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orders.map(
                (order: Order): JSX.Element => (
                  <Item
                    order={order}
                    key={order.id}
                    edit={() => openPanel(order.id)}
                    selected={order.id === selectedOrderId}
                  />
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)

export default OrderList
