import { Role } from 'globalTypes'

import type { PeoplePanelQuery_allCapabilities as Capability } from 'api/__generated__/PeoplePanelQuery'

import Pill from 'components/shared/pill'

type GroupedCaps = Record<Role, Capability[]>
const groupByRole = (acc: GroupedCaps, cap: Capability): GroupedCaps => {
  return cap.roles.reduce((acc: GroupedCaps, role: Role) => {
    if (acc[role]) {
      acc[role].push(cap)
    } else {
      acc[role] = [cap]
    }
    return acc
  }, acc)
}

interface Props {
  allCapabilities: Capability[]
}
const CapabilityHelp = ({ allCapabilities }: Props): JSX.Element => {
  const groupedCaps = allCapabilities.reduce<GroupedCaps>(
    groupByRole,
    {} as GroupedCaps,
  )
  const capList = Object.entries(groupedCaps)

  return (
    <table className="min-w-full divide-y divide-gray-200 mt-4">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Role
          </th>
          <th
            scope="col"
            className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Capabilities required
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {capList.map(([role, caps]) => (
          <tr key={role}>
            <td className="px-2 py-2 whitespace-nowrap text-sm align-top">
              <Pill className="bg-blue-100 text-blue-800 capitalize">
                {role.toLowerCase()}
              </Pill>
            </td>
            <td className="px-2 py-2 text-sm">
              {caps.map(({ name, description, id }) => (
                <Pill
                  key={id}
                  className="bg-orange-100 text-orange-800"
                  title={description}
                >
                  {name}
                </Pill>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CapabilityHelp
