import React, { useMemo } from 'react'
import { DateTime } from 'luxon'

import type { WeekSessionsQuery_sessions as Session } from 'api/__generated__/WeekSessionsQuery'
import { Flag, Role, SessionType } from 'globalTypes'

import { getOccupancy, SESSION_TYPE_EMOJIS } from 'utils'
import { calc } from './week-utils'
import { Placeholder } from 'api/sessions'
import useAssignmentFilter from 'hooks/useAssignmentFilter'
import {
  Gatekeepers,
  Lifeguards,
  Teachers,
  Unconfirmed,
} from 'components/Shirt'
import { ucfirst } from 'utils'
import { RequireRole } from 'components/shared/auth-components'

interface BlankSlotProps {
  starts: DateTime
  duration: number
  onClick: () => void
}
export const BlankSlot = ({
  starts,
  duration,
  onClick,
}: BlankSlotProps): JSX.Element => {
  const { span, row } = calc(starts, duration)

  return (
    <div
      className="group week-session justify-center items-center"
      style={{ gridRow: `${row} / span ${span}` }}
    >
      <button
        onClick={onClick}
        title="Add a session"
        className="transform border-2 border-white scale-50 bg-indigo-200 group-hover:scale-100 hover:bg-indigo-500 text-white transition-default duration-200 flex flex-0 rounded-full px-2 py-1 pb-2 leading-5 text-2xl font-bold cursor-pointer"
      >
        +
      </button>
    </div>
  )
}

interface SessionWrapperProps {
  session: Session
  onClick: () => void
  selected?: boolean
  extraHeading?: React.ReactElement[]
  children: React.ReactNode
  interactable?: boolean
  highlighted?: boolean
}

const SessionWrapper = ({
  session,
  onClick,
  selected,
  children,
  extraHeading,
  highlighted,
  interactable = true,
}: SessionWrapperProps): JSX.Element => {
  const { starts, ends, duration, type, flag, bookableCapacity, spaces } =
    session
  const { span, row } = calc(starts, duration)

  const occupancy = useMemo(() => getOccupancy(session), [session])

  const isUnbookedBooking =
    type === SessionType.BOOKING &&
    spaces == bookableCapacity &&
    bookableCapacity > 0
  const isUnbookableBooking =
    type === SessionType.BOOKING && bookableCapacity < 1 && spaces === 0
  const times = `${starts.toLocaleString(
    DateTime.TIME_24_SIMPLE,
  )} - ${ends.toLocaleString(DateTime.TIME_24_SIMPLE)}`

  let bookingClass = ''
  let typeText = ucfirst(type)
  let emoji = SESSION_TYPE_EMOJIS[type]

  if (isUnbookableBooking) {
    typeText = 'Unavailable'
    bookingClass = 'opacity-50 bg-gray-100'
    emoji = '🙅‍♀️'
  }
  if (isUnbookedBooking) {
    typeText = 'Unbooked'
    bookingClass = 'opacity-50 bg-gray-100'
  }

  const normalBorderClass = isUnbookedBooking
    ? 'border-gray-400'
    : 'border-blue-800'
  const selectedClass = selected ? 'border-yellow-600' : normalBorderClass
  const highlightedClass = highlighted
    ? 'bg-blue-900 text-white'
    : 'bg-white text-gray-600'

  const flagClass =
    flag &&
    {
      [Flag.OK]: 'bg-green-50',
      [Flag.WARNING]: 'bg-red-100',
    }[flag]

  const interactableClass = interactable
    ? 'session-grow cursor-pointer'
    : 'cursor-not-allowed '

  const blueText = highlighted ? 'text-white' : 'text-blue-800'

  return (
    <button
      className={`group week-session shadow-sm border-l-4 border-solid ${selectedClass} ${flagClass} ${highlightedClass} ${bookingClass} ${interactableClass}`}
      style={{ gridRow: `${row} / span ${span}`, borderBottomColor: '#f5f5f5' }}
      onClick={interactable ? onClick : undefined}
      title={interactable ? undefined : 'Session is fully resourced'}
    >
      <div
        className="flex flex-row items-top w-full h-5 z-10 relative"
        style={{ backgroundColor: 'inherit' }}
      >
        <span className={'text-xs font-bold ' + blueText} title={times}>
          {starts.toLocaleString(DateTime.TIME_24_SIMPLE)}
          <span className="invisible group-hover:visible">
            - {ends.toLocaleString(DateTime.TIME_24_SIMPLE)}
          </span>
        </span>
        <span className="flex-1 flex justify-end">{extraHeading}</span>
      </div>
      <div className="flex-1">{span > 3 && children}</div>
      <p className="text-xs w-full flex z-10">
        <span className="text-md mr-1 rounded-full bg-white px-1 -mx-1">
          {emoji}
        </span>
        <span className="truncate flex-grow text-left">{typeText}</span>
        {[SessionType.BOOKING, SessionType.LESSON].includes(type) && (
          <span className={spaces < 0 ? 'text-red-500' : ''}>{occupancy}</span>
        )}
      </p>
      {span <= 3 && (
        <div
          className={`text-left pl-2 transform transition-transform z-0 group-hover:visible group-hover:-translate-y-full group-hover:opacity-100 bg-white border-solid absolute top-0 border-l-4 -left-1 right-0 ${normalBorderClass}`}
          style={{ backgroundColor: 'inherit' }}
        >
          <div className="inline-block self-start">{children}</div>
        </div>
      )}
    </button>
  )
}

interface WeekSessionProps {
  session: Session
  onClick: () => void
  selected?: boolean
  interactable?: boolean
  highlighted?: boolean
  showCounts?: boolean
}

export const WeekSession = ({
  session,
  onClick,
  selected,
  interactable = true,
  highlighted,
  showCounts,
}: WeekSessionProps): JSX.Element => {
  const { assignments, notes, spaces, bookableCapacity, type, myAssignment } =
    session

  // Number of unconfimed assignments from people offering
  const numOffers = assignments?.filter(
    ({ confirmedAt }) => !confirmedAt,
  ).length

  const {
    confirmedGatekeepers,
    confirmedLifeguards,
    confirmedTeachers,
    unConfirmed,
  } = useAssignmentFilter(session)

  const isUnbookedBooking =
    type === SessionType.BOOKING && spaces === bookableCapacity

  const isUnbookableBooking =
    type === SessionType.BOOKING && bookableCapacity < 1

  // This is to show the count of people confirmed at a session at a glance
  // We should exclude the tech manager role from this count
  const confirmed = assignments?.filter(
    ({ confirmedAt, role }) => !!confirmedAt && role !== Role.TECH_MANAGER,
  )

  const flags = []
  if (notes) {
    flags.push(
      <span key="notes" className="text-sm mr-1" role="img" aria-label="Notes">
        📝
      </span>,
    )
  }

  const showFlags = showCounts && !isUnbookableBooking

  // There are unconfirmed offers
  if (numOffers > 0 && confirmed.length === 0 && !isUnbookedBooking)
    flags.push(
      <span
        className="text-xs font-bold text-white bg-red-600 rounded-full self-center w-4 text-center leading-2"
        title={`${numOffers} offers please confirm or remove`}
        key="offers"
      >
        {numOffers}
      </span>,
    )

  // No outstanding offers, some are confirmed
  if (confirmed.length > 0)
    flags.push(
      <span
        className="text-xs font-bold text-white bg-green-600 rounded-full self-center w-4 text-center leading-2"
        title={`${confirmed.length} confirmed attendance`}
        key="confirmed"
      >
        {confirmed.length}
      </span>,
    )

  return (
    <SessionWrapper
      session={session}
      onClick={onClick}
      selected={selected}
      extraHeading={showFlags ? flags : []}
      interactable={interactable}
      highlighted={highlighted}
    >
      <div className="flex overflow-hidden flex-1 py-1 justify-start text-xl">
        <Unconfirmed assignments={unConfirmed} />
        <Lifeguards assignments={confirmedLifeguards} />
        <Gatekeepers assignments={confirmedGatekeepers} />
        <Teachers assignments={confirmedTeachers} />
        {myAssignment && (
          <span
            className="-mt-1"
            title={
              myAssignment.isConfirmed
                ? 'You are attending'
                : 'Your offer is pending'
            }
          >
            {myAssignment.isConfirmed ? '🙋' : '⏳'}
          </span>
        )}
      </div>
    </SessionWrapper>
  )
}

interface SessionProps {
  session: Session | Placeholder
  selectedSessionId?: Session['id']
  openPanelSession: (sessionId: Session['id']) => void
}

interface EditableSessionProps extends SessionProps {
  openPanelPlaceholder: (placeholder: Placeholder) => void
}
export const EditableSession = ({
  session,
  selectedSessionId,
  openPanelPlaceholder,
  openPanelSession,
}: EditableSessionProps): JSX.Element => {
  const { starts, duration } = session

  if ((session as Placeholder).blank) {
    return (
      <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
        <BlankSlot
          key={starts.toISO() + 'blank'}
          starts={starts}
          duration={duration}
          onClick={() => openPanelPlaceholder(session as Placeholder)}
        />
      </RequireRole>
    )
  } else {
    const selected = selectedSessionId === (session as Session).id
    return (
      <WeekSession
        key={starts.toISO()}
        selected={selected}
        session={session as Session}
        onClick={() => openPanelSession((session as Session).id)}
        showCounts
      />
    )
  }
}

export const ReadOnlySession = ({
  session,
  openPanelSession,
  selectedSessionId,
}: SessionProps): JSX.Element | null => {
  if ((session as Placeholder).blank) return null
  const { myAssignment, starts } = session as Session
  const selected = selectedSessionId === (session as Session).id
  const highlighted = myAssignment?.isConfirmed

  return (
    <WeekSession
      key={starts.toISO()}
      selected={selected}
      session={session as Session}
      onClick={() => openPanelSession((session as Session).id)}
      highlighted={highlighted}
    />
  )
}
