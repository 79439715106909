import { useContext } from 'react'

import type { UpdatePersonMutationVariables } from 'api/__generated__/UpdatePersonMutation'

import { FormStateContext } from '.'
import { Input } from 'components/form'
import { ucfirst } from 'utils'

type Props = {
  fieldName: keyof UpdatePersonMutationVariables
}
export default function Field({ fieldName }: Props): JSX.Element {
  const { person, editing, formState, setFormState, fieldErrors } =
    useContext(FormStateContext)!
  if (!editing) {
    return <div className="h-5 ml-3">{person[fieldName]}</div>
  }
  const value = formState[fieldName]
  const setValue = (value: string) =>
    setFormState((state: UpdatePersonMutationVariables) => ({
      ...state,
      [fieldName]: value,
    }))

  const error = fieldErrors[fieldName]?.[0]

  return (
    <>
      <div className="h-5">
        <Input
          value={value ?? ''}
          onChange={setValue}
          type="text"
          className="mr-4 -mt-2"
        />
      </div>
      {error && (
        <p className="text-xs mt-4 text-red-600" role="alert">
          {ucfirst(error)}
        </p>
      )}
    </>
  )
}
