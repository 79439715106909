import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import type {
  SendAssignmentNotifications,
  SendAssignmentNotificationsVariables,
} from 'api/__generated__/SendAssignmentNotifications'
import type {
  MessageQuery,
  MessageQueryVariables,
} from 'api/__generated__/MessageQuery'

import * as mutations from 'api/mutations'
import * as queries from 'api/queries'
import Panel from 'components/panel'
import GenericPanelHeader from '../generic-panel-header'
import useWeekStart from 'hooks/useWeekStart'
import { ButtonConfirm, Next, Previous } from 'components/buttons'
import { DateTime } from 'luxon'

const WeekPanel = ({ isOpen }: { isOpen: boolean }): JSX.Element => {
  const { weekStart, weekEnd, thisRoute } = useWeekStart()
  const navigate = useNavigate()

  const [send, { loading: sending }] = useMutation<
    SendAssignmentNotifications,
    SendAssignmentNotificationsVariables
  >(mutations.SEND_ASSIGNMENT_NOTIFICATIONS_MUTATION, {
    variables: { weekCommencing: weekStart.toISODate() },
    refetchQueries: [queries.MESSAGES],
  })

  const { data, loading, refetch } = useQuery<
    MessageQuery,
    MessageQueryVariables
  >(queries.MESSAGES, {
    variables: { since: weekStart.toISODate(), to: weekEnd.toISODate() },
  })

  const close = () => {
    navigate(thisRoute, { replace: true })
  }

  const nextWeek = () => {
    navigate(`/wc/${weekStart.plus({ week: 1 }).toISODate()}/messages`)
  }
  const prevWeek = () => {
    navigate(`/wc/${weekStart.plus({ week: -1 }).toISODate()}/messages`)
  }
  const alreadySent = !loading && data && data.messages.length > 0

  const header = (
    <GenericPanelHeader
      title={`WC: ${weekStart.toLocaleString(DateTime.DATE_MED)}`}
      loading={loading}
      reload={refetch}
      icon="📆"
    />
  )

  return (
    <Panel isOpen={isOpen} close={close} header={header}>
      <p className="mb-2 text-gray-800">
        When you're happy with the assignments for the upcoming week, you can
        press the button below to send everyone their sessions for week
        commencing:{' '}
        <strong>{weekStart.toLocaleString(DateTime.DATE_FULL)}</strong>.
      </p>
      <p className="mb-4 text-gray-800">
        Note: messages will only be sent once per person per week. So if any
        assignemnts change, its best to let the individuals know directly.
      </p>
      <div className="md:flex">
        <ButtonConfirm
          className="mb-4"
          onClick={send}
          loading={sending}
          disabled={alreadySent}
        >
          Send notifications for week {weekStart.weekNumber}
        </ButtonConfirm>

        <div className="md:justify-end self-start flex-1 flex">
          <Previous onClick={prevWeek} />
          <Next onClick={nextWeek} />
        </div>
      </div>

      <h3 className="panel-heading mt-8 mb-2">
        Messages sent for week {weekStart.weekNumber}
      </h3>
      <table className="divide-y divide-gray-200 mb-2 table-fixed w-full">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th px-2 w-3/4">
              Key
            </th>
            <th scope="col" className="th px-2">
              Sent at
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
          {data?.messages.map(({ key, insertedAt }) => {
            const dt = DateTime.fromISO(insertedAt)
            return (
              <tr key={key}>
                <td className="px-2 py-4">
                  <code className="text-xs" key={key}>
                    {key}
                  </code>
                </td>
                <td className="px-2 py-4 text-sm">
                  {dt.toLocaleString(DateTime.DATETIME_SHORT)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Panel>
  )
}

export default WeekPanel
