import { DateTime } from 'luxon'

import { STARTING_HOUR, MIN_TIME, HOURS_PER_DAY } from 'utils'
import type { WeekSessionsQuery_sessions as Session } from 'api/__generated__/WeekSessionsQuery'

export type Placeholder = Pick<Session, 'starts' | 'duration' | 'ends'> & {
  blank: true
}
export type SessionOrPlaceholder = Session | Placeholder

/**
 * Make a blank session in the right place for any gaps between sessions
 */
const intersperseBlanks = (
  daySessions: Session[],
  weekStart: DateTime,
  index: number,
): SessionOrPlaceholder[] => {
  const blank = true
  const sortedSessions = daySessions.sort((a, b) => +a.starts - +b.starts)
  const total = sortedSessions.length

  const output: (Session | Placeholder)[] = []

  // Make a big ol blank, if there are no sessions on this day
  // Need weekStart and index to figure out what date
  if (sortedSessions.length < 1) {
    const starts = weekStart.plus({ days: index }).set({ hour: STARTING_HOUR })
    const duration = HOURS_PER_DAY * 60
    output.push({
      blank,
      duration,
      starts,
      ends: starts.plus({ minutes: duration }),
    })
    return output
  }

  // If there are any sessions, then fill in the gaps
  for (let index = 0; index < total; index++) {
    const prev = sortedSessions[index - 1]
    const current = sortedSessions[index]

    const previousEnds =
      prev?.ends || current.starts.startOf('day').plus({ hours: STARTING_HOUR })
    const { starts } = current

    const duration = starts.diff(previousEnds, 'minute')
    // There's a gap between this one and the end of the last one, so insert a blank
    if (duration.minutes > MIN_TIME) {
      output.push({
        blank,
        duration: duration.minutes,
        starts: previousEnds,
        ends: starts,
      })
    }

    output.push(current)

    //If last fill out till end if > 15
    if (index === total - 1) {
      const endOfDay = current.starts
        .startOf('day')
        .plus({ hours: STARTING_HOUR + HOURS_PER_DAY })
      const durationTillEndOfDay = endOfDay.diff(current.ends, 'minute')
      if (durationTillEndOfDay.minutes >= MIN_TIME) {
        output.push({
          blank,
          starts: current.ends,
          duration: durationTillEndOfDay.minutes,
          ends: endOfDay,
        })
      }
    }
  }

  return output
}

/**
 * Sort sessions into a (week) list of (day) lists
 */
export const sortSessions = (
  sessions: Session[],
  weekStart: DateTime,
): SessionOrPlaceholder[][] => {
  const emptyWeek = [[], [], [], [], [], [], []]

  // Curry week start into here so it can be mapped
  const intersperseBlanksWithWeekStart = (
    daySessions: Session[],
    index: number,
  ): SessionOrPlaceholder[] => intersperseBlanks(daySessions, weekStart, index)

  const sessionComparitor = (acc: Session[][], current: Session) => {
    const dow = current.starts.weekday - 1
    const item = {
      ...current,
      dow, // so it knows what list it's in
    }
    if (acc[dow]) {
      acc[dow].push(item)
    } else {
      acc[dow] = [item]
    }
    return acc
  }

  return sessions
    .reduce(sessionComparitor, emptyWeek)
    .map(intersperseBlanksWithWeekStart)
}

// This is typed as partial so it can work for the week lising and detail panel view
export const parseDateTimes = <T>(
  session: T & { starts: string; ends: string },
): T & { starts: DateTime; ends: DateTime } => {
  const starts = DateTime.fromISO(session.starts)
  const ends = DateTime.fromISO(session.ends)
  return { ...session, starts, ends }
}
