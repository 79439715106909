import { useMemo } from 'react'

import type {
  WeekSessionsQuery_sessions as Session,
  WeekSessionsQuery_sessions_assignments as Assignment,
} from 'api/__generated__/WeekSessionsQuery'
import { Role } from 'globalTypes'

type SessionWithAssignments = Pick<Session, 'assignments'>

export default function useAssignmentFilters(session?: SessionWithAssignments) {
  const assignedLifeguards = useMemo(
    (): Assignment[] =>
      session?.assignments?.filter(({ role }) => role === Role.LIFEGUARD) ?? [],
    [session],
  )
  // Get assigned gatekeepers for this session
  const assignedGatekeepers = useMemo(
    (): Assignment[] =>
      session?.assignments?.filter(
        ({ role }: Assignment) => role === Role.GATEKEEPER,
      ) ?? [],
    [session],
  )

  const assignedTechManagers = useMemo(
    (): Assignment[] =>
      session?.assignments?.filter(
        ({ role }: Assignment) => role === Role.TECH_MANAGER,
      ) ?? [],
    [session],
  )

  const assignedTeachers = useMemo(
    (): Assignment[] =>
      session?.assignments?.filter(
        ({ role }: Assignment) => role === Role.TEACHER,
      ) ?? [],
    [session],
  )

  const confirmedLifeguards = useMemo(
    (): Assignment[] =>
      assignedLifeguards.filter(({ confirmedAt }) => !!confirmedAt) ?? [],
    [session],
  )

  const confirmedGatekeepers = useMemo(
    (): Assignment[] =>
      assignedGatekeepers.filter(({ confirmedAt }) => !!confirmedAt) ?? [],
    [session],
  )

  const confirmedTeachers = useMemo(
    (): Assignment[] =>
      assignedTeachers.filter(({ confirmedAt }) => !!confirmedAt) ?? [],
    [session],
  )

  const unConfirmed = useMemo(
    (): Assignment[] =>
      session?.assignments.filter(({ confirmedAt }) => !confirmedAt) ?? [],
    [session],
  )

  const confirmed = useMemo(
    (): Assignment[] =>
      session?.assignments.filter(({ confirmedAt }) => !!confirmedAt) ?? [],
    [session],
  )

  return {
    assignedLifeguards,
    assignedGatekeepers,
    assignedTechManagers,
    assignedTeachers,
    confirmedLifeguards,
    confirmedGatekeepers,
    confirmedTeachers,
    unConfirmed,
    confirmed,
  }
}
