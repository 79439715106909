import { useEffect, useState, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'

import type {
  DocumentPanelQuery,
  DocumentPanelQueryVariables,
  DocumentPanelQuery_document as Document,
} from 'api/__generated__/DocumentPanelQuery'
import type {
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
} from 'api/__generated__/UpdateDocumentMutation'
import type {
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
} from 'api/__generated__/DeleteDocumentMutation'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import { BigLoading } from 'components/shared/loading'
import Error from 'components/shared/error'
import { ucfirst, groupGQLErrors } from 'utils'
import Pill, { PillVariant } from 'components/shared/pill'
import { DocumentEventType, Role } from 'globalTypes'
import UploadDocumentForm from './upload-document'
import { Button, ButtonDanger, ButtonSec } from 'components/buttons'
import DocumentForm, { FormState } from './document-form'
import { RequireRole } from 'components/shared/auth-components'

const DocumentEventVariant = {
  [DocumentEventType.CREATE]: PillVariant.yellow,
  [DocumentEventType.UPDATE]: PillVariant.yellow,
  [DocumentEventType.VIEW]: PillVariant.blue,
}

const defaultFormState = { title: '...', category: '...', description: '...' }

interface DocumentTableProps {
  document: Document
}
export const DocumentTable = ({
  document: {
    title,
    description,
    revision,
    url,
    updatedAt,
    contentType,
    size,
    exists,
    roles,
  },
}: DocumentTableProps): JSX.Element => {
  const sizeString = size ? ` ${(size / 1024).toFixed(2)}Kb` : ''
  const updated = DateTime.fromISO(updatedAt)
  const updatedString = updated.toLocaleString(DateTime.DATETIME_MED)
  return (
    <>
      <h3 className="panel-heading">File info</h3>
      <table className="min-w-full divide-y divide-gray-200 table-fixed mb-4">
        <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
          <tr>
            <th scope="col" className="th--col align-top pt-4">
              Title
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm w-2/3">
              {exists ? (
                <a
                  className="text-link"
                  title={`Download ${title} ${contentType}${sizeString}`}
                  href={url}
                >
                  {title}
                  {revision > 0 && `– v${revision}`}
                </a>
              ) : (
                <>
                  {title}
                  {revision > 0 && `– v${revision}`}
                </>
              )}
            </td>
          </tr>
          <tr>
            <th scope="col" className="th--col align-top pt-4">
              Description
            </th>
            <td className="px-2 py-4 text-sm">{description}</td>
          </tr>
          <tr>
            <th scope="col" className="th--col align-top pt-4">
              Revision
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm">
              {updatedString}
              {revision > 0 && `– v${revision}`}
            </td>
          </tr>
          <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
            <tr>
              <th scope="col" className="th--col align-top pt-4">
                Visible to
              </th>
              <td className="px-2 py-2 whitespace-nowrap text-sm">
                {roles?.map((role) => (
                  <Pill variant={PillVariant.blue} key={role}>
                    {ucfirst(role)}
                  </Pill>
                ))}
                {!roles?.length && (
                  <Pill variant={PillVariant.red}>Everyone</Pill>
                )}
              </td>
            </tr>
          </RequireRole>
        </tbody>
      </table>
    </>
  )
}

export const RevisionHistory = ({
  document: { events },
}: DocumentTableProps): JSX.Element => {
  return (
    <>
      <h3 className="panel-heading">Revision history</h3>
      <div className=" overflow-y-auto h-64">
        <table className="min-w-full divide-y divide-gray-200 table-fixed">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              <th scope="col" className="th px-2 w-1/2">
                Person
              </th>
              <th scope="col" className="th px-2 w-1/2">
                Action
              </th>
              <th scope="col" className="th px-2 w-1/2">
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
            {events.map(
              ({ person: { firstName, lastName }, event, insertedAt }) => {
                const date = DateTime.fromISO(insertedAt)
                const dateString = date.toLocaleString(DateTime.DATETIME_MED)
                return (
                  <tr key={insertedAt}>
                    <td className="px-2 py-4 whitespace-nowrap text-sm capitalize w-1/3">
                      {firstName} {lastName}
                    </td>
                    <td className="px-2 whitespace-nowrap text-sm capitalize w-1/3">
                      <Pill variant={DocumentEventVariant[event]}>
                        {ucfirst(event.toLowerCase())}
                      </Pill>
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap text-sm capitalize w-1/3">
                      {dateString}
                    </td>
                  </tr>
                )
              },
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

interface Props {
  documentId: Document['id']
  close: () => void
  // This is a bodge to give these things to SessionPanel, so it can mount for animating and not have to flash
  setLoading: (loading: boolean) => void
  setRefetch: (refetch: (options?: any) => Promise<any>) => void
  setData: (data?: DocumentPanelQuery) => void
}

const EditDocumentPanel = ({
  documentId,
  setLoading,
  setRefetch,
  setData,
  close,
}: Props): JSX.Element | null => {
  const { data, loading, refetch, error } = useQuery<
    DocumentPanelQuery,
    DocumentPanelQueryVariables
  >(queries.DOCUMENT_PANEL, {
    variables: { documentId },
  })

  const [updateDocument, { loading: saving, error: errorSaving }] = useMutation<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >(mutations.UPDATE_DOCUMENT_MUTATION)

  const [deleteDocument, { loading: deleting }] = useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(mutations.DELETE_DOCUMENT, {
    variables: { documentId },
    refetchQueries: [queries.DOCUMENTS],
  })

  const fieldErrors = useMemo(
    () => groupGQLErrors<UpdateDocumentMutationVariables>(errorSaving),
    [errorSaving],
  )

  const [isEditing, setEditing] = useState(false)
  const [formState, setFormState] = useState<FormState>(defaultFormState)

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    setRefetch(refetch)
  }, [refetch])

  useEffect(() => {
    setData(data)
  }, [data])

  useEffect(() => {
    setEditing(false)
  }, [documentId, setEditing])

  // Set inital form state, and reset when toggling edit
  useEffect(() => {
    if (data) {
      // Initial data from get query is a subset of fields in document panel query
      setFormState(data.formState)
    }
  }, [data?.document, isEditing])

  if (loading) {
    return <BigLoading loading />
  }
  if (error) {
    return <Error className="mt-6">{error.message}</Error>
  }

  if (!data) {
    return <Error className="mt-6">Oh dear</Error>
  }

  const save = async () => {
    try {
      await updateDocument({ variables: { ...formState, documentId } })
      setEditing(false)
    } catch {
      // Noop, errorSaving from hook should display the error
    }
  }

  const deleteAndClose = async () => {
    try {
      await deleteDocument()
      close()
    } catch {
      // oh dear
    }
  }

  return (
    <div className="flex-1 pb-5">
      {isEditing ? (
        <div className="mb-4">
          <DocumentForm
            formState={formState ?? defaultFormState}
            setFormState={setFormState}
            save={save}
            fieldErrors={fieldErrors}
          />

          {errorSaving && (
            <p className="text-xs mt-4 text-red-600 capitalize" role="alert">
              {errorSaving}
            </p>
          )}

          <div className="flex mb-4">
            <ButtonSec onClick={() => setEditing(false)}>Cancel</ButtonSec>
            <Button onClick={save} loading={saving}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          <DocumentTable document={data.document} />
          <div className="flex mb-4">
            <Button type="edit" onClick={() => setEditing(true)}>
              Edit document details
            </Button>
            <ButtonDanger onClick={deleteAndClose} loading={deleting}>
              Delete document
            </ButtonDanger>
          </div>
        </>
      )}
      <RevisionHistory document={data.document} />
      <UploadDocumentForm document={data.document} refetch={refetch} />
    </div>
  )
}

export default EditDocumentPanel
