import { useState, useMemo } from 'react'
import Select from 'react-select'
import { useMutation, useQuery } from '@apollo/client'

import type {
  OrderPanelQuery_order as Order,
  OrderPanelQuery_order_session as Session,
} from 'api/__generated__/OrderPanelQuery'

import type {
  SessionOptionsQuery,
  SessionOptionsQueryVariables,
  SessionOptionsQuery_bookableSessions as SessionOption,
} from 'api/__generated__/SessionOptionsQuery'

import type {
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
} from 'api/__generated__/UpdateOrderMutation'

import * as mutations from 'api/mutations'
import * as queries from 'api/queries'
import { DateTime } from 'luxon'
import { InlineButtonDanger } from 'components/buttons'
import Error from 'components/shared/error'

type Option = {
  value: SessionOption['id']
  label: React.ReactNode
  item: SessionOption
}
type Group = { options: Option[]; label: string }
type GroupedByKeys = Record<string, Group>

type Props = {
  orderId: Order['id']
}
const ChangeSession = ({ orderId }: Props): JSX.Element => {
  const [sessionId, setSessionId] = useState<Session['id'] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const since = DateTime.now()
  const to = since.endOf('year')

  const { data, loading } = useQuery<
    SessionOptionsQuery,
    SessionOptionsQueryVariables
  >(queries.CHANGE_SESSION_OPTIONS_QUERY, {
    variables: { since: since.toISODate(), to: to.toISODate() },
  })

  const [updateOrder, { error }] = useMutation<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >(mutations.UPDATE_ORDER, { refetchQueries: [queries.ORDER_PANEL] })

  const onChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      setSessionId(selectedOption.value)
    }
  }

  const save = async () => {
    setIsLoading(true)
    await updateOrder({ variables: { orderId, sessionId } })
    setIsLoading(false)
  }

  const options = useMemo((): Group[] => {
    if (!data?.bookableSessions) return []

    const groupedOptions: GroupedByKeys = data.bookableSessions.reduce(
      (acc: GroupedByKeys, current: SessionOption) => {
        const starts = DateTime.fromISO(current.starts)
        const date = starts.toISODate()
        const booked = current.spaces < 1
        const formattedDt = starts.toLocaleString(DateTime.DATETIME_MED)
        const label = (
          <div className="flex items-center">
            <p className="flex-1">{formattedDt}</p>
            {booked && <span className="text-xs">Booked</span>}
          </div>
        )

        const item = {
          value: current.id,
          label,
          item: current,
        }

        if (acc[date]) {
          acc[date].options.push(item)
        } else {
          acc[date] = {
            options: [item],
            label: starts.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
          }
        }
        return acc
      },
      {},
    )
    return Object.values(groupedOptions)
  }, [data?.bookableSessions])

  if (loading) return <>Loading</>

  return (
    <div className="mb-6">
      <h3 className="panel-heading">Change order slot</h3>
      <p className="text-gray-600 text-sm">
        If you wish to change an order to another slot, select the slot below.
        Remember to re-send the order confirmation email.
      </p>
      <div className="flex mt-4 items-center">
        <Select
          className="flex-1 mr-3"
          onChange={onChange}
          options={options}
          isOptionDisabled={({ item: { spaces } }) => spaces < 1}
        />
        <InlineButtonDanger disabled={!sessionId || isLoading} onClick={save}>
          Save
        </InlineButtonDanger>
      </div>

      {error && <Error className="block mt-4">{error}</Error>}
    </div>
  )
}

export default ChangeSession
