import React, { useState, useEffect, SyntheticEvent } from 'react'
import { ICONS, IconArrowLeft, IconArrowRight, IconReload } from './icons'

type IconType = 'edit' | 'view' | 'publish' | 'cancel' | 'info'

interface Props {
  onClick: () => void
  className?: string
  disabled?: boolean
  title?: string
  loading?: boolean
}

interface IconableButtonProps extends Props {
  type?: IconType
}

interface UseDanger {
  (onClick: () => void, loading?: boolean): {
    pressed: boolean
    onFirstClick: () => void
  }
}
const useDanger: UseDanger = (onClick, loading) => {
  const [pressed, setPressed] = useState(false)

  const onFirstClick = () => {
    setPressed(true)
  }

  useEffect(() => {
    setPressed(false)
  }, [onClick])

  // Reset if loading transitions
  useEffect(() => {
    // if (!loading) {
    setPressed(false)
    // }
  }, [loading])

  return {
    pressed,
    onFirstClick,
  }
}

const BaseButton: React.FC<IconableButtonProps> = ({
  children,
  onClick,
  type,
  className,
  disabled,
}) => (
  <span className="shadow-sm rounded-md">
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={'btn mr-2 rounded-md disabled:opacity-50 ' + className}
    >
      {type && ICONS[type]}
      <span className="h-5">{children}</span>
    </button>
  </span>
)
export const Button: React.FC<IconableButtonProps> = (props) => (
  <BaseButton
    {...props}
    className={
      'text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 btn-ring ' +
      props.className
    }
  />
)

export const ButtonSec: React.FC<IconableButtonProps> = (props) => (
  <BaseButton
    {...props}
    className={
      'border-gray-200 text-gray-800 bg-gray-100 hover:border-gray-300 btn-ring ' +
      props.className
    }
  />
)

export const ButtonDanger: React.FC<Props & { confirm?: string }> = (props) => {
  const { onClick, className, confirm = 'You sure?', loading } = props
  const { pressed, onFirstClick } = useDanger(onClick, loading)

  return pressed ? (
    <BaseButton
      {...props}
      className={
        'border-red-600 text-white bg-red-600 hover:bg-red-500 ring-2 ring-red-600 ring-offset-2 ring-offset-white btn-ring ' +
        className
      }
    >
      {confirm}
    </BaseButton>
  ) : (
    <BaseButton
      {...props}
      onClick={onFirstClick}
      className={
        'border-red-600 text-white bg-red-600 hover:bg-red-500 btn-ring ' +
        className
      }
    />
  )
}

export const ButtonConfirm: React.FC<Props & { confirm?: string }> = (
  props,
) => {
  const { onClick, className, confirm = 'You sure?', loading } = props
  const { pressed, onFirstClick } = useDanger(onClick, loading)

  return pressed ? (
    <BaseButton
      {...props}
      className={
        'border-indigo-600 text-white bg-indigo-600 hover:bg-indigo-500 ring-2 ring-indigo-600 ring-offset-2 ring-offset-white btn-ring ' +
        className
      }
    >
      {confirm}
    </BaseButton>
  ) : (
    <BaseButton
      {...props}
      onClick={onFirstClick}
      className={
        'border-indigo-600 text-white bg-indigo-600 hover:bg-indigo-500 btn-ring ' +
        className
      }
    />
  )
}

export const RoundButton: React.FC<Props> = ({
  onClick,
  className,
  children,
}) => (
  <button
    onClick={onClick}
    type="button"
    className={
      'btn-ring round-centre rounded-full bg-blue-500 text-white text-lg font-bold h-6 w-6 leading-3 cursor-pointer hover:bg-blue-600 ' +
      className
    }
  >
    {children}
  </button>
)

interface ExpandProps extends Props {
  isOpen: boolean
}
export const Plus: React.FC<ExpandProps> = (props) => (
  <RoundButton {...props}>
    <svg
      className={`w-4 h-4 transition-transform transform ${
        !props.isOpen && ' -rotate-45 '
      }`}
      fill="currentColor"
      viewBox="0 0 512 512"
    >
      <path
        d="M256.010 204.645l100.118-100.146 51.344 51.33-100.118 100.146-51.344-51.329z"
        fill="currentColor"
      />
      <path
        d="M155.827 407.483l-51.344-51.358 100.161-100.132 51.344 51.358-100.161 100.132z"
        fill="currentColor"
      />
      <path
        d="M407.498 356.112l-51.373 51.358-100.118-100.146 51.373-51.358 100.118 100.146z"
        fill="currentColor"
      />
      <path
        d="M104.502 155.857l51.337-51.351 100.153 100.125-51.337 51.351-100.153-100.125z"
        fill="currentColor"
      />
      <path
        d="M255.983 307.36l-51.351-51.365 51.365-51.351 51.351 51.365-51.365 51.351z"
        fill="currentColor"
      />
    </svg>
  </RoundButton>
)

export const Expand: React.FC<ExpandProps> = (props) => (
  <RoundButton {...props}>
    <svg
      className={`w-2 h-2 transition-transform transform ${
        !props.isOpen && ' -rotate-90 '
      }`}
      fill="currentColor"
      viewBox="0 0 30 25"
    >
      <polygon points="15,30 30,3 0,3" />
    </svg>
  </RoundButton>
)

export const InlineButton: React.FC<Props> = ({
  onClick,
  className,
  children,
  disabled,
  title,
}) => {
  return disabled ? (
    <span
      title={title}
      className={
        'text-gray-500 font-bold text-xs rounded-md leading-none align-middle py-1 px-2 uppercase'
      }
    >
      {children}
    </span>
  ) : (
    <button
      onClick={onClick}
      title={title}
      className={
        'font-bold text-xs rounded-md transition duration-200 transition-colors leading-none align-middle py-1 px-2 cursor-pointer uppercase ' +
        className
      }
    >
      {children}
    </button>
  )
}
export const InlineButtonDanger: React.FC<Props> = ({
  onClick,
  className,
  disabled,
  children,
  title,
  loading,
}) => {
  const { pressed, onFirstClick } = useDanger(onClick, loading)

  return pressed ? (
    <InlineButton
      onClick={onClick}
      disabled={disabled}
      title={title}
      className={
        'text-red-500 hover:bg-red-100 ring-1 ring-red-600 ring-offset-1 ring-offset-white btn-ring ' +
        className
      }
    >
      You sure?
    </InlineButton>
  ) : (
    <InlineButton
      onClick={onFirstClick}
      disabled={disabled}
      title={title}
      className={'text-red-500 hover:bg-red-100 ' + className}
    >
      {children}
    </InlineButton>
  )
}
export const InlineButtonPrimary: React.FC<Props> = ({
  onClick,
  className,
  disabled,
  children,
  title,
}) => (
  <InlineButton
    onClick={onClick}
    disabled={disabled}
    className={'text-blue-500 hover:bg-blue-100 ' + className}
    title={title}
  >
    {children}
  </InlineButton>
)

export const Next: React.FC<Props> = ({ onClick, className }) => (
  <button
    onClick={onClick}
    className={
      'btn rounded-r-md px-3 border border-l-0 border-gray-300 bg-white text-gray-500 hover:text-gray-600 hover:border-gray-400 ' +
      className
    }
    aria-label="Next"
  >
    <IconArrowRight />
  </button>
)

export const Previous: React.FC<Props> = ({ onClick, className }) => (
  <button
    onClick={onClick}
    className={
      '-ml-px btn rounded-l-md px-3 border border-gray-300 bg-white text-gray-500 hover:text-gray-600 hover:border-gray-400 ' +
      className
    }
    aria-label="Previous"
  >
    <IconArrowLeft />
  </button>
)

export const Reload = ({ onClick, className }: Props): JSX.Element => {
  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onClick()
  }

  return (
    <button
      onClick={handleClick}
      className={'btn py-1 ' + className}
      aria-label="Reload"
    >
      <IconReload />
    </button>
  )
}
