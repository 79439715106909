import React from 'react'
import { DateTime } from 'luxon'
import { useNavigate, Link } from 'react-router-dom'

import { Role } from 'globalTypes'
import { Previous, Next } from '../buttons'
import Loading from '../shared/loading'
import { RequireRole } from 'components/shared/auth-components'

interface HeaderProps {
  children: React.ReactNode
  before?: React.ReactNode
  className?: string
}
export const Header = ({
  children,
  before,
  className,
}: HeaderProps): JSX.Element => {
  return (
    <header className={`bg-white shadow sticky top-0 z-10 ${className}`}>
      <div className="max-w-7xl mx-auto py-6 px-6 flex flex-col-reverse md:flex-row-reverse justify-between lg:px-8">
        {before}
        <h1 className="flex-1 sm:text-xl md:text-3xl font-bold leading-tight text-gray-900 ml-3">
          {children}
        </h1>
      </div>
    </header>
  )
}

interface WeekHeaderProps {
  weekStart: DateTime
  loading: boolean
}
export const WeekHeader: React.FC<WeekHeaderProps> = ({
  weekStart,
  loading,
}) => {
  const navigate = useNavigate()

  const now = DateTime.local()
  const nextWeek = () => {
    navigate(`/wc/${weekStart.plus({ week: 1 }).toISODate()}`)
  }
  const prevWeek = () => {
    navigate(`/wc/${weekStart.plus({ week: -1 }).toISODate()}`)
  }
  const isThisWeek = now.hasSame(weekStart, 'week')

  return (
    <Header
      before={
        <div className="flex-0 self-end flex flex-col-reverse sm:flex-row-reverse items-end">
          <div className="flex mt-4 sm:mt-0">
            <Loading className="flex-0 mr-2 self-center" loading={loading} />
            <Previous onClick={prevWeek} />
            <Next onClick={nextWeek} />
          </div>
          <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
            <Link
              className="md:mr-6 btn rounded-md px-3 border border-gray-300 bg-white text-gray-500 hover:text-gray-600 hover:border-gray-400 "
              to={`/wc/${weekStart.toISODate()}/messages`}
            >
              Notifications
            </Link>
          </RequireRole>
        </div>
      }
    >
      {isThisWeek ? 'This week' : 'Week starting'}
      <span className="font-light text-gray-500 block md:px-3 md:inline">
        <span className="hidden md:inline">/ </span>
        <span className="">{weekStart.toFormat('DDD')}</span>
      </span>
    </Header>
  )
}
