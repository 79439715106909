import { gql } from '@apollo/client'

import * as fragments from 'api/fragments'

export const WEEK_SESSIONS = gql`
  query WeekSessionsQuery($since: Date!, $to: Date!) {
    sessions(since: $since, to: $to) {
      ...SessionFragment
      assignments {
        ...AssignmentFragment
      }
      myAssignment {
        ...AssignmentFragment
      }
      courses {
        id
        bookableCapacity
        spaces
      }
    }
  }
  ${fragments.Session.SessionFragment}
  ${fragments.Assignment.AssignmentFragment}
`

export const PEOPLE = gql`
  query PeopleQuery($role: Role, $userStatus: UserStatus) {
    people(role: $role, userStatus: $userStatus) {
      ...PersonFragment
      roles
      phone
      capabilities {
        id
        name
        description
        roles
      }
      users {
        id
        email
        status
      }
    }
  }
  ${fragments.People.PersonFragment}
`

export const PEOPLE_PANEL = gql`
  query PeoplePanelQuery($personId: ID!) {
    person(id: $personId) {
      ...PersonFragment
      roles
      contactPreference
      reminderPreference
      capabilities {
        id
        name
        description
        roles
      }
      users {
        id
        email
        status
        authType
      }
    }
    allCapabilities: capabilities {
      id
      name
      description
      roles
    }
  }
  ${fragments.People.PersonFragment}
`

export const SESSION_PANEL = gql`
  query SessionPanelQuery($sessionId: ID!) {
    session(id: $sessionId) {
      ...SessionFragment
      assignments {
        ...AssignmentFragment
      }
      myAssignment {
        ...AssignmentFragment
      }
      courses {
        id
        name
        level
        bookableCapacity
      }
    }
    lifeguards: people(role: LIFEGUARD) {
      ...PersonFragment
    }
    gatekeepers: people(role: GATEKEEPER) {
      ...PersonFragment
    }
    teachers: people(role: TEACHER) {
      ...PersonFragment
    }
    tech_managers: people(role: TECH_MANAGER) {
      ...PersonFragment
    }
    orders(sessionId: $sessionId) {
      ...OrderFragment
    }
    courses {
      id
      name
      level
      bookableCapacity
      spaces
    }
  }
  ${fragments.Session.SessionFragment}
  ${fragments.Assignment.AssignmentFragment}
  ${fragments.People.PersonFragment}
  ${fragments.Order.OrderFragment}
`

export const SESSION_PANEL_READ_ONLY = gql`
  query SessionPanelReadOnlyQuery($sessionId: ID!) {
    session(id: $sessionId) {
      ...SessionFragment
      myAssignment {
        ...AssignmentFragment
      }
      assignments {
        ...AssignmentFragment
      }
    }
    orders(sessionId: $sessionId) {
      ...OrderFragment
    }
    user {
      id
      roles
      person {
        id
      }
    }
  }
  ${fragments.Session.SessionFragment}
  ${fragments.Assignment.AssignmentFragment}
  ${fragments.Order.OrderFragment}
`

export const USER_SETTINGS = gql`
  query UserSettingsQuery {
    user {
      id
      email
      person {
        ...PersonFragment
        contactPreference
        reminderPreference
        phone
      }
    }
  }
  ${fragments.People.PersonFragment}
`

export const USER_CHECK = gql`
  query UserCheckQuery {
    user {
      id
      status
      roles
      person {
        ...PersonFragment
      }
    }
  }
  ${fragments.People.PersonFragment}
`

export const USER_BADGE = gql`
  query UserBadgeQuery {
    user {
      id
      email
      person {
        id
        phone
        contactPreference
        firstName
        lastName
      }
    }
  }
`

export const ORDERS = gql`
  query OrderQuery(
    $since: Date!
    $to: Date
    $orderType: OrderType
    $orderStatus: OrderStatus
  ) {
    orders(
      since: $since
      to: $to
      orderType: $orderType
      orderStatus: $orderStatus
    ) {
      id
      firstName
      lastName
      email
      phone
      postCode
      ref
      places
      orderStatus
      paidAt
      pricePence
      orderType
      paymentType
      insertedAt
      session {
        id
        starts
        ends
      }
      course {
        id
        name
        level
      }
      orderSkus {
        id
        quantity
        sku {
          id
          name
          price
          id
        }
      }
    }
  }
`

export const ORDER_PANEL = gql`
  query OrderPanelQuery($orderId: ID!) {
    order(orderId: $orderId) {
      id
      firstName
      lastName
      email
      phone
      postCode
      ref
      places
      orderStatus
      paidAt
      pricePence
      insertedAt
      orderType
      paymentType
      notes
      paymentIntentId
      session {
        id
        starts
        ends
        type
      }
      course {
        id
        name
        level
        bookableCapacity
      }
      orderSkus {
        id
        quantity
        sku {
          id
          name
          price
          id
        }
      }
    }
  }
`

export const CHANGE_SESSION_OPTIONS_QUERY = gql`
  query SessionOptionsQuery($since: Date!, $to: Date!) {
    bookableSessions(since: $since, to: $to) {
      id
      starts
      type
      spaces
    }
  }
`

export const ALL_COURSES = gql`
  query AllCoursesQuery {
    allCourses {
      id
      name
      level
      bookableCapacity
      isAvailable
      spaces
    }
  }
`

export const COURSE_PANEL = gql`
  query CoursePanelQuery($courseId: ID!) {
    course(courseId: $courseId) {
      id
      name
      level
      bookableCapacity
      isAvailable
      spaces
      sessions {
        id
        starts
        ends
      }
    }
    orders(courseId: $courseId) {
      ...OrderFragment
    }
  }
  ${fragments.Order.OrderFragment}
`

export const MESSAGES = gql`
  query MessageQuery($since: Date!, $to: Date!) {
    messages(since: $since, to: $to) {
      id
      key
      insertedAt
    }
  }
`

export const DOCUMENTS = gql`
  query DocumentsQuery {
    documents {
      __typename
      id
      title
      description
      revision
      filename
      contentType
      size
      exists
      url
      category
      updatedAt
      roles
    }
    documentCategories {
      category
      count
    }
  }
`

export const DOCUMENT_PANEL = gql`
  query DocumentPanelQuery($documentId: ID!) {
    document(documentId: $documentId) {
      __typename
      id
      title
      description
      revision
      filename
      contentType
      size
      url
      exists
      category
      updatedAt
      roles
      events {
        __typename
        id
        event
        person {
          __typename
          id
          firstName
          lastName
          picture
        }
        insertedAt
      }
    }
    formState: document(documentId: $documentId) {
      documentId: id
      category
      title
      description
      roles
    }
  }
`

export const DOCUMENT_CATEGORIES = gql`
  query DocumentCategoriesQuery {
    documentCategories {
      category
      count
    }
  }
`
