import { useState, useRef } from 'react'
import { DateTime } from 'luxon'

import type {
  OrderPanelQuery,
  OrderPanelQuery_order as Order,
} from 'api/__generated__/OrderPanelQuery'

import Panel from 'components/panel'
import EditOrderPanel from 'components/orders/edit-order-panel'
import SessionPanelHeader from 'components/panel/session-panel-header'
import GenericPanelHeader from 'components/panel/generic-panel-header'

interface Props {
  orderId?: Order['id']
  close: () => void
}

const OrderPanel = ({ orderId, close }: Props): JSX.Element => {
  // Passing data, loading and refetch back out here to make the header work
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<OrderPanelQuery | undefined>(undefined)
  const refetchRef = useRef<() => void>(() => null)
  const setRefetch = (refetch: () => Promise<void>): void => {
    refetchRef.current = refetch
  }

  let header
  if (data?.order?.session) {
    const starts = data?.order && DateTime.fromISO(data.order.session.starts)
    const ends = data?.order && DateTime.fromISO(data.order.session.ends)
    header = (
      <SessionPanelHeader
        starts={starts}
        ends={ends}
        type={data.order.session.type}
        reload={refetchRef.current}
        loading={loading}
      />
    )
  }
  if (data?.order?.course) {
    header = (
      <GenericPanelHeader
        title={`(${data.order.course.name}) lesson booking`}
        reload={refetchRef.current}
        loading={loading}
        icon="🎓"
      />
    )
  }

  return (
    <Panel isOpen={!!orderId} close={close} header={header}>
      {orderId && (
        <EditOrderPanel
          orderId={orderId}
          setData={setData}
          setLoading={setLoading}
          setRefetch={setRefetch}
          close={close}
        />
      )}
    </Panel>
  )
}

export default OrderPanel
