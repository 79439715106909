import { ChangeEvent, useState } from 'react'

import type { DocumentPanelQuery_document as Document } from 'api/__generated__/DocumentPanelQuery'

import { Button } from 'components/buttons'

type Props = {
  document: Document
  refetch: () => void
}
const UploadDocumentForm = ({
  document: { url },
  refetch,
}: Props): JSX.Element => {
  const [file, setFile] = useState<File | undefined>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setFile(file)
  }

  const onSubmit = () => {
    if (!file || !url) {
      return
    }
    setLoading(true)
    const formData = new FormData()
    formData.append('input', file)
    const request = new XMLHttpRequest()
    request.onload = onComplete
    request.onerror = onError
    request.open('POST', url)
    request.send(formData)
  }

  const onComplete = () => {
    setLoading(false)
    refetch()
  }

  const onError = () => {
    setLoading(false)
    setError('There was an error uploading the file')
  }

  return (
    <div className="my-4">
      <h3 className="panel-heading">Upload a version</h3>
      <div className="flex items-stretch">
        <input
          type="file"
          onChange={onFileChange}
          className="
              flex-1
              form-control
              block
              w-full
              px-1
              py-1
              text-sm
              font-normal
              bg-blue-50 bg-clip-padding
              border-blue-100 border-2
              rounded-md
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
            "
        />
        <Button
          onClick={onSubmit}
          loading={loading}
          disabled={!file}
          className="ml-2"
        >
          Save
        </Button>
      </div>
      <div className="mt-2">
        <p className="text-sm text-gray-600">
          You can upload something up to 20Mb
        </p>
      </div>
      {error && (
        <p className="text-xs mt-4 text-red-600 capitalize" role="alert">
          {error}
        </p>
      )}
    </div>
  )
}

export default UploadDocumentForm
