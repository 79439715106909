import React, { useEffect } from 'react'

interface Props {
  title?: string
  isOpen: boolean
  close?: () => void
  header?: React.ReactElement
}

const Panel: React.FC<Props> = ({ children, title, isOpen, close, header }) => {
  const containerclassNames = isOpen ? 'visible' : 'invisible'
  const overlayclassNames = isOpen
    ? 'opacity-100 visible'
    : 'opacity-0 invisible'
  const panelclassNames = isOpen ? 'translate-x-0' : 'translate-x-full'
  const buttonclassNames = isOpen ? 'opacity-100' : 'opacity-0'
  const stopProp: React.MouseEventHandler<HTMLDivElement> = (evt) =>
    evt.stopPropagation()

  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === 'Escape') close?.()
    }
    if (isOpen) {
      window.addEventListener('keyup', onKeyup)
    } else {
      window.removeEventListener('keyup', onKeyup)
    }
    return () => window.removeEventListener('keyup', onKeyup)
  }, [isOpen])

  return (
    <div
      className={'fixed inset-0 overflow-hidden z-10 ' + containerclassNames}
    >
      <div
        className={
          'absolute inset-0 overflow-hidden ease-in-out duration-500 ' +
          overlayclassNames
        }
        onClick={close}
      >
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-0"></div>
        <section
          className={
            'absolute inset-y-0 right-0 pl-10 max-w-full flex transform transition ease-in-out duration-500 sm:duration-700 ' +
            panelclassNames
          }
        >
          <div
            className={
              'relative w-screen max-w-md ease-in-out duration-500' +
              buttonclassNames
            }
          >
            <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
              <button
                onClick={close}
                aria-label="Close panel"
                className="focus:outline-none text-gray-300 hover:text-white transition ease-in-out duration-150"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div
              className="h-full flex flex-col space-y-6 bg-white shadow-xl overflow-y-scroll flex"
              onClick={stopProp}
            >
              {title && (
                <header className="px-4 sm:px-6 bg-indigo-700 py-6">
                  <h2 className="text-lg leading-7 font-medium text-white">
                    {title}
                  </h2>
                </header>
              )}
              {header}
              <div className="relative flex-1 px-4 sm:px-6">
                <div className="absolute inset-0 px-4 sm:px-6 flex flex-col">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Panel
