import React, { useEffect, useState } from 'react'
import Select, { Options, OnChangeValue } from 'react-select'
import { useMutation } from '@apollo/client'

import {
  Plus,
  InlineButtonPrimary,
  InlineButtonDanger,
} from 'components/buttons'
import Error from 'components/shared/error'

import { CopyAssignmentPeriod, Role, SessionType } from 'globalTypes'

import type {
  SessionPanelQuery_session as Session,
  SessionPanelQuery_session_assignments as Assignment,
  SessionPanelQuery_lifeguards as Lifeguard,
  SessionPanelQuery_gatekeepers as Gatekeeper,
  SessionPanelQuery_gatekeepers_capabilities as Capability,
} from 'api/__generated__/SessionPanelQuery'
import type {
  AddAssignmentMutation,
  AddAssignmentMutationVariables,
} from 'api/__generated__/AddAssignmentMutation'
import type {
  ConfirmAssignmentMutation,
  ConfirmAssignmentMutationVariables,
} from 'api/__generated__/ConfirmAssignmentMutation'
import type {
  DeleteAssignmentMutation,
  DeleteAssignmentMutationVariables,
} from 'api/__generated__/DeleteAssignmentMutation'
import * as mutations from 'api/mutations'
import * as queries from 'api/queries'
import { Link } from 'react-router-dom'
import { RoleIcon } from 'components/Shirt'
import Pill, { PillVariant } from 'components/shared/pill'
import { RequireRole } from 'components/shared/auth-components'
import {
  CopyAssignmentMutation,
  CopyAssignmentMutationVariables,
} from 'api/__generated__/CopyAssignmentMutation'

type Person = Lifeguard | Gatekeeper

interface Option {
  value: Person['id']
  label: string | React.ReactNode
}

const AgePill = ({
  capabilities,
}: {
  capabilities: Capability[]
}): JSX.Element | null => {
  const ageCap = capabilities.find(({ name }) => name.match(/18/))
  if (!ageCap) return null
  return (
    <Pill className="-my-1" variant={PillVariant.green}>
      {ageCap.name}
    </Pill>
  )
}

const mapPerson = ({
  id,
  firstName,
  lastName,
  capabilities,
}: Person): Option => ({
  value: id,
  label: (
    <div className="flex items-center">
      <p className="flex-1">
        {firstName} {lastName}
      </p>
      <AgePill capabilities={capabilities} />
    </div>
  ),
})
const mapForSelect = (people: Person[]): Options<Option> =>
  people.map(mapPerson)

const pedagogicSessionTypes = [
  SessionType.LESSON,
  SessionType.SCHOOL,
  SessionType.TRAINING,
]

interface AddPersonProps {
  visible: boolean
  addPerson: () => void
  closeAddRole: () => void
  people: Person[]
  setSelectedPerson: (personId: string | null) => void
  selectedPerson: string | null
  addAssignment: () => void
  saving: boolean
}

const AddPerson = ({
  visible,
  addPerson,
  closeAddRole,
  people,
  setSelectedPerson,
  selectedPerson,
  addAssignment,
  saving,
}: AddPersonProps): JSX.Element => {
  const onChange = (selectedOption: OnChangeValue<Option, false>) => {
    const value = selectedOption?.value ?? null
    setSelectedPerson(value)
  }

  const peopleOptions = mapForSelect(people)

  const toggle = () => {
    if (visible) {
      closeAddRole()
    } else {
      addPerson()
    }
  }

  return (
    <>
      <div className="flex h-10">
        <div className="flex-0 mr-2">
          <Plus isOpen={visible} onClick={toggle} className="mt-3" />
        </div>
        {visible ? (
          <>
            <div className="flex-1 pt-1">
              <Select
                options={peopleOptions}
                onChange={onChange}
                className="shadow focus:ring rounded-md focus:outline-none"
              />
            </div>
            <div className="pt-2 pl-3">
              <InlineButtonPrimary
                onClick={addAssignment}
                disabled={!selectedPerson || saving}
              >
                Add
              </InlineButtonPrimary>
            </div>
          </>
        ) : (
          <span className="text-sm text-gray-400 flex-1 mt-3">
            Press plus to add someone
          </span>
        )}
      </div>
    </>
  )
}

interface AssignmentProps {
  person: Person
  isConfirmed: boolean
  role: Role
}

interface AssignmentUIProps extends AssignmentProps {
  removeAssignment: () => void
  confirmAssignment: () => void
}

const AssignmentUI: React.FC<AssignmentUIProps> = ({
  person: { firstName, lastName, id, capabilities },
  isConfirmed,
  removeAssignment,
  confirmAssignment,
  role,
}) => {
  const ageCap = capabilities.find(({ name }) => name.match(/18/))

  return (
    <div className="mb-1 flex items-center group">
      <Link to={`/people/${id}`} title="View">
        {
          <RoleIcon
            role={role}
            isConfirmed={isConfirmed}
            className="mr-2"
            flag={ageCap?.name}
          />
        }
      </Link>
      <span
        className={`flex-1 text-sm truncate ${
          isConfirmed ? 'text-gray-600' : 'text-gray-400'
        }`}
      >
        {firstName} {lastName}
      </span>
      <div className="">
        <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
          <InlineButtonDanger onClick={removeAssignment}>
            Remove
          </InlineButtonDanger>
          {!isConfirmed && (
            <InlineButtonPrimary onClick={confirmAssignment}>
              Confirm
            </InlineButtonPrimary>
          )}
        </RequireRole>
      </div>
    </div>
  )
}

export const ReadOnlyAssignment: React.FC<AssignmentProps> = ({
  person: { firstName, lastName, id },
  isConfirmed,
  role,
}) => {
  return (
    <div className="mb-1 flex items-center">
      <Link to={`/people/${id}`} title="View">
        {<RoleIcon role={role} isConfirmed={isConfirmed} className="mr-2" />}
      </Link>
      <span
        className={`flex-1 text-sm truncate ${
          isConfirmed ? 'text-gray-600' : 'text-gray-400'
        }`}
      >
        {firstName} {lastName}
      </span>
    </div>
  )
}

export interface AssignmentsProps {
  roleToAdd: Role | null
  setRoleToAdd: (role: Role | null) => void
  assignedLifeguards: Assignment[]
  assignedGatekeepers: Assignment[]
  assignedTeachers: Assignment[]
  assignedTechManagers: Assignment[]
  lifeguards: Person[]
  gatekeepers: Person[]
  techManagers: Person[]
  teachers: Person[]
  sessionId: Session['id']
  sessionType: SessionType
}

export const Assignments = ({
  roleToAdd,
  setRoleToAdd,
  assignedLifeguards,
  assignedGatekeepers,
  assignedTeachers,
  assignedTechManagers,
  lifeguards,
  gatekeepers,
  techManagers,
  teachers,
  sessionId,
  sessionType,
}: AssignmentsProps): JSX.Element => {
  const [personId, setPersonId] = useState<Person['id'] | null>(null)
  const addingLifeguard = roleToAdd === Role.LIFEGUARD
  const addingGatekeeper = roleToAdd === Role.GATEKEEPER
  const addingTechManager = roleToAdd === Role.TECH_MANAGER
  const addingTeacher = roleToAdd === Role.TEACHER

  // Button callbacks
  const addGatekeeper = () => setRoleToAdd(Role.GATEKEEPER)
  const addLifeguard = () => setRoleToAdd(Role.LIFEGUARD)
  const addTechManager = () => setRoleToAdd(Role.TECH_MANAGER)
  const addTeacher = () => setRoleToAdd(Role.TEACHER)
  const closeAddRole = () => {
    setRoleToAdd(null)
    setPersonId(null)
  }

  // Clear selected person if switching between role UIs
  useEffect(() => {
    setPersonId(null)
  }, [roleToAdd, setPersonId])

  const [addAssignment, { loading: saving, error: errorSaving }] = useMutation<
    AddAssignmentMutation,
    AddAssignmentMutationVariables
  >(mutations.ADD_ASSIGNMENT, { refetchQueries: [queries.SESSION_PANEL] })

  const [confirmAssignment, { error: errorConfirming }] = useMutation<
    ConfirmAssignmentMutation,
    ConfirmAssignmentMutationVariables
  >(mutations.CONFIRM_ASSIGNMENT, { refetchQueries: [queries.SESSION_PANEL] })

  const [removeAssignment, { error: errorDeleting }] = useMutation<
    DeleteAssignmentMutation,
    DeleteAssignmentMutationVariables
  >(mutations.DELETE_ASSIGNMENT, { refetchQueries: [queries.SESSION_PANEL] })

  const [copyAssignment, { error: errorCopying, loading: copying }] =
    useMutation<CopyAssignmentMutation, CopyAssignmentMutationVariables>(
      mutations.COPY_ASSIGNMENT,
      { refetchQueries: [queries.SESSION_PANEL] },
    )

  // Call mutation if the fields are set
  const doAddAssignment = async () => {
    if (!personId || !roleToAdd) return
    try {
      await addAssignment({
        variables: {
          sessionId,
          personId: personId,
          role: roleToAdd,
        },
      })
    } catch (error) {
      // error handled by hook
    }
  }

  const error =
    errorSaving?.message ??
    errorConfirming?.message ??
    errorDeleting?.message ??
    errorCopying?.message ??
    undefined

  const doConfirmAssignment = (assignmentId: Assignment['id']) => {
    confirmAssignment({
      variables: {
        assignmentId,
      },
    })
  }

  const doRemoveAssignment = (assignmentId: Assignment['id']) => {
    removeAssignment({
      variables: {
        assignmentId,
      },
    })
  }

  const doCopyAssignment = (
    assignmentId: Assignment['id'],
    period: CopyAssignmentPeriod,
  ) => {
    copyAssignment({
      variables: {
        assignmentId,
        period,
      },
    })
  }

  return (
    <div className="mb-4">
      <h3 className="panel-heading">Lifeguards</h3>
      {assignedLifeguards?.map(({ person, isConfirmed, role, id }) => (
        <AssignmentUI
          key={id}
          role={role}
          person={person}
          isConfirmed={isConfirmed}
          removeAssignment={() => doRemoveAssignment(id)}
          confirmAssignment={() => doConfirmAssignment(id)}
        />
      ))}

      <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
        <AddPerson
          addPerson={addLifeguard}
          visible={addingLifeguard}
          closeAddRole={closeAddRole}
          people={lifeguards}
          setSelectedPerson={setPersonId}
          selectedPerson={personId}
          addAssignment={doAddAssignment}
          saving={saving}
        />
      </RequireRole>

      <h3 className="panel-heading mt-4">Gatekeepers</h3>
      {assignedGatekeepers?.map(({ person, isConfirmed, role, id }) => (
        <AssignmentUI
          key={id}
          role={role}
          person={person}
          isConfirmed={isConfirmed}
          removeAssignment={() => doRemoveAssignment(id)}
          confirmAssignment={() => doConfirmAssignment(id)}
        />
      ))}

      <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
        <AddPerson
          addPerson={addGatekeeper}
          visible={addingGatekeeper}
          closeAddRole={closeAddRole}
          people={gatekeepers}
          setSelectedPerson={setPersonId}
          selectedPerson={personId}
          addAssignment={doAddAssignment}
          saving={saving}
        />
      </RequireRole>

      <h3 className="panel-heading mt-4 flex">
        <span className="flex-1">Technical manager</span>
        <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
          {assignedTechManagers.length > 0 && (
            <>
              <InlineButtonPrimary
                onClick={() =>
                  doCopyAssignment(
                    assignedTechManagers[0].id,
                    CopyAssignmentPeriod.DAY,
                  )
                }
                loading={copying}
                title="Copies this person to all the sessions this week. Overrides whoever was there before, but can be overriden by manually setting someone to certain sessions."
              >
                Copy to day
              </InlineButtonPrimary>
              <InlineButtonPrimary
                onClick={() =>
                  doCopyAssignment(
                    assignedTechManagers[0].id,
                    CopyAssignmentPeriod.WEEK,
                  )
                }
                loading={copying}
                title="Copies this person to all the sessions this week. Overrides whoever was there before, but can be overriden by manually setting someone to certain sessions."
              >
                Copy to week
              </InlineButtonPrimary>
            </>
          )}
        </RequireRole>
      </h3>
      {assignedTechManagers?.map(({ person, isConfirmed, role, id }) => (
        <>
          <AssignmentUI
            key={id}
            role={role}
            person={person}
            isConfirmed={isConfirmed}
            removeAssignment={() => doRemoveAssignment(id)}
            confirmAssignment={() => doConfirmAssignment(id)}
          />
        </>
      ))}

      <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
        {assignedTechManagers.length < 1 && (
          <AddPerson
            addPerson={addTechManager}
            visible={addingTechManager}
            closeAddRole={closeAddRole}
            people={techManagers}
            setSelectedPerson={setPersonId}
            selectedPerson={personId}
            addAssignment={doAddAssignment}
            saving={saving}
          />
        )}
      </RequireRole>

      {pedagogicSessionTypes.includes(sessionType) && (
        <>
          <h3 className="panel-heading mt-4">Teachers</h3>
          {assignedTeachers?.map(({ person, isConfirmed, id, role }) => (
            <AssignmentUI
              key={id}
              role={role}
              person={person}
              isConfirmed={isConfirmed}
              removeAssignment={() => doRemoveAssignment(id)}
              confirmAssignment={() => doConfirmAssignment(id)}
            />
          ))}

          <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
            <AddPerson
              addPerson={addTeacher}
              visible={addingTeacher}
              closeAddRole={closeAddRole}
              people={teachers}
              setSelectedPerson={setPersonId}
              selectedPerson={personId}
              addAssignment={doAddAssignment}
              saving={saving}
            />
          </RequireRole>
        </>
      )}

      {error && <Error className="block mt-4">{error}</Error>}
    </div>
  )
}
