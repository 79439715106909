import { useState, useRef } from 'react'

import type {
  CoursePanelQuery,
  CoursePanelQuery_course as Course,
} from 'api/__generated__/CoursePanelQuery'

import Panel from 'components/panel'
import EditCoursePanel from 'components/courses/edit-course-panel'
import GenericPanelHeader from 'components/panel/generic-panel-header'

interface Props {
  courseId?: Course['id']
  close: () => void
}

const CoursePanel = ({ courseId, close }: Props): JSX.Element => {
  // Passing data, loading and refetch back out here to make the header work
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<CoursePanelQuery | undefined>(undefined)
  const refetchRef = useRef<() => void>(() => null)
  const setRefetch = (refetch: () => Promise<void>): void => {
    refetchRef.current = refetch
  }

  let header
  if (data?.course) {
    header = (
      <GenericPanelHeader
        title={data.course.name}
        reload={refetchRef.current}
        loading={loading}
        icon="🎓"
      />
    )
  }

  return (
    <Panel isOpen={!!courseId} close={close} header={header}>
      {courseId && (
        <EditCoursePanel
          courseId={courseId}
          setData={setData}
          setLoading={setLoading}
          setRefetch={setRefetch}
          close={close}
        />
      )}
    </Panel>
  )
}

export default CoursePanel
