import { DateTime } from 'luxon'
import type { DocumentsQuery_documents as Document } from 'api/__generated__/DocumentsQuery'

import { InlineButtonPrimary } from 'components/buttons'
import Pill, { PillVariant } from 'components/shared/pill'
import { RequireRole } from 'components/shared/auth-components'
import { Role } from 'globalTypes'
import { ucfirst } from 'utils'

const CONTENT_TYPES = {
  'application/pdf': '📄',
  'image/png': '🖼',
  'image/jpeg': '🖼',
  'image/jpg': '🖼',
  new: '🆕',
  default: '📑',
}

interface ItemProps {
  document: Document
  edit: () => void
  selected: boolean
}
const Item = ({
  document: {
    title,
    description,
    revision,
    contentType,
    size,
    url,
    category,
    updatedAt,
    exists,
    roles,
  },
  edit,
}: ItemProps): JSX.Element => {
  const sizeString = size ? `${(size / 1024).toFixed(2)}Kb` : null
  const updated = DateTime.fromISO(updatedAt)
  const updatedString = updated.toLocaleString(DateTime.DATE_MED)
  let icon = CONTENT_TYPES.default
  if (contentType && contentType in CONTENT_TYPES) {
    icon = CONTENT_TYPES[contentType as keyof typeof CONTENT_TYPES]
  }
  if (!exists) {
    icon = CONTENT_TYPES.new
  }
  return (
    <tr>
      <td className="px-6 py-4 text-sm flex">
        {icon && <span className="text-2xl mr-3">{icon}</span>}
        <div className={`flex-1 mt-1 ${exists ? '' : 'text-gray-400'}`}>
          <h2 className="text-sm font-bold">
            {exists ? (
              <a
                className="text-link"
                title={`Download ${title} ${contentType} ${sizeString}`}
                href={url}
              >
                {title}
                {revision > 0 && `– v${revision}`}
              </a>
            ) : (
              <>
                {title}
                {revision > 0 && `– v${revision}`}
              </>
            )}
          </h2>
          <p className="text-sm pt-2">{description}</p>
        </div>
      </td>
      <td className="px-6 py-4 text-sm">{updatedString}</td>
      <td className="px-6 py-4 text-sm">
        <Pill variant={PillVariant.blue}>{ucfirst(category)}</Pill>
      </td>
      <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
        <td className="px-6 py-4 text-sm">
          {roles?.map((role) => (
            <Pill variant={PillVariant.blue} key={role}>
              {ucfirst(role)}
            </Pill>
          ))}
          {!roles?.length && <Pill variant={PillVariant.red}>Everyone</Pill>}
        </td>
      </RequireRole>
      <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <InlineButtonPrimary onClick={edit} className="">
            Edit
          </InlineButtonPrimary>
        </td>
      </RequireRole>
    </tr>
  )
}

interface DocumentsListProps {
  documents: Document[]
  selectedDocumentId: Document['id'] | null
  openPanel: (documentId: Document['id']) => void
}

const DocumentsList = ({
  documents,
  openPanel,
  selectedDocumentId,
}: DocumentsListProps): JSX.Element => (
  <div className="max-w-full xl:max-w-6xl mx-auto py-6 sm:px-6 flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  Last modified
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
                >
                  Category
                </th>
                <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
                  >
                    Visible to roles
                  </th>
                </RequireRole>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {documents.map((item: Document) => (
                <Item
                  document={item}
                  key={item.id}
                  edit={() => openPanel(item.id)}
                  selected={item.id === selectedDocumentId}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)

export default DocumentsList
