import { Reload } from 'components/buttons'

interface Props {
  title: string
  reload?: () => void
  icon: string
  loading: boolean
}
const GenericPanelHeader = ({
  title,
  reload,
  loading,
  icon,
}: Props): JSX.Element => {
  return (
    <header className="px-4 sm:px-6 bg-indigo-700 py-6 flex text-white">
      <h2 className="text-lg leading-7 font-medium flex-1 capitalize">
        <span className="text-2xl mr-3">{icon}</span>
        {title}
      </h2>
      {reload && (
        <Reload
          onClick={reload}
          className={`flex-end ${loading && 'animate-spin'}`}
        />
      )}
    </header>
  )
}

export default GenericPanelHeader
